import { useEffect, useState } from "react";
import roomService from "../services/roomService";
import getTaxedPrice from "./getRoomPriceWithTax";
import periods from "./periods";
import RoomInfo from "./RoomInfo";

/**
 *
 * @returns {Array} - the roomPrices and roomData
 *
  roomPrices = {
    "room-key": price<Number>,
    "room-key2": price<Number>
  }
  roomData = [
    {_id: '62ba47578a887481, name:'room-key, ...etc}
  ]
 */
export default function useRoomPrices() {
  const [roomsPrices, setRoomsPrices] = useState();
  const [roomData, setRoomData] = useState();

  useEffect(() => {
    roomService.getRooms().then((res) => {
      const roomData = res.data;
      let prices = {};
      let currentPeriod;
      const today = new Date().setHours(0, 0, 0, 0);
      Object.keys(periods).forEach((period) => {
        periods[period].forEach((interval) => {
          const dates = interval.split(" - ");
          const startDate = new Date(dates[0]).setHours(0, 0, 0, 0);
          const endDate = new Date(dates[1]).setHours(0, 0, 0, 0);
          if (interval === "2022-09-20 - 2022-10-31") {
            console.log(startDate);
            console.log(endDate);
          }
          if (today >= startDate && today <= endDate) {
            currentPeriod = period;
          }
        });
      });
      Object.keys(RoomInfo).forEach((room) => {
        const currentRoomInfo = roomData.find((x) => x.name === room);
        const currentRoomPrice = currentRoomInfo[currentPeriod + "Price"];
        prices[room] = getTaxedPrice(currentRoomPrice);
      });
      setRoomData(roomData);
      setRoomsPrices(prices);
    });
  }, []);
  return [roomsPrices, roomData];
}
