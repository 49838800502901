import React, { useEffect, useState } from "react";
import PageBanner from "../components/PageBanner";
import Layouts from "../layouts/Layouts";
import AvailabilityPicker from "../components/AvailabilityPicker";
import RoomInfo from "../utils/RoomInfo";
import { useLocation } from "react-router-dom";
import RoomBookingView from "../components/RoomBookingView";
import LoadingSpinner from "../components/LoadingSpinner";
import RoomSection from "../components/RoomSection";
import { useTranslation } from "react-i18next";
export default function Rooms() {
  const { t } = useTranslation();
  const location = useLocation();
  const [bookingData, setBookingData] = useState();
  const [roomsToDisplay, setRoomsToDisplay] = useState(Object.keys(RoomInfo));
  const [isRoomsLoading, setIsRoomsLoading] = useState(true);
  const [showBookingView, setShowBookingView] = useState(false);

  useEffect(() => {
    if (location.state) {
      console.log(location.state.data);
      setBookingData(location.state.data);
    } else {
      setIsRoomsLoading(false);
    }
  }, [location]);

  return (
    <Layouts>
      <PageBanner pageName={t("accommodation")} />
      <AvailabilityPicker
        setBookingData={setBookingData}
        roomsToDisplay={roomsToDisplay}
        setRoomsToDisplay={setRoomsToDisplay}
        setIsRoomsLoading={setIsRoomsLoading}
        setShowBookingView={setShowBookingView}
      />
      {!isRoomsLoading ? (
        showBookingView ? (
          <RoomBookingView
            isRoomsLoading={isRoomsLoading}
            roomsToDisplay={roomsToDisplay}
            data={bookingData}
          />
        ) : (
          <RoomSection roomsToDisplay={roomsToDisplay} data={bookingData} />
        )
      ) : (
        <LoadingSpinner />
      )}
    </Layouts>
  );
}
