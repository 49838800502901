import http from "./httpService";
import config from "../config/config.json";
const apiUrl = config.apiUrl;
const apiEndpoint = apiUrl + "/offers";

const createOffer = async (data) => {
  const offer = await http.post(apiEndpoint, data);
  return offer;
};

const getAllOffers = async () => {
  const offers = await http.get(apiEndpoint);
  return offers;
};

const getApprovedOffers = async () => {
  return await http.get(`${apiEndpoint}/approved`);
};

const changeOfferStatus = async (id, status) => {
  const res = await http.put(`${apiEndpoint}/changeOfferStatus`, {
    id,
    status,
  });
  return res;
};

const editOffer = async (offer) => {
  const res = await http.put(`${apiEndpoint}/editOffer`, { offer });
  return res;
};

const deleteOffer = async (id) => {
  const offer = await http.delete(`${apiEndpoint}/${id}`);
  return offer;
};

const exportedFunctions = {
  createOffer,
  getAllOffers,
  getApprovedOffers,
  changeOfferStatus,
  deleteOffer,
  editOffer,
};

export default exportedFunctions;
