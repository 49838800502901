import React from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Index from "../pages";
import About from "../pages/about";
import RoomDetails from "../pages/room-details";
import RoomGrid from "../pages/room-grid";
import RoomList from "../pages/room-list";
import Rooms from "../pages/RoomsPage";
import AdminRoot from "./AdminRoot";
import PaymentPage from "./PaymentPage/PaymentPage";
import Contact from "../pages/contact";
import Dining from "../pages/dining";
import ReviewsPage from "../pages/ReviewsPage/ReviewsPage";
import Sport from "../pages/sport";
import Events from "../pages/events";
import Gallery from "../pages/gallery";
import OffersPage from "../pages/OffersPage/OffersPage";
import CancellationPage from "../pages/CancellationPage/CancellationPage";
import Terms from "../pages/Terms";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import HotelPolicy from "../pages/HotelPolicy";
import AuthorizePayment from "../components/AuthorizePayment/AuthorizePayment";
import MobileAppPage from "../pages/MobileAppPage/MobileApp";

export default function Root() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Index} />
        <Route exact path="/home" component={Index} />
        <Route exact path="/about" component={About} />
        <Route exact path="/rooms" component={Rooms} />
        <Route exact path="/room-grid" component={RoomGrid} />
        <Route exact path="/room-list" component={RoomList} />
        <Route exact path="/room-single" component={RoomDetails} />
        <Route exact path="/payment" component={PaymentPage} />
        <Route exact path="/authorize-payment" component={AuthorizePayment} />
        <Route exat path="/admin" component={AdminRoot} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/dining" component={Dining} />
        <Route exact path="/reviews" component={ReviewsPage} />
        <Route exact path="/sport" component={Sport} />
        <Route exact path="/events" component={Events} />
        <Route exact path="/gallery" component={Gallery} />
        <Route exact path="/offers" component={OffersPage} />
        <Route exact path="/general-terms" component={Terms} />
        <Route exact path="/privacy" component={PrivacyPolicy} />
        <Route exact path="/terms" component={HotelPolicy} />
        <Route path="/cancellation" component={CancellationPage} />
        <Route path="/mobile-app" component={MobileAppPage} />
      </Switch>
    </Router>
  );
}
