import React, { useState } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Button from "../../components/Button";

//Services
import authService from "../../services/authService";
import "./style.css";
import Joi from "joi";
import { validate } from "../../utils/validation";

const schema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .label("Моля, въведи твоя имейл адрес!"),
  password: Joi.string().min(1).label("Моля, въведи твоята парола!"),
});

export default function AdminLoginPage() {
  const history = useHistory();
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const changeHandler = (val, field) => {
    let dataCopy = { ...data };
    dataCopy[field] = val;
    setData(dataCopy);
  };

  const submitForm = async (e) => {
    console.log("submit");
    e.preventDefault();
    if ((await validate(data, schema, () => {})) === null) {
      try {
        await authService.login(data.email, data.password);
        history.push("/rooms");
      } catch (err) {
        toast.error(err.response ? err.response.data : "Нещо се обърка!");
      }
    } else {
      toast.error("Empty field is not allowed!");
    }
  };
  return (
    <div className="admin-login-page">
      <div className="contact-form style-two login-form">
        <form onSubmit={submitForm} method="post">
          <h4>Вход</h4>
          <div className="form-group">
            <input
              type="email"
              className="form-control"
              name="email"
              defaultValue=""
              placeholder="Имейл"
              required=""
              value={data.email}
              onChange={(e) => changeHandler(e.currentTarget.value, "email")}
            />
            <input
              type="password"
              className="form-control"
              name="password"
              defaultValue=""
              placeholder="Парола"
              required=""
              value={data.password}
              onChange={(e) => changeHandler(e.currentTarget.value, "password")}
            />
          </div>
          <Button text={"Вход"} type="submit" />
        </form>
      </div>
    </div>
  );
}
