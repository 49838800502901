import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Layouts from "../layouts/Layouts";

export default function Terms() {
  const { i18n } = useTranslation();
  const language = i18n.language;
  const bulgarian = (
    <Fragment>
      <p className="center-text">
        <strong>ОБЩИ УСЛОВИЯ</strong>
      </p>
      <p className="center-text">
        <strong>
          За резервации на хотелски услуги направени чрез онлайн-резервационна система генерирана от официалния уеб-сайт
          на хотел &bdquo;Адмирал&rdquo;
        </strong>
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>
        С настоящите общи условия се уреждат отношенията между &bdquo;Златни пясъци&rsquo;&rsquo; АД по повод
        предоставяните от хотел &bdquo;Адмирал&ldquo;
        <span class="Apple-converted-space">&nbsp; </span>
        услуги и клиентите на хотела.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>1.РЕЗЕРВАЦИИ:</strong>
      </p>
      <p>&nbsp;</p>
      <ol>
        <ol>
          <li>
            Резервация на стаи може да бъде направена по следния начин: чрез бутон &bdquo;Резервирай&ldquo; на уеб
            страницата на хотела www.admiral.bg, ще бъдете насочени към онлайн резервационната бланка, където е
            необходимо да бъдат попълнени данни за: период, брой нощувки, брой стаи, брой възрастни, деца до 11,99
            години и бебета от 0 до 1 г. След попълване на данните ще бъдат визуализирани възможните опции за
            резервиране &ndash; типове стаи, видове оферти и съответстващите им цени. След избиране на желаната оферта е
            необходимо да въведете личните си данни, за да бъде направена Вашата резервация: име, фамилия, имейл адрес и
            начин на плащане: банков превод или кредитна карта. За гаранция с банкова карта са необходими следните
            данни: номер на кредитна карта, валидност на кредитната карта, имената изписани върху картата, както и Card
            Security Code (CVV2 / CVC). Моля, прегледайте детайлите по резервацията си, запознайте се с условията, и в
            случай че желаете да направите резервацията си по описаните в офертата и условията параметри, отметнете
            &bdquo;Приемам условията&ldquo; и натиснете &bdquo;Потвърждение и резервация&ldquo;. Вашата резервация е
            направена, и на посочения имейл адрес ще получите потвърждението и.
          </li>
        </ol>
      </ol>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>
        <strong>2.ЦЕНИ И НАЧИН НА ПЛАЩАНЕ:</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          2.1.<span class="Apple-converted-space">&nbsp; </span>
        </strong>
        Хотел &bdquo;Адмирал&rdquo; се задължава да осигури ползването на услугите, заявени и платени от клиента по
        онлайн резервация според предоставените от клиента чрез бланката за резервация данни за: име на клиента, период,
        брой нощувки, брой възрастни и деца до 11,99 години, редовни и допълнителни легла, цена за нощувка. Гости на
        възраст 12 или повече години се таксуват като възрастни. Полето "Забележки" в бланката за резервация няма
        задължаващ характер за хотел &bdquo;Адмирал&rdquo;, като всички специални изисквания на клиента, попълнени в
        това поле, ще бъдат удовлетворени при възможност.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>2.2.</strong>&nbsp; Цените в интернет страницата на хотел &bdquo;Адмирал&rdquo; се визуализират в БГ
        валута (лева) и включват 9% ДДС, туристически данък и хотелска застраховка. Общата дължима сума по резервацията
        се калкулира автоматично, в зависимост от броя дни, броя възрастни и деца (според възрастта им), и от актуалната
        ценова оферта за съответния период. Направената и заплатена през онлайн резервационния портал резервация е
        гарантирана със съответното авансово плащанe според офертата. Моля, да имате предвид, че резервации не се считат
        за гарантирани без предварително заплащане според условията на офертата, и гостите по тях няма да бъдат
        настанени. Хотел &bdquo;Адмирал&rdquo; запазва правото си да променя цените и специалните оферти в интернет
        страницата си без предизвестие и без уведомление. При вече направени, потвърдени и платени резервации, дължимата
        сума не се променя, независимо от представените нови ценови оферти.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>2.3.</strong>&nbsp;По преценка на хотелиера, като изискуем гарантиращ депозит, може да бъде посочена
        сума, която следва да бъде платена авансово по банков път или чрез дебитна или кредитна карта. Плащане от Ваша
        страна следва да се извърши в рамките на посочения в условията за резервация срок. Остатъкът от сумата на цялата
        Ви резервация, e платим на място в хотела.
      </p>
      <p>
        <span class="Apple-converted-space">&nbsp; &nbsp; &nbsp; </span>В случай на плащане чрез банков превод, моля,
        имайте предвид, че трябва да посочите номера на резервацията, името на титуляря на резервацията и датите на
        престой в хотела като основание за плащане.
      </p>
      <p>&nbsp;</p>
      <p>
        При пакетни и празнични предложения, по преценка на хотелиера, като изискуем гарантиращ депозит, може да бъде
        посочена сума, която следва да платите авансово преди настаняването си в размер на 100 (сто)% от сумата на
        резервацията. Плащането от Ваша страна следва да се извърши в рамките на посочения в условията за резервация
        срок.
      </p>
      <p>&nbsp;</p>
      <p>След получаване на авансово плащане, Вашата резервация е валидна и се счита за окончателно ПОТВЪРДЕНА.</p>
      <p>&nbsp;</p>
      <p>
        При неизвършено плащане в рамките на посочения в условията за резервация срок, хотелът запазва правото си да
        анулира резервация.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ВАЖНО!</strong>
      </p>
      <p>
        Моля, при затруднения да резервирате и платите Вашата резервация през резервационната система на хотел
        &bdquo;Адмирал&ldquo;, свържете се с нашия екип на телефон &nbsp;+ 359 52 390200, или на e-mail:
        reservations@admiral.bg
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ol start="3">
        <li>
          <strong>АНУЛАЦИИ И ПРОМЕНИ ПО РЕЗЕРВАЦИИТЕ:</strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <strong>3.1.</strong>&nbsp;При промени, анулации, неявяване (no-show) или ранно напускане при вече платена от
      </p>
      <p>
        <span class="Apple-converted-space">&nbsp;&nbsp; &nbsp; &nbsp; </span>
        страна на клиента онлайн резервация, &bdquo;Златни пясъци&rsquo;&rsquo; АД задържа съответната
        <span class="Apple-converted-space">&nbsp; &nbsp; &nbsp; &nbsp; </span>
        неустойка според условията на резервираната ценова оферта.
      </p>
      <p>&nbsp;</p>
      <ol start="3">
        <ol start="2">
          <li>
            В случаи на форсмажорни обстоятелства, налагащи отмяна или промяна на
            <span class="Apple-converted-space">&nbsp; </span>резервацията, е необходимо да се предоставят съответните
            документи и доказателства.
            <span class="Apple-converted-space">&nbsp; </span>Тогава възстановяването на заплатените суми е по преценка
            на хотел &bdquo;Адмирал&rdquo;. Възстановяването на заплатени суми от &bdquo;Златни пясъци&rsquo;&rsquo; АД,
            ще бъде направено чрез транзакция по картата, с която е извършено плащането по резервацията.
            Възстановяването на суми се извършва в 30-дневен период от датата на анулацията.
          </li>
        </ol>
      </ol>
      <p>&nbsp;</p>
      <ol start="3">
        <ol start="3">
          <li>
            При анулация на потвърдена резервация до 7 дни преди пристигане, не се дължи неустойка. При получено плащане
            от Ваша страна, се възстановява в срок от 7 работни дни, считано от датата на получената анулация.
          </li>
        </ol>
      </ol>
      <p>&nbsp;</p>
      <ol start="3">
        <ol start="4">
          <li>
            При анулация на резервация 6 или по-малко дни преди пристигане, се дължи неустойка в размер на 1 нощувка за
            всяка резервирана стая. Останалата сума се възстановява по сметката Ви в срок от 7 работни дни, считано
            датата на получената анулация.
          </li>
        </ol>
      </ol>
      <p>&nbsp;</p>
      <ol start="3">
        <ol start="5">
          <li>В случай на неявяване, сумата по Вашата резервация няма да ви бъде възстановена.</li>
        </ol>
      </ol>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ol start="4">
        <li>
          <strong>ПРАВА И ЗАДЪЛЖЕНИЯ НА СТРАНИТЕ:</strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <strong>4.1.</strong>&nbsp;Хотелиерът се задължава да предостави стандартните за категория &bdquo;пет
        звезди&ldquo; туристически услуги, заплатени от Потребителя.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>4.2.</strong>&nbsp;Хотелиерът има право да откаже приетата резервация, без да носи никаква отговорност,
        ако Потребителят не извърши плащанията в пълен размер в установените срокове.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>4.3.</strong>&nbsp;Потребителят се задължава:
      </p>
      <p>
        <span class="Apple-converted-space">&nbsp;&nbsp; &nbsp; &nbsp; </span>
        4.3.1. да заплати в установените срокове стойността на направената резервация;
      </p>
      <p>
        <span class="Apple-converted-space">&nbsp;&nbsp; &nbsp; &nbsp; </span>
        4.3.2. да спазва законите на Република България и Правилника за вътрешния ред в хотела;
      </p>
      <p>&nbsp;</p>
      <ol start="5">
        <li>
          <strong>ОТГОВОРНОСТ НА СТРАНИТЕ:</strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <strong>5.1.</strong>&nbsp;Хотелиерът не носи отговорност пред Потребителя в случай на форсмажорни обстоятелства
        като: болести, епидемии, природни бедствия, стачки, военни действия както в Република България, така и в
        чужбина, заплашващи сигурността на хората или ограничаващи тяхната възможност да се придвижват, правителствени
        решения, прекъсване на водоснабдяването, електрозахранването и други услуги от външни доставчици, върху които
        хотелът няма контрол, както и за всяко друго непредвидено или непреодолимо събитие от извънреден характер,
        възникнало след направена резервация, което не може да бъде избегнато от страна на Хотелиера и неговите
        контрагенти при добросъвестно изпълнение на задълженията им.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>5.2.</strong>&nbsp;Хотелиерът носи отговорност само за събития настъпили на територията на хотела и не
        носи каквато и да било отговорност към Потребителя при консумирани от него храна и напитки извън определените от
        хотела места и извършване на действия извън хотела различни от обичайните такива за територията на хотела.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>5.3.</strong>&nbsp;Хотелиерът не носи каквато и да било отговорност към претенции на Потребителя, които
        не са съобщени от него по време на престоя му в хотела или не са потвърдени от лекарския екип, обслужващ хотела,
        или са недоказани по несъмнен и категоричен начин, че са възникнали в пряка връзка от виновно действие или
        бездействие на служители на Хотелиера.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>5.4.</strong>&nbsp;Хотелиерът може да търси отговорност от Потребителя в случай на възникнало събитие,
        което може да увреди личното достойнство на персонала на хотела, репутацията или имуществото на хотела.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>5.5.</strong>&nbsp;Хотелиерът не носи отговорност и не дължи никакви компенсации или неустойки, ако
        поради хулигански прояви от страна на Потребителя или нарушение на правилника за вътрешния ред в хотела, му е
        отказано изпълнението на услуги или се е наложило да го отстрани изцяло от територията на хотела, като това се
        документира с протокол от присъстващи на място служебни лица или други туристи.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>5.6.</strong>&nbsp;Хотелиерът не носи отговорност за услуги, избрани, самостоятелно организирани и
        заплатени от Потребителя по време на престоя му в хотела.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>5.7.</strong>&nbsp;Хотелиерът не носи отговорност за вреди, причинени на Потребителя по време на
        почивката му от криминални прояви на трети лица, включително кражба на вещи или багаж на потребителя. В подобни
        случаи Хотелиерът съдейства на Потребителя за изпълнение на формалностите по регистрация на криминалното деяние
        до степен, при която не се нарушава обичайна туристическа почивка на другите гости на хотела.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>5.8.</strong>&nbsp;Хотелиерът не носи отговорност и не компенсира Потребителя, ако той се е отказал от
        ползването на отделни услуги или по каквито и да е причини се е отклонил, не се е явил или е закъснял след
        обявеното време и поради това не е получил едни или други услуги.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>5.9.</strong>&nbsp;Хотелът разполага с платен паркинг с 150 места в непосредствена близост до рецепция.
      </p>
      <p>
        <span class="Apple-converted-space">&nbsp;&nbsp; &nbsp; &nbsp; </span>
        Резервация на паркомясто не е възможна. Паркоместата са до изчерпване на наличните свободни. В случай на пълна
        заетост, хотелът не носи отговорност и не се ангажира с осигуряване на свободно място за паркиране, но може да
        съдействали с информация за други в близост.
      </p>
      <p>&nbsp;</p>
      <ol start="6">
        <li>
          <strong>СПОРОВЕ И РЕКЛАМАЦИИ:</strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <strong>6.1.</strong>&nbsp;Всички спорове по изпълнение на услугите, произтичащи от направената резервация ще
        бъдат решавани по взаимно съгласие на страните. В случай, че съгласие не може да бъде постигнато, те ще се
        отнасят за разглеждане пред компетентен български съд.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>6.2.</strong>&nbsp;В случай, че по време на престоя в хотела се установят факти на неизпълнение или
        неточно изпълнение на предоставените стандартни услуги, Потребителят се задължава незабавно и писмено да предяви
        претенцията си пред Хотелиера и да изиска и получи документ, удостоверяващ предявяването на претенцията с оглед
        своевременно предприемане на мерки, които удовлетворяват всички заинтересовани страни.
      </p>
      <p>&nbsp;</p>
      <ol start="7">
        <li>
          <strong>ЛИЧНИ ДАННИ:</strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <strong>7.1.</strong>&nbsp;&bdquo;Златни пясъци&rsquo;&rsquo; АД е администратор на лични данни по смисъла на
        Закона за защита на личните данни. Когато се предоставят лични данни, това се извършва съгласно изискванията на
        Закона за туризма и свързаните с него подзаконови нормативни актове. Вашите лични данни ще бъдат използвани само
        за цели, предвидени в тези нормативни актове. Вие имате право на достъп до своите лични данни на адреса на
        Дружеството: гр. Варна 9007, к.к. Златни пясъци, e-mail:&nbsp;reservations@admiral.bg
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>7.2.</strong>&nbsp;Предоставените от Вас лични данни се обработват и съхраняват на електронен и на
        хартиен носител. Вашите лични данни са защитени надлежно с надеждни методи и средства.
      </p>
      <p>
        <span class="Apple-converted-space">&nbsp; &nbsp; &nbsp; </span>Златни пясъци АД не променя и не разкрива лични
        данни на своите клиенти и не предоставя лични данни на трети лица, освен в предвидените от закона случаи.
        &bdquo;Златни пясъци&rsquo;&rsquo; АД не обработва лични данни за целите на директен маркетинг, освен с
        изричното информирано съгласие на субектите.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          Хотелиерът си запазва правото едностранно да променя настоящите Общи условия, структурата и съдържанието на
          своя уебсайт, както и да прекратява достъпа до него по всяко време, за което не е длъжен да уведомява
          Потребителя.
        </strong>
      </p>
    </Fragment>
  );
  const english = (
    <Fragment>
      <div>
        <div>
          <p className="center-text">
            <strong>GENERAL TERMS AND CONDITIONS</strong>
          </p>
          <p className="center-text">
            <strong>
              For reservations of hotel services made through the online reservation system generated by the official
              website of the Admiral Hotel
            </strong>
          </p>
          <p>&nbsp;</p>
          <p>
            These general terms and conditions regulate the relations between Golden Sands JSC and the hotel customers
            regarding the services provided by the Admiral Hotel.
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>1. RESERVATIONS:</strong>
          </p>
          <p>&nbsp;</p>
          <ol>
            <ol>
              <li>
                Room reservations can be made in the following way: by clicking the "Reserve" button on the hotel
                website: www.admiral.bg, you will be directed to the online reservation form, where it is necessary to
                fill in the information about: the period, number of nights, number rooms, number of adults, children up
                to 11.99 years of age and infants between the ages of 0 and 12 months. After filling in the information,
                the possible booking options will be visualized - room types, types of offers and their corresponding
                prices. After selecting the desired offer, it is necessary to enter your personal data in order to make
                your reservation: name, surname, email address and payment method: bank transfer or credit card.
              </li>
            </ol>
          </ol>
          <p>
            To guarantee the booking through a bank card, the following data are required: credit card number, validity
            of the credit card, the names written on the card, as well as the Card Security Code (CVV2 / CVC). Please
            review the details of your reservation, familiarize yourself with the conditions, and in case you wish to
            make your reservation in accordance with the parameters and conditions described in the offer, check "I
            accept the conditions" and press "Confirmation and booking". Your reservation has been made, and you will
            receive its confirmation at the specified email address.
            <span class="Apple-converted-space">&nbsp;</span>
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>2. PROCES AND PAYMENT METHODS:</strong>
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>
              2.1. <span class="Apple-converted-space">&nbsp;</span>
            </strong>{" "}
            The Admiral Hotel undertakes to ensure the use of the services requested and paid for online by the customer
            <span class="Apple-converted-space">&nbsp; </span>in accordance with the information provided by the
            customer via the reservation form: customer's name, period of stay, number of nights, number of adults and
            children up to 11.99 years old, regular and extra beds, price per night. Guests 12 years of age or older are
            charged as adults. The Remarks field in the reservation form is not binding to the Admiral Hotel, and all
            special customer requirements filled in this field will be met if possible.
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>2.2.</strong>&nbsp; The prices on the website of the Admiral Hotel are displayed in Bulgarian
            currency (BGN) and include 9% VAT, tourist tax and hotel insurance. The total amount due for the reservation
            is calculated automatically, depending on the number of days, the number of adults and children (according
            to their age), and the current price offer for the relevant period. The reservation made and paid for
            through the online reservation portal is guaranteed with the corresponding advance payment according to the
            offer. Please be advised that reservations are not considered guaranteed without advance payment under the
            terms of the offer and guests will not be accommodated. The Admiral Hotel reserves the right to change the
            prices and special offers on its website without prior notification. For reservations already made,
            confirmed and paid for, the amount due does not change, regardless of the new price offers presented.
            <span class="Apple-converted-space">&nbsp; </span>
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>2.3.</strong>&nbsp;At the discretion of the hotel, an amount payable in advance by bank transfer,
            debit or credit card may be specified as a required security deposit. Payment on your part should be made
            within the period specified in the reservation conditions. The rest of the amount of your entire reservation
            is payable on location at the hotel.
          </p>
          <p>
            <span class="Apple-converted-space">&nbsp; &nbsp; &nbsp; </span>In the event of payment via bank transfer,
            please be advised that you must provide the reservation number, the name of the reservation holder and the
            dates of stay at the hotel as the basis for payment.
            <span class="Apple-converted-space">&nbsp;</span>
          </p>
          <p>&nbsp;</p>
          <p>
            For package and holiday offers, at the discretion of the hotel, as a required guarantee deposit, an amount
            may be specified which should be paid in advance before check-in, to the amount of 100 (one hundred) % of
            the reservation price. Payment on your part should be made within the term specified in the reservation
            conditions.
          </p>
          <p>Upon receipt of advance payment, your reservation is valid and considered CONFIRMED.</p>
          <p>
            In the event of non-payment within the period specified in the reservation conditions, the hotel reserves
            the right to cancel the reservation.
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>IMPORTANT!</strong>
          </p>
          <p>
            Please, in case of difficulties to book and pay for your reservation through the reservation system of the
            Admiral Hotel, contact our team at:<span class="Apple-converted-space">&nbsp;</span>
          </p>
          <p>phone + 359 52 390200</p>
          <p>
            or<span class="Apple-converted-space">&nbsp; </span>e-mail: reservations@admiral.bg
          </p>
          <p>&nbsp;</p>
          <ol start="3">
            <li>
              <strong>CANCELLATIONS AND CHANGES TO RESERVATIONS:</strong>
            </li>
          </ol>
          <p>
            <strong>3.1.</strong>&nbsp;In the event of changes, cancellations, no-shows or early departures with online
            reservations already paid for by the customer, Golden Sands JSC retains the corresponding penalty amount in
            accordance with the conditions of the price offer booking.
          </p>
          <p>&nbsp;</p>
          <ol start="3">
            <ol start="2">
              <li>
                In the event of force majeure requiring the cancellation or change in the reservation, it is necessary
                to provide the relevant documents and evidence. In that case, the reimbursement of the paid amounts is
                at the discretion of the Admiral Hotel. The refund by Golden Sands JSC of amounts already paid will be
                made via transaction to the card from which the payment for the reservation was made. Refunds are made
                within 30 days from the date of cancellation.
              </li>
            </ol>
          </ol>
          <p>&nbsp;</p>
          <ol start="3">
            <ol start="3">
              <li>
                In the event of a cancellation of a confirmed reservation up to 7 days before arrival, no penalty is
                due. If payment has been made by you, such payment shall be refunded within 7 work days from the date of
                the received cancellation.
              </li>
            </ol>
          </ol>
          <p>
            <span class="Apple-converted-space">&nbsp;</span>
          </p>
          <ol start="3">
            <ol start="4">
              <li>
                Cancellations made 6 days or less prior to arrival incur a penalty of 1 night for each room booked. The
                remaining amount is refunded to your account within 7 work days, counting from the date of the
                cancellation received.
              </li>
            </ol>
          </ol>
          <p>&nbsp;</p>
          <ol start="3">
            <ol start="5">
              <li>In the event of no-show, the amount of your reservation will not be refunded.</li>
            </ol>
          </ol>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <ol start="4">
            <li>
              <strong>RIGHTS AND OBLIGATIONS OFTHE PARTIES:</strong>
            </li>
          </ol>
          <p>&nbsp;</p>
          <p>
            <strong>4.1.</strong>&nbsp;The hotel undertakes to provide the standard "five star" tourist services paid
            for by the customer.
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>4.2.</strong>&nbsp;The Hotel has the right to refuse the accepted reservation without bearing any
            responsibility if the customer does not make the payments in full within the appointed time.
            <span class="Apple-converted-space">&nbsp;</span>
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>4.3.</strong>&nbsp;The customer undertakes:
          </p>
          <p>
            <span class="Apple-converted-space">&nbsp;&nbsp; &nbsp; &nbsp; </span>
            4.3.1. to pay for the reservation made within the appointed time;
            <span class="Apple-converted-space">&nbsp;</span>
          </p>
          <p>
            <span class="Apple-converted-space">&nbsp;&nbsp; &nbsp; &nbsp; </span>
            4.3.2. to comply with the laws of the Republic of Bulgaria and the Rules of the House.
          </p>
        </div>
      </div>
      <p>&nbsp;</p>
      <ol start="5">
        <li>
          <strong>RESPONSIBILITIES OF THE PARTIES:</strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <strong>5.1.</strong>&nbsp;The hotel cannot be held liable in the event of force majeure, such as: diseases,
        epidemics, natural disasters, strikes, military actions both in the Republic of Bulgaria and abroad, threatening
        people's security or limiting their ability to move, government decisions, interruption of water supply,
        electricity and other services from external suppliers over which the hotel has no control, as well as for any
        other unforeseen or insurmountable events of extraordinary nature that occurred after a reservation was made,
        which cannot be avoided by the Hotel and its contractors in good faith performance of their duties.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>5.2.</strong>&nbsp;The hotel is only responsible for events that occurred on the hotel premises and does
        not bear any responsibility towards the customer for consuming food and drinks outside the places designated by
        the hotel and performing actions outside the hotel other than the usual ones for the hotel premises.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>5.3.</strong>&nbsp;The hotel does not bear any responsibility for claims on the part of the customer
        which have not been communicated by him during his stay at the hotel, nor have they been confirmed by the
        medical team serving the hotel, or if it has not been proven beyond any doubt that they arose as a direct result
        from the culpable action or inaction of the Hotel employees.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>5.4.</strong>&nbsp;The hotel may take the customer to task in the event of an occurrence that may damage
        the personal dignity of the hotel staff, the hotel's good name or property.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>5.5.</strong>&nbsp;The hotelier is not responsible for and does not owe any compensation or penalties
        if, due to hooliganism on the part of the customer or a violation of the Rules of the House, he is denied the
        performance of services or has had to be evicted from the hotel premises, and this is documented with in a
        report by officials present on the spot or other tourists.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>5.6.</strong>&nbsp;The hotel is not responsible for services chosen, independently organized and paid
        for by the customer during his stay at the hotel.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>5.7.</strong>&nbsp;The hotel cannot be held responsible for damages caused to the customer during his
        holiday by criminal acts of third parties, including theft of the customer's belongings or luggage. In such
        cases, the Hotel shall assist the customer in completing the formalities for registration of the criminal act to
        the extent that the usual tourist holidy of the other guests of the hotel is not disturbed.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>5.8.</strong>&nbsp;The hotel cannot be held responsible and does not compensate the guest if they have
        willingly refused certian services or for any reason have deviated from, have not shown up or have been late
        after the announced time and therefore have not received one service or another.
      </p>
      <p>
        <strong>5.9.</strong>&nbsp;The hotel has a paid parking lot with 150 spaces in close proximity to the reception.
      </p>
      <p>
        <span class="Apple-converted-space">&nbsp; &nbsp; &nbsp; </span>Parking space booking is not possible. Parking
        spaces are subject to availability. In case of full occupancy, the hotel is not responsible and does not
        undertake to provide a free parking space but may assist with information about others nearby.
      </p>
      <p>&nbsp;</p>
      <ol start="6">
        <li>
          <strong>6. DISPUTES AND COMPLAINTS:</strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <strong>6.1.</strong>&nbsp;All disputes regarding the performance of the services arising from the reservation
        will be resolved by mutual agreement of the parties. In the event that an agreement cannot be reached, they will
        be referred to a competent Bulgarian court of law.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>6.2.</strong>&nbsp;In the event that during the stay at the hotel facts of non-performance or inaccurate
        performance of the provided standard services are established, the customer undertakes to immediately present
        his claim in writing to the Hotel and request and receive a document certifying the presentation of the claim,
        in view of taking prompt measures that satisfy all interested parties.
      </p>
      <p>&nbsp;</p>
      <ol start="7">
        <li>
          <strong>PERSONAL DATA|:</strong>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <strong>7.1.</strong>&nbsp;Golden Sands JSC is a personal data controller within the meaning of the Personal
        Data Protection Act. When personal data is provided, it is done in accordance with the requirements of the
        Tourism Act and related by-laws. Your personal data will only be used for purposes provided for in these
        regulations. You have the right to access your personal data at the company's address: Varna 9007, Golden Sands
        Resort, e-mail: reservations@admiral.bg
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>7.2.</strong>&nbsp;The personal data provided by you are processed and stored electronically and on
        paper. Your personal data are properly protected with and through reliable methods and means.
      </p>
      <p>&nbsp;</p>
      <p>
        Golden Sands JSC does not change or disclose its customers' personal data,
        <span class="Apple-converted-space">&nbsp; </span>and does not provide personal data to third parties, except in
        the cases provided by law. Golden Sands AD does not process personal data for the purposes of direct marketing,
        except with the express informed consent of the subjects.
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>
        <strong>
          The hotel reserves the right to unilaterally change these General Terms and Conditions, the structure and
          content of his website, as well as to terminate access to it at any time without prior notification.
        </strong>
      </p>
    </Fragment>
  );
  return (
    <Layouts>
      <div style={{ padding: "30px" }}>{language === "bg" ? bulgarian : english}</div>
    </Layouts>
  );
}
