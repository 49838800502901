import http from "./httpService";
import config from "../config/config.json";
const apiUrl = config.apiUrl;
const apiEndpoint = apiUrl + "/subscribers";

const createSubscriber = async (email) => {
  const subscriber = await http.post(apiEndpoint, { email });
  return subscriber;
};

const getSubscribers = async () => {
  return await http.get(apiEndpoint);
};

const exporedFunctions = {
  createSubscriber,
  getSubscribers,
};

export default exporedFunctions;
