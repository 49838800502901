import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Button from "../../components/Button";
import LoadingSpinner from "../../components/LoadingSpinner";
import Layouts from "../../layouts/Layouts";
import booking from "../../services/bookingsService";

import "./style.css";

export default function CancellationPage() {
  const { t } = useTranslation();
  const [refNumber, setRefNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!refNumber) {
      toast.error(t("type-reference-number"));
      return;
    }
    setLoading(true);
    try {
      const res = await booking.cancelBooking(refNumber);
      if (res.status === 200) {
        toast.success(t("booking-cancelled"));
        setRefNumber("");
      }
    } catch (ex) {
      if (ex.response.status === 404) {
        if (ex.response.data === "booking-not-found") {
          toast.error(t("ref-not-found"));
        } else if (ex.response.data === "already-paid") {
          toast.error(t("already-paid"));
        } else if (ex.response.data === "cannot-cancel") {
          toast.error(t("cannot-cancel"));
        } else if (ex.response.data === "already-cancelled") {
          toast.error(t("already-cancelled"));
        }
      }
    }
    setLoading(false);
  };

  return (
    <Layouts>
      <div className="cancellation-page">
        <div className="cancellation-container">
          <h3 className="center-text">{t("cancellation")}</h3>
          <form onSubmit={handleSubmit} className="cancellation-form">
            <input
              className="form-details"
              value={refNumber}
              onChange={(e) => setRefNumber(e.target.value)}
              placeholder={t("ref-number")}
            />
            {loading ? (
              <LoadingSpinner />
            ) : (
              <Button type="submit" text={t("send")} />
            )}
          </form>
        </div>
      </div>
    </Layouts>
  );
}
