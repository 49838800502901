// import { Link } from "react-router-dom";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import bannerImage from "../assets/images/banner.jpg";

const PageBanner = ({ pageName, pageTitle, image, noText = false }) => {
  const { t } = useTranslation();
  return (
    <section
      className={`page-title` + (noText ? " no-text" : "")}
      style={{
        background: `url(${
          image ? image : bannerImage
        }) center center/cover no-repeat local`,
      }}
    >
      {noText ? (
        <></>
      ) : (
        <div className="auto-container">
          <div className="content-box">
            <div className="content-wrapper" style={{ opacity: "0.9" }}>
              <ul className="bread-crumb">
                <li>
                  <Link to="/">{t("home")}</Link>
                </li>
                <li>{pageName ? pageName : pageTitle}</li>
              </ul>
              <div className="title">
                <h1>{pageName}</h1>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};
export default PageBanner;
