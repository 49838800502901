import React, { useState } from "react";
import Joi from "joi";
import { validate } from "../../utils/validation";
import { useTranslation } from "react-i18next";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import LoadingSpinner from "../../components/LoadingSpinner";
import { getDateView, getDateDB } from "../../utils/date";
import Button from "../Button";
//services
import paymentSVC from "../../services/paymentService";

import "./style.css";

const schema = Joi.object({
  firstName: Joi.string()
    .min(3)
    .max(50)
    .required()
    .label("Полето е задължително!"),
  lastName: Joi.string().min(3).max(50),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .label("Полето е задължително!"),
  paymentMethod: Joi.any(),
  phone: Joi.string().max(20),
});

const PaymentForm = ({ bookingData, validVoucherUsed }) => {
  const stripe = useStripe();
  const elements = useElements();

  const { t, i18n } = useTranslation();
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });
  const [error, setError] = useState({});
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [modalMessage, setModalMesssage] = useState("wrong");
  const [isProcessing, setProcessingTo] = useState(false);
  const [cardElementError, setCardElementError] = useState(null); //State variable holding the card element input field error
  const [checkoutError, setCheckoutError] = useState(); // state variable which will hold any error which may rise during the checkout process

  const configureModalMessage = (data) => {
    const lang = i18n.language;
    let modalPayNowText = {};
    let modalScheduledText = {};
    const bookingReference = data.bookingReference;
    console.log(data, "data");
    const date = data.paymentDate;
    switch (lang) {
      case "bg":
        modalPayNowText.textOne = "Плащането беше успешно!";
        modalPayNowText.textTwo = `Референтният номер на вашата резервация е: ${bookingReference}`;
        modalPayNowText.textThree =
          "Имейлът за потвърждение е на път и трябва да го получите скоро.";
        modalScheduledText.textOne = `Вашата заявка е получена успешно!`;
        modalScheduledText.textTwo = `Ще се опитаме да обработим плащането на ${getDateView(
          getDateDB(date)
        )}, след което вашата резервация ще бъде потвърдена.`;
        modalScheduledText.textThree = `Референтният номер на вашата резервация е: ${bookingReference}`;

        break;
      case "en":
        modalPayNowText.textOne = `The payment was successful!`;
        modalPayNowText.textTwo = `Your booking reference number is: ${bookingReference}`;
        modalPayNowText.textThree = `The confirmation email is on its way and you should receive it soon.`;
        modalScheduledText.textOne = `Your request has been successfully received!`;
        modalScheduledText.textTwo = `We will try to process the payment on ${getDateView(
          getDateDB(date)
        )} following this your booking will be confirmed. `;
        modalScheduledText.textThree = `Your booking reference number is: ${bookingReference}`;
        break;
      case "ro":
        modalPayNowText.textOne = `Plata a avut succes!`;
        modalPayNowText.textTwo = `Numărul de referință al rezervării dumneavoastră este: ${bookingReference}`;
        modalPayNowText.textThree = `E-mailul de confirmare este pe drum și ar trebui să îl primiți în curând.`;
        modalScheduledText.textOne = `Solicitarea dvs. a fost primită cu succes!`;
        modalScheduledText.textTwo = `Vom încerca să procesăm plata pe data de ${getDateView(
          getDateDB(date)
        )}, după care rezervarea dumneavoastră va fi confirmată.`;
        modalScheduledText.textThree = `Numărul de referință al rezervării dumneavoastră este: ${bookingReference}`;
        break;
      case "ru":
        modalPayNowText.textOne = `Платеж прошел успешно!`;
        modalPayNowText.textTwo = `Номер вашего бронирования: ${bookingReference}`;
        modalPayNowText.textThree = `Электронное письмо с подтверждением находится в пути, и вы должны получить его в ближайшее время.`;
        modalScheduledText.textOne = `Ваш запрос успешно получен!`;
        modalScheduledText.textTwo = `Мы постараемся обработать платеж в ${getDateView(
          getDateDB(date)
        )}, после чего ваше бронирование будет подтверждено.`;
        modalScheduledText.textThree = `Номер вашего бронирования: ${bookingReference}`;
        break;
      case "de":
        modalPayNowText.textOne = `Die Zahlung war erfolgreich!`;
        modalPayNowText.textTwo = `Ihre Buchungsreferenznummer lautet: ${bookingReference}`;
        modalPayNowText.textThree = `Die Bestätigungs-E-Mail ist unterwegs und Sie sollten sie bald erhalten.`;
        modalScheduledText.textOne = `Ihre Anfrage wurde erfolgreich empfangen!`;
        modalScheduledText.textTwo = `Wir werden versuchen, die Zahlung am ${getDateView(
          getDateDB(date)
        )} zu verarbeiten, danach wird Ihre Buchung bestätigt.`;
        modalScheduledText.textThree = `Ihre Buchungsreferenznummer lautet: ${bookingReference}`;
        break;
      default:
        break;
    }
    setModalMesssage(
      data.paymentScheduled ? modalScheduledText : modalPayNowText
    );
  };

  //Validate Card Element
  const handleCardDetailsChange = (ev) => {
    ev.error
      ? setCardElementError(ev.error.message)
      : setCardElementError(null);
  };

  const changeHandler = (field, value) => {
    let dataCopy = { ...data };
    dataCopy[field] = value;
    setData({ ...dataCopy });
  };

  const subimtHandler = async (e) => {
    e.preventDefault();
    if ((await validate(data, schema, setError)) === null) {
      // send email here

      submitPayment(e);
    }
  };

  const submitPayment = async (ev) => {
    setProcessingTo(true);
    setCheckoutError(null);

    // const stripeCustomerId = data.stripeCustomerId;
    const billingDetails = {
      name: data.firstName + " " + data.lastName,
      email: data.email,
      phone: data.phone,
    };

    try {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        console.log("here");
        return;
      }

      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = elements.getElement(CardElement);
      // Use your card Element with other Stripe.js APIs
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: billingDetails,
      });

      if (error) {
        throw new Error(error.message);
      }
      let finalBookingData = { ...bookingData };
      finalBookingData["startDate"] = getDateDB(bookingData.startDate);
      finalBookingData["endDate"] = getDateDB(bookingData.endDate);
      finalBookingData["foodBoard"] = bookingData["foodBoard"]
        ? bookingData["foodBoard"]
        : "bed-and-breakfast";
      //Create payment Intent
      const paymentIntentResponse = await paymentSVC.createPaymentIntent({
        customerData: billingDetails,
        bookingData: finalBookingData,
        paymentMethod,
        language: i18n.language,
        validVoucherUsed,
      });
      //Check if the payment intent status is not 200 and if this is true display the error to the user and the checkout should end
      if (paymentIntentResponse.data.statusCode !== 200) {
        throw new Error(paymentIntentResponse.data.message);
      } else {
        configureModalMessage(paymentIntentResponse.data);
      }

      if (paymentIntentResponse.data.paymentScheduled === false) {
        // Constant holding the client secret retrieved from the payment intent creation.
        const clientSecret = paymentIntentResponse.data.clientSecret;

        //Confirm card payment
        //If data.paymentMethod is empty this means that we need to use the payment method provided inside the card elementotherwise we use the the payment method id choosed by hte user.
        const { error } = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            type: "card",
            card: cardElement,
            billing_details: billingDetails,
          },
        });

        // //Check if there is any error
        if (error) {
          throw new Error(error.message);
        }
      }

      setProcessingTo(false);
      setIsConfirmationModalOpen(true);
    } catch (err) {
      setProcessingTo(false);
      let errorMsg;
      if (err.response && err.response.data === "invalid-dates") {
        errorMsg = t("dates-were-booked");
      } else {
        errorMsg = err.message ? err.message : t("wrong");
        console.log(err.message);
      }
      setCheckoutError(errorMsg);
    }
  };

  const cardElementsOptions = {
    hidePostalCode: true,
    style: {
      base: {
        iconColor: "red",
        color: "black",
        fontWeight: "500",
        fontFamily: "Montserrat, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        border: "1px solid var(--color-primary-1)",
        ":-webkit-autofill": {
          color: "#fce883",
        },
        "::placeholder": {
          color: "#black",
        },
      },
      invalid: {
        iconColor: "#black",
        color: "#red",
      },
    },
  };

  return (
    <>
      <form onSubmit={subimtHandler} className="payment-form contact-form">
        <h2 className="center-text">{t("details")}</h2>
        <div className="row">
          <div className="col-lg-6 col-12">
            <div className="form-field">
              <input
                value={data.firstName}
                onChange={(e) =>
                  changeHandler("firstName", e.currentTarget.value)
                }
                type="text"
                name="name"
                placeholder={t("name")}
              />
              <p>{error.firstName ? error.firstName : ""}</p>
            </div>
          </div>
          <div className="col-lg-6 col-12">
            <div className="form-field">
              <input
                value={data.lastName}
                onChange={(e) =>
                  changeHandler("lastName", e.currentTarget.value)
                }
                type="text"
                name="lastname"
                placeholder={t("last-name")}
              />
            </div>
          </div>
          <div className="col-lg-6 col-12">
            <div className="form-field">
              <input
                onChange={(e) => changeHandler("email", e.currentTarget.value)}
                value={data.email}
                type="email"
                name="email"
                placeholder={t("email-address")}
              />
              <p>{error.email ? error.email : ""}</p>
            </div>
          </div>
          <div className="col-lg-6 col-12">
            <div className="form-field">
              <input
                onChange={(e) => changeHandler("phone", e.currentTarget.value)}
                value={data.phone}
                type="phone"
                name="phone"
                placeholder={t("telephone")}
              />
              <p>{error.phone ? error.phone : ""}</p>
            </div>
          </div>

          <div className="col-lg-12 col-12">
            {/* <label htmlFor={"card"} className={`label `}>
              {t("card-details")}
            </label>{" "} */}
            <div className="form-field card-details">
              <CardElement
                options={cardElementsOptions}
                onChange={handleCardDetailsChange}
              />
            </div>
            <p className="p--small p--error">{cardElementError}</p>
          </div>

          <div className="col-lg-12">
            <div className="form-submit">
              {!isProcessing ? (
                <Button text={t("pay")} type="submit" />
              ) : (
                <LoadingSpinner />
              )}
            </div>
          </div>
        </div>
        {checkoutError && <p className="checkout-error">{checkoutError}</p>}
      </form>
      {isConfirmationModalOpen && (
        <div
          className="add-new-voucher-modal"
          onClick={() => setIsConfirmationModalOpen(false)}
        >
          <div
            className="add-new-voucher-modal__content"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <h1>{modalMessage.textOne}</h1>
            <h3>{modalMessage.textTwo}</h3>
            <h3>{modalMessage.textThree}</h3>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentForm;
