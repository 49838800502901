import React, { useCallback } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import "./style.css";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

export default function GalleryFilter({ tabs, activeTab, handleClick }) {
  const { t } = useTranslation();
  return (
    <div className="gallery-page-filter">
      <div className="gallery-filters">
        <Nav tabs>
          {tabs.map((tab, index) => {
            const tabId = String(index + 1);
            return (
              <NavItem key={index}>
                <NavLink
                  className={classnames({ active: activeTab === tabId })}
                  onClick={() => {
                    handleClick(tabId);
                  }}
                >
                  {t(tab)}
                </NavLink>
              </NavItem>
            );
          })}
        </Nav>
      </div>
    </div>
  );
}

const GalleryContent = ({ items, renderItem, activeTab, selectImage }) => {
  return (
    <div className="gallery-container">
      <TabContent activeTab={activeTab}>
        {Object.keys(items).map((item, index) => {
          return (
            <TabPane tabId={String(index + 1)}>
              {items[item].map((itm, index) => {
                return (
                  <div
                    onClick={() => selectImage(itm)}
                    className="gallery-item"
                    key={index}
                  >
                    {renderItem(itm)}
                  </div>
                );
              })}
            </TabPane>
          );
        })}
      </TabContent>
    </div>
  );
};

const FullScreenGallery = ({ images, onClose }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handlePreviousImage = useCallback(
    (e) => {
      e.stopPropagation();
      setCurrentImageIndex(
        (prevIndex) => (prevIndex - 1 + images.length) % images.length
      );
    },
    [images.length]
  );

  const handleNextImage = useCallback(
    (e) => {
      e.stopPropagation();
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    },
    [images.length]
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === "ArrowLeft") {
        handlePreviousImage(e);
      } else if (e.key === "ArrowRight") {
        handleNextImage(e);
      } else if (e.key === "Escape") {
        onClose();
      }
    },
    [handleNextImage, handlePreviousImage, onClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        onClose();
      }}
      className="full-screen-gallery"
    >
      <div className="content">
        <i
          onClick={(e) => handlePreviousImage(e)}
          className="fa fa-lg fa-chevron-left full-screen-gallery-arrow"
        ></i>
        <img
          onClick={(e) => {
            e.stopPropagation();
          }}
          src={images[currentImageIndex]}
          alt=""
        />
        <i
          onClick={(e) => handleNextImage(e)}
          className="fa fa-lg fa-chevron-right full-screen-gallery-arrow"
        ></i>
        <i
          onClick={onClose}
          className="fa fa-lg fa-times full-screen-gallery-close"
        ></i>
      </div>
    </div>
  );
};

export { GalleryContent, FullScreenGallery };
