import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PageBanner from "../../components/PageBanner";
import Layouts from "../../layouts/Layouts";

// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from "react-responsive-carousel";
import reviewService from "../../services/reviewService";
import Button from "../../components/Button";
import { validate } from "../../utils/validation";
import { toast } from "react-toastify";
import Joi from "joi";
import { feedBackSlider } from "../../sliderProps";
import "./style.css";
import { Swiper, SwiperSlide } from "swiper/react";
import Stars from "../../components/Stars/Stars";
import LoadingSpinner from "../../components/LoadingSpinner";

const defaultState = {
  name: "",
  email: "",
  message: "",
  stars: "",
};

const schema = Joi.object({
  name: Joi.string().required().min(3).max(50),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  stars: Joi.number().required(),
  message: Joi.string().required().min(3),
});

export default function ReviewsPage() {
  const { t } = useTranslation();

  return (
    <Layouts>
      <PageBanner pageName={t("reviews")} />

      <div className="display-reviews">
        <h2>{t("clients-say")}</h2>
      </div>
      <DisplayReviews />
      <AddReview />
    </Layouts>
  );
}

const DisplayReviews = () => {
  const [reviews, setReviews] = useState();

  useEffect(() => {
    try {
      reviewService.getApprovedReviews().then((res) => {
        if (res.data) {
          setReviews(res.data);
        }
      });
    } catch (err) {
      console.log(err);
    }
  }, []);
  return (
    <Swiper
      {...feedBackSlider}
      className="theme_carousel swiper-container pxy-50 review-swiper"
    >
      <div className="swiper-wrapper">
        {reviews ? (
          reviews.map((review, index) => {
            return (
              <SwiperSlide className="slide-item" key={index}>
                <SingleReview key={index} item={review} />
              </SwiperSlide>
            );
          })
        ) : (
          <LoadingSpinner />
        )}
      </div>
    </Swiper>
  );
};

const AddReview = () => {
  const star = "far fa-star";
  const activeStar = "fas fa-star";

  const { t } = useTranslation();
  const [formData, setFormData] = useState(defaultState);

  const totalStars = 5;
  const initial = 1;
  const [initialStarsState, setInitialStarsState] = useState();
  const [stars, setStars] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    // Define the initial state of the stars array.
    let initialStarsState = [];
    for (let i = 0; i < totalStars; i++) {
      if (i + 1 <= initial) {
        initialStarsState.push(activeStar);
      } else {
        initialStarsState.push(star);
      }
    }

    setInitialStarsState(initialStarsState);
    setStars(initialStarsState);
  }, [initial, totalStars]);

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  const submitHandler = async (e) => {
    e.preventDefault();
    const starsNum = stars.filter((x) => x === activeStar).length;
    let submitData = { ...formData };
    submitData.stars = starsNum;
    if ((await validate(submitData, schema, setErrors)) === null) {
      try {
        await reviewService.createReview({
          stars: starsNum,
          text: formData.message,
          senderName: formData.name,
          email: formData.email,
          status: "pending",
        });
        toast.success(t("send-review-success"));
        setFormData(defaultState);
      } catch (ex) {}
    }
  };

  function onStarHover(noStar) {
    let newStars = [...stars];

    // Set all previous stars as active
    for (let i = 0; i <= noStar; i++) {
      newStars[i] = activeStar;
    }

    // Reset all next stars
    for (let i = noStar + 1; i < initialStarsState.length; i++) {
      newStars[i] = star;
    }

    // setNoStars(noStar + 1);
    setStars(newStars);
    handleChange("stars", newStars);
  }

  const handleChange = (field, value) => {
    let dataCopy = { ...formData };
    dataCopy[field] = value;
    setFormData({ ...dataCopy });
  };

  return (
    <div className="contact-form style-two add-review-form-container">
      <h3 className="add-review-header">{t("add-review")}</h3>
      <div className="stars-container">
        <ul>
          {stars.map((star, index) => {
            return (
              <li key={index} onMouseEnter={() => onStarHover(index)}>
                <div className="single-star-wrapper">
                  <i className={`${star}`}></i>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
      <form
        onSubmit={submitHandler}
        style={{ width: "50%", display: "block", margin: "0 auto" }}
      >
        <div className="row">
          <div className="form-group col-md-6">
            <input
              value={formData.name}
              onChange={(e) => handleChange("name", e.currentTarget.value)}
              className="form-control"
              placeholder={t("name")}
            />
            <p className="form-error">{errors.name && t("valid-name")}</p>
          </div>
          <div className="form-group col-md-6">
            <input
              value={formData.email}
              onChange={(e) => handleChange("email", e.currentTarget.value)}
              className="form-control"
              placeholder={t("email")}
            />
            <p className="form-error">{errors.email && t("valid-email")}</p>
          </div>
        </div>
        <div className="form-group col-md-12">
          <textarea
            name="message"
            className="form-control"
            placeholder={t("message")}
            onChange={(e) => handleChange("message", e.currentTarget.value)}
            value={formData.message}
            // onChange={(e) => changeHandler("message", e.currentTarget.value)}
            // value={data.message}
          />
          <p className="form-error">{errors.message && t("valid-text")}</p>
        </div>
        <Button
          style={{ margin: "0 auto 30px auto", display: "block" }}
          text={t("send")}
        />
      </form>
    </div>
  );
};

const SingleReview = ({ item }) => {
  let email = item.email;
  let hiddenEmail = "";
  for (let i = 0; i < email.length; i++) {
    if (i > 1 && i < email.indexOf("@")) {
      hiddenEmail += "*";
    } else {
      hiddenEmail += email[i];
    }
  }
  return (
    <>
      <div className="inner-box single-review-box">
        <div className="author-info">
          <div className="rating-info">
            <div className="stars-container">
              {[...Array(5)].map((star, i) => {
                if (i + 1 <= item.stars) {
                  return <Stars isActive />;
                } else {
                  return <Stars isActive={false} />;
                }
              })}
            </div>
          </div>
          <h4 className="review-text">{item.text}</h4>
        </div>
        <div className="content">
          <div className="review-sender">{hiddenEmail}</div>
        </div>
      </div>
    </>
  );
};

export { DisplayReviews };
