import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  bg: {
    translation: {
      // Room names and descriptions
      "double-standard-deluxe": "Двойна стая стандарт DeLuxe",
      "double-sea-deluxe": "Двойна стая изглед море DeLuxe",
      "double-sea-superior-deluxe": "Двойна стaя море Superior DeLuxe",
      "junior-suit-deluxe": "Junior Suite DeLuxe",
      "apartment-deluxe": "Апартамент DeLuxe",
      "double-standard-park": "Двойна стая стандарт",
      "double-standard-sea": "Двойна стая изглед море",
      "double-standard-sea-superior": "Двойна стaя море Superior ",
      "junior-suit": "Junior Suite",
      "boutique-apartment": "Бутиков апартамент",
      "double-standard-deluxe-description":
        "Изцяло реновирани, съчетаващи стилен дизйн и съвременни технологии, стаите разполагат със спалня / king size / или две единични легла.",
      "double-sea-deluxe-description":
        "Изцяло реновирани, съчетаващи стилен дизйн и съвременни технологии, стаите предлагат завладяваща морска гледка. Разполагат със спалня / king size / или две единични легла.",
      "double-sea-superior-deluxe-description":
        "Просторни и комфортни, изцяло реновирани, съчетаващи стилен дизйн и съвременни технологии, стаите предлагат завладяваща морска гледка. Разполагат със спалня / king size / и разтегателен диван.",
      "junior-suit-deluxe-description":
        "Изцяло обновени, отличаващи се с изискана атмосфера и модерен дизайн, апартаментите тип junior suite се състоят от спалня и просторна всекидневна, сляти в едно обширно помещение, с панорамна гледка към морето. Разполагат със спалня / king size / и разтегателен диван.",
      "apartment-deluxe-description":
        "Изцяло обновени и разположени на последния девети етаж на хотела, апартаментите се отличават с изискан интериор и характерен дизай. Състоят се от хол и спалня и предлагат неповторима панорамна гледка към морския бряг и комплекса. Разполагат със спалня / king size / и разтегателен диван.",
      "double-standard-park-description":
        "Просторни и светли, двойните стаи стандарт, съчетават комфортен дизайн и всички удобстава за пълноценен престой. Стаите разполагат с две единични легла.",
      "double-standard-sea-description":
        "Елегантно обзаведени, двойните стаи с изглед към морето, осигуряват всички съвременни удобстава. Отличават се с уникалната си гледка към морето. Стаите разполагат с две единични легла.",
      "double-standard-sea-superior-description":
        "Двойните стаи Superior предлагат защеметяващ гледка към морето и панорамен изглед към центъра на комплекса. Разполагат със спалня / king size / и разтегателен диван.",
      "junior-suit-description":
        "Апартаментите тип junior suite се състоят от спалня и просторна всекидневна, сляти в едно обширно помещение, с панорамна гледка към морето. Разполагат със спалня / king size / и разтегателен диван.",
      "boutique-apartment-description":
        "Този луксозен бутиков апартамент с великолепен изглед към морето е разположен на 9-ия етаж. Проектиран в стилен дизайн с впечатляващ декор, бутикът се състои от 2 спални, две бани с душ, отворена всекидневна зона с кухня, три балконa с изглед към морето, трапезария и огромен диван, разположен пред 75 инчов телевизор. ",
      sea: "Море",
      park: "Парк",

      // Tabs
      home: "Начало",
      about: "За нас",
      accommodation: "Настаняване",
      events: "Събития",
      offers: "Оферти",
      reviews: "Ревюта",
      gallery: "Галерия",
      contact: "Контакти",
      services: "Удобства",
      sport: "Отдих и Спорт",
      room: "Стаи",
      rooms: "Стаи",

      // Admin tabs
      "all-offers": "Всички оферти",
      "create-offer": "Създай оферта",
      vouchers: "Ваучери",
      "approve-reviews": "Одобри ревюта",

      // Home
      welcome: "Добре дошли в",
      "hotel-admiral": "Хотел Адмирал",
      "one-sentence-about":
        "Емблематичен за курорта Златни пясъци, разположен на самия морски бряг, хотел Адмирал предлага изключителни условия за Вашата пълноценна почивка.",
      "one-sentence-about-2": `Като част от нашето топло посрещане, можете да очаквате персонално внимание, лично отношение и искрена усмивка, които отразяват нашия ангажимент за изключително гостоприемство. Нашите рецепционисти са добре запознати с обслужването на клиенти и ще направят всичко възможно, за да гарантират Вашия комфорт и удовлетвореност по време на престоя Ви. Независимо дали се нуждаете от съдействие за резервация на ресторант, организиране на транспорт или друго искане, екипът ни на рецепция, ще бъде на Ваше разположение 24 часа в денонощието. Очакваме с нетърпение да Ви посрещнем в хотел "Адмирал" и да Ви осигурим приятно изживяване, което да надмине очакванията Ви.`,

      // About
      "about-text-one":
        "Преживейте разликата от стандартната All Inclusive формула с новият ни 5 звезден Luxury Ultra All Inclusive.",
      "about-text-two":
        "С невероятната си и несравнима локация в центъра на комплекс Златни пясъци и пред самия бряг на морето, изцяло реновиран за лято 2023 петзвездният хотел Адмирал, предоставя на своите гости високо ниво на качество във всяко едно отношение. Елегантно обзаведени с прекрасна комбинация от пространство и стил, 300 двойни, фамилни стаи (две помещения, свързани с междинна врата) и апартаменти създават изключителна атмосфера за една незабравима и перфектната почивка.",
      "about-text-three":
        "С напитка за Добре дошли , започва Luxury Ultra All Inclusive.",
      "own-beach": "Плаж",
      "outdoor-pool": "Басейн",
      parking: "Паркинг",
      "pool-table": "Билярд",
      "lobby-bar": "Лоби бар",
      "grill-service": "Грил",
      "piano-bar-service": "Пиано бар",
      "rent-bicycle-service": "Наем на велосипед",
      "amore-service": "aMore Moonlight",
      confectionery: "Виенска сладкарница",
      "confectionery-working-hours": "Работно време - 09:00 - 23:00",

      "bar-mezzanine": "Lounge Piano bar",
      fitness: "Фитнес",
      vault: "Сейф",
      "currency-exchange": "Обменно бюро",
      restaurant: "Admiral",
      restaurants: "Ресторанти",
      conference: "Конферентни зали",
      laundry: "Пералня",
      wifi: "Интернет",
      breakfast: "Закуска",
      spa: "AquaBliss Спа Център",
      minibar: "Минибар",
      ergonomic: "Ергономичен матрак",
      "TV-55": "Цифрова телевизия 55″",
      "TV-75": "Цифрова телевизия 75″",
      nespresso: "Кафемашина Nespresso",
      cosmetics: "Козметика Aqua dellËlba",
      AC: "Климатик",
      balcon: "Балкон",
      "TV-32": "Цифрова телевизия 32″",
      armchair: "Разтегателен фотьойл",
      "coffee-machine": "Кафемашина",
      couch: "Диван",
      "touch-light": "Touch осветление",

      // Sport
      "admiral-beach": "Плаж Адмирал",
      "admiral-beach-text":
        "Хотел Адмирал разполага със собствен плаж, на който гостите могат да се насладят само на няколко метра от хотела. Широката плажна ивица е равна, покрита със златист ситен пясък. Пясъчното морско дъно плавно се спуска в морето, липсват скали и резки промени на дъното. Чистите и спокойни морски води с ниско съдържание на соли, по-ниско от това на Средиземно море например и отсъствие на опасни за хората риби и животни, са подходящи за къпане на деца и плуване.",
      "admiral-beach-working-hours": "Работно време: 08:00 - 17:30",
      "outdoor-swimming-pool": "Външен басейн",
      "outdoor-swimming-pool-text":
        "Големият външен басейн пред хотела, разположен на самия плаж, е едно от любимите места за отдих на посетителите на хотела. Оборудван с многобройни чадъри и удобни шезлонги, басейнът дава възможност на посетителите си да се насладят на морският бриз и шума на вълните, на метри от рецепцията на хотела.",
      "outdoor-swimming-pool-working-hours": "Работно време: 08:00 - 17:00",
      gym: "Фитнес зала",
      "gym-text":
        "За любителите на спорта хотел Адмирал предлага достъп до модерно оборудвана фитнес зала, намираща се в самия хотел.",
      "gym-working-hours": "Работно време: 08:00 - 20:00",
      "indoor-swimming-pool": "Вътрешен минерален басейн",
      "indoor-swimming-pool-text":
        "За приятния и релаксиращ престой на своите гости, Хотел Адмирал е осигурил вътрешен басейн с минерална вода, достъпен през дъждовните дни, разположен на самостоятелен етаж в сградата.",
      "indoor-swimming-pool-working-hours": "Работно време: 08:00 - 20:00",
      "spa-text":
        'Представяме ви чисто новия ни "AquaBliss" Admiral Спа Център в хотел Адмирал, Златни пясъци! Открийте място на спокойствие и подмладяване в нашето модерно спа съоръжение. С набор от луксозни удобства и изключителни услуги, ние ви каним да се отдадете на върховното релаксиращо изживяване. Нашият любезен персонал на рецепцията информира гостите за допълнителни услуги, като избор от различни масажи, 3 вида сауни – високотемпературна, нискотемпературна и инфрачервена, както и парна баня.',
      "bicycle-rent": "Наем на велосипед",
      "bicycle-rent-text":
        "Наемете велосипед - това е чудесен начин да прекарате активно деня и уикенда. Колоезденето е забавен, ефективен и лесен начин за придвижване из Златни пясъци - точно както правят местните жители! Дори и да въртите педалите само няколко часа, карането на колело е незаменимо преживяване за Златни пясъци. Най-добрият ефективен начин за придвижване из курорта е с велосипед. Ако се интересувате от разглеждане на забележителности с велосипед, имаме няколко чудесни предложения за велосипедни обиколки. Наемането на велосипед в Златни пясъци е доста лесно. Отдаваме велосипеди под наем за един час или за един ден, въпреки че най-често срещаният наем е за до 3 часа. Когато наемате велосипед, трябва да внесете депозит.",
      "bicycle-rent-working-hours": "Работно време - 24/7",

      // Events
      parties: "Сватби и фирмени тържества",
      "parties-text-1":
        "Хотел Адмирал разполага с многофункционална зала подходяща за балове, сватби и фирмени тържества.",
      "parties-text-2":
        "Екипът на хотела ще откликне с идеи и професионализъм за перфектната организация и провеждане на събитието.",
      conferences: "Конференции и бизнес срещи",
      "conferences-text-1":
        "Хотелът осигурява целогодишно отлични условия за организиране на мероприятия от различен характер: конференции, семинари, работни срещи, бизнес форуми, презентации, изложби, фирмени обучения и тържетсва.",
      "conferences-text-2":
        "Семинарната зала е с максимален капацитет 80 места / при конфигурация театър / и площ 100 кв.м. Подходяща за семинари, пресконференци и работни срещи.",
      "conferences-text-3":
        "Многофункционалната зала е с максимален капацитет 550 места и площ 520 кв.",
      "conferences-text-4":
        "Подходяща за конференции, презентации, коктейли и гала вечери.",

      "check-availability": "Провери наличност",
      "contact-us": "Свържи се с нас",
      "useful-links": "Полезни линкове",
      "email-address": "Имейл адрес",
      name: "Име",
      "last-name": "Фамилия",
      subject: "Тема",
      message: "Съобщение",
      "send-message": "Изпрати съобщение",
      telephone: "Телефон",
      email: "Имейл",
      address: "Адрес",
      fax: "факс",
      "add-review": "Добави ревю",
      send: "Изпрати",
      "general-terms": "Общи условия",
      privacy: "Политика за поверителност",
      terms: "Условия за ползване",
      seminars: "Семинари",
      "check-in": "Настаняване",
      "check-out": "Напускане",
      children: "Деца",
      child: "Дете",
      guests: "Гости",
      book: "Запази",
      beds: "Легла",
      "room-size": "Размер на стаята",
      capacity: "Капацитет на стаята",
      price: "Цена",
      night: "Вечер",
      all: "Всички",
      hotel: "Хотел",
      pool: "Басейн",
      dining: "Заведения",
      bar: "Барове",
      beach: "Плаж",
      "room-details": "Детайли за Стаята",
      azzurro: "Azzurro",
      "asian-samba": "Asian samba Bar & Dinner",
      amore: "aMore Moonlight",
      tortuga: "Tortuga Beach Club",
      "affair-bbq": "Affair BBQ Beach",

      "more-about-us": "Повече за нас",
      "hotel-address": "9007 Златни пясъци, Варна",
      "all-rights-reserved": "Хотел Адмирал. Всички права запазени",
      "see-more": "Виж повече",
      "clients-say": "Какво казват клиентите",

      // Restaurants page
      "restaurant-text":
        "Изцяло обновен, основният ни ресторант Адмирал, предлага богато разнообразие от ястия и напитки. Насладете се на вкусно приготвени морски дарове, месни специалитети, свежи салати и изкусителни десерти, сервирани на елегантен бюфет. С гарантирано високо качество на обслужване и кулинарни изкушения, Ви каним да се потопите в истинско кулинарно удоволствие!",
      "restaurant-text-2":
        "Веднъж седмично може да се насладите на тематичните ни Българска и Италианска вечеря",
      "restaurant-working-hours": "Работно време:",
      "restaurant-working-hours-1": "Закуска на блок-маса – 07:30 – 10:00 ч.",
      "restaurant-working-hours-2": "Обяд на блок-маса – 12:30 – 14:30 ч.",
      "restaurant-working-hours-3": "Вечеря на блок-маса – 18:00 – 21:00 ч.",
      "lobby-bar-text":
        "Разположен в сърцето на хотела, нашият лоби бар предлага богат избор от кафе специалитети, освежаващи коктейли , премиум напитки, идеални за прохладен релакс след дълъг ден или за среща с приятели.",
      "lobby-bar-working-hours": "Работно време - 24/7",
      "confectionery-text":
        "Виенската сладкарница на хотел Адмирал, изцяло реновирана за сезон 2024 съчетава уют и финес и предлага изтънчено меню с многобройни сладки изкушения собствено производство. Заповядайте и се насладете на сладостите на живота!",
      "bar-mezzanine-text": "Melodies of Elegance 🔝🎹🎶",
      "bar-mezzanine-text-2": `Лаундж пиано барът ни в хотел "Адмирал" е нещо повече от място - това е покана да избягате от обикновеното и да се потопите в свят на изискани удоволствия. Това е възможност да създадете скъпи спомени, в които хармоничното съчетание на жива музика, изискана атмосфера и безупречно обслужване се преплитат в една вечер, която ще остане в паметта ви за години напред. Впуснете се в това необикновено пътешествие и ни позволете да преосмислим представата ви за лукс.`,
      "bar-mezzanine-text-3": "Музика на живо всяка вечер",
      "bar-mezzanine-working-hours": "Работно време - 20:30 - 22:30",
      "swim-up": "Swim-up Pool Бар",
      "swim-up-text":
        "Swim-up Pool Барът на хотел Адмирал е перфектното място за освежаваща почивка и незабравими летни моменти. Насладете се на уникалното преживяване да отпивате коктейли и освежаващи напитки, без да напускате басейна. Нашият бар предлага разнообразие от екзотични коктейли, свежи сокове и леки закуски, които ще удовлетворят вашите вкусове, докато се наслаждавате на слънцето и водата. В идеална комбинация от релакс и забавление, Swim-up Pool Барът създава идеалната атмосфера за почивка и забавление. Заповядайте и се насладете на лятото с стил в Swim-up Pool Бара на хотел Адмирал!",
      "swim-up-working-hours": "Работно време - 09:00 - 18:00",
      "azzurro-text":
        "Добре дошли в нашия средиземноморски ресторант Azzurro, разположен на самият плаж пред хотел „Адмирал“ на брега на Златни пясъци. Докато се наслаждавате на кулинарните предложения, допълнете ястието си със селекция от внимателно подбраната ни винена листа. Нашият всеотдаен персонал се стреми да осигури изключително обслужване, за да гарантира, че всеки момент от посещението ви ще бъде удоволствие. Независимо дали празнувате специален повод, наслаждавате се на романтична вечер или просто търсите незабравимо ястие, нашият внимателен екип е тук, за да направи преживяването ви незабравимо. Елате и се насладете на истинската същност на Средиземноморието, където вкусната кухня и очарованието на плажа се съчетават, за да създадат едно необикновено кулинарно преживяване.",
      "azzurro-text-2":
        "1 безплатна вечеря за гости с престой от минимум 7 нощувки - с предварителна резервация минимум 1 ден преди това на рецепция",
      "asian-samba-text":
        "Отдайте се на кулинарно пътешествие като никое друго в нашия азиатски ресторант. Навлезте в свят на изискани вкусове и завладяващи аромати, който ще ви пренесе в сърцето на Азия. Нашите опитни готвачи старателно са съставили меню, което показва богатото разнообразие на азиатската кухня. Нашият внимателен персонал е посветен на това да ви осигури незабравимо изживяване, като гарантира, че всяка ваша нужда ще бъде удовлетворена. Присъединете се към нас в нашия азиатски ресторант в хотел Адмирал и се впуснете в кулинарно приключение, което ще ви накара да жадувате за още. Сърдечно Ви приветстваме да опознаете вкусовете на Азия и да създадете незабравими спомени с нас.",
      "asian-samba-text-2":
        "За гости с престой от минимум 7 нощувки 1 безплатна вечеря - с предварителна резервация минимум 1 ден преди това на рецепция",
      "amore-text":
        "Представяме ви едно спиращо дъха изживяване, което ще ви разтърси и ще ви пренесе в свят на вълшебство - нашата ексклузивна романтична вечеря на плажа, организирана в ресторант aMore Moonlight, разположен на плажа пред хотел Адмирал. Когато се настаните на интимната си маса на брега на морето, украсена с деликатна светлина на свещи и изящни цветни аранжировки, ще бъдете посрещнати от нашия топъл и внимателен персонал, който се грижи всеки момент от вечерта ви да бъде изпълнен с вълшебство. Независимо дали празнувате специален повод, изненадвате партньора си с искрен жест или просто се отдавате на незабравимо преживяване, нашата Романтична вечеря на плажа в ресторант aMore Moonlight е олицетворение на романтиката и лукса. Потопете се в красотата на природата, изисканата кухня и блажената връзка с любимия човек. Резервирайте масата си сега на рецепцията и ни позволете да създадем вечер, която завинаги ще се запечата в сърцата ви, оставяйки ви спомени, които ще пазите цял живот.",
      "amore-text-2":
        "1 безплатна вечеря за гости с престой от минимум 7 нощувки - с предварителна резервация минимум 1 ден преди това на рецепция.",
      "amore-text-3":
        '"Amore moonlight" - вечеря на свещи е възможна през високия сезон (юли, август) и според метеорологичната прогноза',
      "tortuga-text":
        "А ла карт surf&turf ресторант - с предварителна резервация срещу допълнително заплащане. Единствено гостите на хотел Aдмирал ползват специална отстъпка от 10% от цените в менюто. Пригответе се да разкриете пълното преживяване на Tortuga - от споделеното празнуване до задушевната трапеза и благополучието.                                                                           През този сезон ще имате възможност да се впуснете в кулинарното ни пътешествие като никое друго, тъй като сме подготвили много нови попълнения в менюто ни, както и новото ни меню за закуска. Очакваме ви!",
      "tortuga-text-2": "",
      "tortuga-text-3": "",
      "affair-bbq-text": "Плажен Grill Bar",

      "azzurro-working-hours-1": "Късна закуска 10:30 - 12:00ч.",
      "azzurro-working-hours": "Обяд 12:30 - 14:30ч.",
      "azzurro-working-hours-2": "Вечеря  19:30 - 22:30ч.",
      "asian-samba-working-hours": "Вечеря 19:00 - 22:00ч.",
      // "asian-samba-working-hours-2": "Бар - до 00:30ч.",
      "amore-working-hours": "Вечеря 20:30 - 22:30ч.",
      "amore-note": "Ресторантът работи през юли и август",
      "affair-working-hours": "Обяд 12:30 - 14:30ч.",
      "tortuga-working-hours": "Работно време: 10:00 - 00:00ч.",

      payment: "Плащане",
      pay: "Плати",
      "card-details": "Детайли на картата",
      details: "Детайли",
      "payment-success": "Успешно плащане",
      "payment-failed": "Плащането е неуспешно",
      pricing: "Цени",
      meters: "м²",
      "booking-summary": "Обобщение",
      bgn: "лв.",
      view: "Гледка",
      showerhead: "Душ (кабина)",
      tv: "Телевизор",
      conditioner: "Климатик",
      dryer: "Сешоар",
      phone: "Телефон",
      adults: "Възрастни",
      wrong: "Възникна проблем.",
      "send-review-success": "Успешно изпратихте вашия отзив!",
      "send-email-success": "Успешно изпратихте вашето съобщение!",
      "send-email-fail": "Възникна проблем с изпращането на вашето съобщение!",
      "no-offers": "Няма намерени оферти.",
      "invalid-period": "Моля изберете валиден период.",
      reservations: "Резервации",
      cancellation: "Анулиране на резервация",
      "ref-number": "Референтен номер",
      "ref-not-found": "Невалиден референтен номер",
      "booking-cancelled": "Резервацията е анулирана",
      apply: "Приложи",
      "not-found": "Няма намерен ваучер.",
      "voucher-expired": "Ваучерът е изтекъл",
      "wrong-room-type": "Ваучерът не може да бъде използван за този тип стая",
      voucher: "Ваучер",
      "voucher-success": "Ваучерът беше успешно приложен.",
      "no-room-found": "Няма свободни стаи за избрания период.",
      "enjoy-dream-vacation": "Насладете се на мечтаната почивка!",
      size: "Площ",
      "capacity-single": "Капацитет",
      "total-price": "Обща цена",
      continue: "Продължи",
      "dates-were-booked": "Избраните дати са заети.",
      "no-rooms": "Няма свободни стаи за избрания период.",
      "view-room": "Виж стаята",

      "our-rooms": "Нашите стаи",

      "required-field": "Полето е задължително!",
      "room-number": "Брой стаи",
      "year-cap": "(до 11.99г)",
      "rooms-and-suites": `Стаи & Апартаменти`,

      "valid-name": "Моля, въведете вашето име.",
      "valid-email": "Моля, въведете валиден имейл адрес.",
      "valid-text": "Моля, въведете текст.",
      "additional-services": "Допълнителни услуги",
      "choose-adults": "Моля, изберете брой възрастни.",
      "room-single": "стая",
      "already-paid": "Резервацията вече е платена.",
      "cannot-cancel":
        "Резервацията не може да бъде анулирана. Моля, свържете се с нас за съдействие.",
      "type-reference-number": "Моля, въведете референтен номер.",
      "already-cancelled": "Резервацията вече е анулирана.",
      subscribe: "Абонирай се",
      newsletter: "Бюлетина",
      "invalid-email": "Невалиден имейл адрес",
      subscribers: "Абонати",
      "subscribe-success": "Успешно се абонирахте за нашите новини!",
      reserve: "Резервирай",
      "food-board": "Изхранване",
      "bed-and-breakfast": "Закуска",
      "half-board": "Закуска и вечеря",
      "full-board": "Закуска, обяд и вечеря",
      "all-light": "All-inclusive light",
      sqm: "м2",
      "send-inquiry": "Изпрати запитване",
      "ultra-all-inclusive": "Ултра Ол Инклузив",

      "voucher-min-nights":
        "Ваучерът може да се използва при резервации от минимум {{minNights}} нощувки ",

      gallery_all: "Всички",
      gallery_hotel: "Хотел",
      gallery_rooms: "Стаи",
      gallery_wiener_cafe: "Виенско кафе",
      gallery_asian_restaurant: "Азиатски ресторант",
      gallery_azzurro: "Azzurro",
      gallerry_piano_bar: "Пиано бар",
      gallerry_pool_bar_and_beach: "Басейн & Плаж",
      gallerry_fitness_and_spa: "Фитнес & СПА",

      authenticating_payment: "Плащането се верифицира",
      cant_accomodate:
        "Избраните стаи нямат капацитет за {{adults}} възрастни. Моля, изберете повече стаи!",
      cant_accomodate_with_children:
        "Избраните стаи нямат капацитет за {{adults}} възрастни и {{children}} деца. Моля, изберете повече стаи!",
      ny_min_stay: "Минимален престой от 3 нощувки",
    },
  },
  en: {
    translation: {
      "double-standard-deluxe": "Double DeLuxe room",
      "double-sea-deluxe": "Double DeLuxe room with a sea view",
      "double-sea-superior-deluxe": "Double sea superior Deluxe",
      "junior-suit-deluxe": "Junior suite DeLuxe",
      "apartment-deluxe": "DeLuxe Apartment SV",
      "double-standard-park": "Double room ‒ Standard",
      "double-standard-sea": "Double room with a sea view",
      "double-standard-sea-superior": "Double room with a sea view Superior",
      "junior-suit": "Junior Suite",
      "boutique-apartment": "Boutique Apartment",
      "double-standard-deluxe-description":
        "Fully renovated, combining a stylish design and modern technologies, the rooms are furnished with a king size bed or two single beds.",
      "double-sea-deluxe-description": `Fully renovated, combining a stylish design and modern technologies, the rooms command a striking sea view. All rooms have a king size bed or two single beds.`,
      "double-sea-superior-deluxe-description":
        "Spacious, comfortable and fully renovated, combining stylish design with modern technologies, the rooms offer a captivating sea view. The rooms are furnished with a king size bed and a sofabed.",
      "junior-suit-deluxe-description":
        "Fully renovated, distinguished for their elegant  atmosphere and modern design, the Junior suites consist of a bedroom and a spacious living-room in an open plan, with a panoramic sea view. Furnished with a king size bed and a sofabed. ",
      "apartment-deluxe-description":
        "Fully renovated and located on the last ninth floor of the hotel, these suites are distinguished for their sophisticated interior and signature design. They consist of a sitting room and a bedroom and afford an unsurpassable panorama of the beach and the resort. Furnished with a king size bed and a sofabed.",
      "double-standard-park-description":
        "Spacious and light, the standard double rooms sport a comfortable design and all the conveniences for a fulfilling stay. The rooms are furnished with two single beds. ",
      "double-standard-sea-description":
        "Elegantly furnished, the double rooms with a view of the sea guarantee all modern conveniences and comforts. Distinguished for the unique view of the sea they afford. The rooms are furnished with two single beds.",
      "double-standard-sea-superior-description":
        "The Superior double rooms offer spectacular view of the sea and a panorama of the resort centre. They are furnished with a king size bed and a sofabed.",
      "junior-suit-description":
        "The Junior Suites consist of a bedroom and a spacious living-room, combined in an open plan, with a panoramic view of the sea. Furnished with a king size bed and a sofabed.",
      "boutique-apartment-description":
        "This luxury boutique apartment with magnificent sea views is located on the 9th floor.With the stylish design and an impressive decor, the boutique consists of 2 bedrooms, two bathrooms with shower, open plan living area with kitchen three balconies overlooking the sea, a dining table and a huge sofa located in front of a 75 inch TV.",
      rooms: "Rooms",
      sea: "Sea",
      park: "Park",

      // Navigation tabs
      home: "Home",
      about: "About us",
      room: "Rooms",
      events: "Events",
      offers: "Offers",
      reviews: "Reviews",
      gallery: "Gallery",
      contact: "Contact us",
      services: "Services",
      sport: "Recreation and Sport",

      // Home
      welcome: "Welcome to",
      "hotel-admiral": "Hotel Admiral",
      "one-sentence-about":
        "Iconic for the Golden Sands Resort and located on the beach, the Admiral Hotel offer bespoke conditions for a delightful holiday.",
      "one-sentence-about-2": `As part of our warm welcome, you can expect personalised attention and a genuine smile that reflects our commitment to exceptional hospitality. Our receptionists are well-versed in customer service, and they will go the extra mile to ensure your comfort and satisfaction throughout your stay. Whether you need assistance with restaurant reservations, transportation arrangements, or any other request, our reception team will bе available 24 hours a day. We look forward to welcoming you to Hotel Admiral and providing you with a delightful experience that exceeds your expectations.Our guests are always welcome warmly `,

      // About
      "about-text-one":
        "Experience the difference from the standard All Inclusive formula with our new 5 star Luxury Ultra All Inclusive.",
      "about-text-two":
        "With its incredible and incomparable location in the center of the Golden Sands complex and right by the waterfront, the five-star Admiral Hotel, completely renovated for summer 2023, provides its guests with high level quality in every respect.Elegantly furnished with a wonderful combination of space and style, 300 double, family rooms (two rooms connected with an adjacent door) and suites create an exceptional atmosphere for an unforgettable perfect holiday.",
      "about-text-three":
        "With a welcome drink, Luxury Ultra All Inclusive begins.",

      // Services names
      "own-beach": "Beach",
      "outdoor-pool": "Pool",
      parking: "Parking",
      "pool-table": "Pool table",
      "lobby-bar": "Lobby bar",
      "grill-service": "BBQ",
      "piano-bar-service": "Piano bar",
      "rent-bicycle-service": "Rent a bicycle",
      "amore-service": "aMore Moonlight",
      confectionery: "Viennese Patisserie",
      "confectionery-working-hours": "Working hours - 09:00 - 23:00",

      "bar-mezzanine": "Lounge Piano bar",
      fitness: "Fitness",
      vault: "Vault",
      "currency-exchange": "Currency exchange",
      restaurant: "Admiral",
      restaurants: "Restaurants",
      conference: "Conference rooms",
      laundry: "Laundry",
      wifi: "Wifi",
      breakfast: "Breakfast",
      spa: "AquaBliss Spa & Beauty",
      minibar: "Minibar",
      ergonomic: "Ergonomic matress",
      "TV-55": "Digital TV 55 ″",
      "TV-75": "Digital TV 75 ″",
      nespresso: "Nespresso machine",
      cosmetics: "Aqua dellËlba cosmetics",
      AC: "Air conditioner",
      balcon: "Balcony",
      "TV-32": "Digital TV 32 ″",
      armchair: "Extendable armchair",
      "coffee-machine": "Coffee-machine",
      couch: "Couch",
      "touch-light": "Touch lights",

      // Sport
      "admiral-beach": "Admiral Beach",
      "admiral-beach-text":
        "The Admiral Hotel has its own beach, which the guests can enjoy just a few meters from the hotel. The wide beach is flat and covered with fine golden sand. The sandy sea bed goes gradually deeper into the sea, there are no rocks, nor sharp changes in the seabed depth. The clear and calm sea water with low mineralisation, lower than the Mediterranean for instance, and absence of dangerous to humans fish and marine life are suitable for children and underwater diving.",
      "admiral-beach-working-hours": "Working hours: 08:00 - 17:30",
      "outdoor-swimming-pool": "Outdoor swimming pool",
      "outdoor-swimming-pool-text":
        "The large outdoor swimming pool in front of the hotel, situated on the very beach, is one of the favourite places where hotel guests can unwind. Furnished with numerous parasols and comfortable chaise longues, the pool gives the guest the opportunity to enjoy the sea breeze and the sound of the waves only meters away from the hotel reception.",
      "outdoor-swimming-pool-working-hours": "Working hours: 08:00 - 17:00",
      gym: "Gym",
      "gym-text":
        "For sports lovers the Admiral offers its guests access to a state-of-the-art gym located inside the hotel.",
      "gym-working-hours": "Working hours: 08:00 - 20:00",
      "indoor-swimming-pool": "Indoor swimming pool with mineral water",
      "indoor-swimming-pool-text":
        "For the delightful and relaxing stay of its guests, Hotel Admiral has provided an indoor pool with mineral water, accessible on rainy days, located on a separate floor in the building.",
      "indoor-swimming-pool-working-hours": "Working hours: 08:00 - 20:00",
      "spa-text":
        'Introducing our Brand New "AquaBliss" Admiral Spa at Hotel Admiral, Golden Sands! Discover a haven of tranquility and rejuvenation at our state-of-the-art spa facility. With a range of luxurious amenities and exceptional services, we invite you to indulge in the ultimate relaxation experience. Our friendly reception staff inform the guests about additional services, such as a choice of different massages, 3 types of sauna – high-temperature, low-temperature and infrared, and a steam bath.',
      "bicycle-rent": "Rent a bicycle",
      "bicycle-rent-text":
        "Rent a bicycle, it's a great way to spend an active day and weekend. Cycling is a fun, efficient and easy way of getting around Golden sands - just like the locals do! Even if you only pedal around for a few hours, riding a bike is an essential Golden sands experience. The best efficient way to get around the resort is by bike. If you are interested in sightseeing by bike, we have some great bike tour suggestions. Renting a bike in Golden sands is pretty easy. We rent bikes for an hour or a day, although the most common rental is for up to 3 hours. When you rent a bike, you will put down a deposit.",
      "bicycle-rent-working-hours": "Working hours - 24/7",

      // Events
      parties: "Weddings and corporate parties",
      "parties-text-1":
        "The Admiral offers a multifunctional hall suitable for balls, weddings and corporate parties.",
      "parties-text-2":
        "The hotel staff will come up with ideas and offer a professional approach for the perfect organisation and realisation of the event.",
      conferences: "Conferences and business events",
      "conferences-text-1":
        "The hotel is open all year round and provides excellent conditions for organising events of various kinds: conferences, seminars, workshops, business forums, presentations, exhibitions, company trainings and celebrations.",
      "conferences-text-2":
        "The seminar hall seats a maximum of 80 /in theatre configuration/ and an area of 100 sq.m. suitable for seminars, press conferences and workshops.",
      "conferences-text-3":
        "The multifunctional hall seats a maximum of 550 and is 520 sq.m.",
      "conferences-text-4":
        "Suitable for conferences, presentations, cocktail parties and gala dinners.",

      "check-availability": "Check availability",
      "contact-us": "Contact us",
      "useful-links": "Useful links",
      "email-address": "Email address",
      name: "First Name",
      "last-name": "Last Name",
      subject: "Subject",
      message: "Message",
      "send-message": "Send a message",
      telephone: "Phone number",
      email: "Email",
      address: "Address",
      fax: "fax",
      "add-review": "Add review",
      send: "Send",
      "general-terms": "Terms and Conditions",
      privacy: "Privacy Policy",
      terms: "Booking Terms",
      seminars: "Seminars",
      accommodation: "Accommodation",
      "check-in": "Check - in",
      "check-out": "Check -out",
      children: "Children",
      child: "Child",
      guests: "Guests",
      book: "Book",
      beds: "Beds",
      "room-size": "Room size",
      capacity: "Capacity",
      price: "Price",
      night: "Night",
      all: "All",
      hotel: "Hotel",
      pool: "Pool",
      dining: "Dining",
      bar: "Bar",
      beach: "Beach",
      "room-details": "Room Details",
      azzurro: "Azzurro",
      "asian-samba": "Asian samba Bar & Dinner",
      amore: "aMore Moonlight",
      tortuga: "Tortuga Beach Club",
      "affair-bbq": "Аffair BBQ beach",

      "more-about-us": "More about us",
      "hotel-address": "9007 Golden Sands, Varna",
      "all-rights-reserved": "Hotel Admiral. All rights reserved",
      "see-more": "See more",
      "clients-say": "What do clients say",

      // Restaurant page
      "restaurant-text":
        "Completely renovated, our main restaurant Admiral, offers a wide variety of dishes and drinks. Enjoy deliciously prepared seafood, meat specialties, fresh salads and tempting desserts served on an elegant buffet. With guaranteed high quality service and culinary delights, we invite you to indulge in a true culinary delight!",
      "restaurant-text-2":
        "Once a week you can enjoy our themed Bulgarian and Italian dinner.",
      "restaurant-working-hours": "Working hours:",
      "restaurant-working-hours-1": "Breakfast buffet - 07:30 - 10:00",
      "restaurant-working-hours-2": "Lunch buffet - 12:30 - 14:30",
      "restaurant-working-hours-3": "Dinner buffet - 18:00 - 21:00",
      "lobby-bar-text":
        "Located in the heart of the hotel, our lobby bar offers a wide selection of coffee specialties, refreshing cocktails , premium drinks, perfect for a cool relax after a long day or for meeting friends.",
      "lobby-bar-working-hours": "Working hours - 24/7",
      "confectionery-text":
        "The Viennese Patisserie of the Admiral Hotel, completely renovated for the 2024 season, combines comfort and refinement and offers a sophisticated menu with numerous sweet delights of its own production. Come and enjoy the sweets of life!",
      "bar-mezzanine-text": "Melodies of Elegance 🔝🎹🎶",
      "bar-mezzanine-text-2":
        "Our Lounge Piano Bar at the Admiral Hotel is more than just a place - it's an invitation to escape the ordinary and immerse yourself in a world of exquisite pleasures. It's an opportunity to create treasured memories where the harmonious blend of live music, sophisticated atmosphere and impeccable service intertwine in an evening that will remain in your memory for years to come. Embark on this extraordinary journey and let us redefine your idea of luxury.",
      "bar-mezzanine-text-3": "Live music every night",
      "bar-mezzanine-working-hours": "Working hours - 20:30 - 22:30",
      "swim-up": "Swim-up Pool Bar",
      "swim-up-text":
        "The Admiral Hotel's Swim-up Pool Bar is the perfect place for a refreshing break and unforgettable summer moments. Enjoy the unique experience of sipping cocktails and refreshing drinks without leaving the pool. Our bar offers a variety of exotic cocktails, fresh juices and snacks that will satisfy your tastes while you enjoy the sun and the water. In an ideal combination of relaxation and fun, the Swim-up Pool Bar creates the perfect atmosphere for relaxation and entertainment. Come and enjoy the summer in style at the Swim-up Pool Bar at the Admiral Hotel!",
      "swim-up-working-hours": "Working hours - 09:00 – 18:00",
      "azzurro-text":
        "Welcome to our Mediterranean restaurant Azzurro, located right on the beach in front of the Admiral Hotel on the shores of Golden Sands. While you enjoy the culinary offerings, complement your meal with a selection from our carefully curated wine list. Our dedicated staff strives to provide exceptional service to ensure that every moment of your visit will be a pleasure. Whether you're celebrating a special occasion, enjoying a romantic evening, or simply looking for an unforgettable meal, our attentive staff is here to make your experience unforgettable. Come and enjoy the true essence of the Mediterranean, where delicious cuisine and the charm of the beach combine to create an extraordinary dining experience.",
      "azzurro-text-2":
        "1 complimentary dinner for guests staying a minimum of 7 nights - with a minimum of 1 day's prior reservation at reception",
      "asian-samba-text":
        "Indulge in a culinary journey like no other at our Asian restaurant. Enter a world of exquisite flavours and captivating aromas that will transport you to the heart of Asia. Our experienced chefs have carefully crafted a menu that showcases the rich diversity of Asian cuisine. Our attentive staff is dedicated to providing you with an unforgettable experience, ensuring that your every need is met. Join us at our Asian restaurant at the Admiral Hotel and embark on a culinary adventure that will leave you craving for more. We warmly welcome you to explore the flavors of Asia and create unforgettable memories with us.",
      "asian-samba-text-2":
        "For guests staying a minimum of 7 nights, 1 complimentary dinner - with a minimum of 1 day prior reservation at the front desk",
      "amore-text":
        "Introducing a breathtaking experience that will sweep you off your feet and carrying you to a world of enchantment - our exclusive Romantic Dinner at the Beach, hosted at aMore Moonlight Restaurant, nestled at the beach infront Admiral Hotel. As you settle into your intimate beachfront table, adorned with delicate candlelight and exquisite floral arrangements, you'll be greeted by our warm and attentive staff, dedicated to ensuring every moment of your evening is filled with magic. Whether you're celebrating a special occasion, surprising your partner with a heartfelt gesture, or simply indulging in an unforgettable experience, our Romantic Dinner at the Beach at aMore Moonlight Restaurant is the epitome of romance and luxury. Immerse yourself in the beauty of nature, exquisite cuisine, and the blissful connection with your loved one. Book your table now at the receptiondesk and let us create an evening that will forever be etched in your hearts, leaving you with memories that will be cherished for a lifetime.",
      "amore-text-2":
        "1 free dinner for guests with a stay of minimum 7 nights - by prior reservation minimum of 1 day before at reception desk",
      "amore-text-3":
        '"Amore moonlight" dinner – candlelight dinner is possible in high season (July, August) and according to the weather forecast.',
      "tortuga-text":
        "A la carte surf & turf restaurant - with prior reservation for an additional fee. Only Admiral Hotel guests enjoy a special discount of 10% off menu prices. Get ready to discover the full Tortuga experience - from the shared celebration to the intimate dining and well-being.                                                                           This season, you'll have the opportunity to embark on a culinary journey like no other, as we've prepared many new additions to our menu, as well as our new breakfast menu. We look forward to seeing you there!",
      "tortuga-text-2": "",
      "tortuga-text-3": "",
      "affair-bbq-text": "Grill bar on the beach",

      "azzurro-working-hours-1": "Brunch 10:30 - 12:00",
      "azzurro-working-hours": "Lunch 12:30 - 14:30",
      "azzurro-working-hours-2": "Dinner 19:30 - 22:30",
      "asian-samba-working-hours": "Dinner 19:00 - 22:00",
      // "asian-samba-working-hours-2": "Bar - until 00:30h",
      "amore-working-hours": "Dinner 20:30 - 22:30h",
      "amore-note": "The restaurant is open in July and August",
      "affair-working-hours": "Lunch 12:30 - 14:30h",
      "tortuga-working-hours": "Working hours: 10:00 - 00:00",

      payment: "Payment",
      pay: "Pay",
      "card-details": "Card details",
      details: "Details",
      "payment-success": "Successfull payment",
      "payment-fail": "Payment failed",
      pricing: "Pricing",
      meters: "m²",

      "booking-summary": "Booking summary",
      bgn: "BGN",
      view: "View",
      showerhead: "Showerhead",
      tv: "TV",
      conditioner: "Air conditioner",
      dryer: "Hair dryer",
      phone: "Phone",
      adults: "Adults",
      wrong: "Something went wrong",
      "send-review-success": "Successfully sent your review!",
      "send-email-success": "Successfully sent your message!",
      "send-email-fail": "There was a problem sending your message!",
      "no-offers": "There are no offers at the moment.",
      "invalid-period": "Please select valid period.",
      reservations: "Reservations",
      cancellation: "Cancel booking",
      "ref-number": "Reference number",
      "ref-not-found": "Invalid reference number",
      "booking-cancelled": "Booking cancelled",
      apply: "Apply",
      "not-found": "There is no voucher found.",
      voucher: "Voucher",
      "voucher-expired": "The voucher is expired",
      "wrong-room-type": "The voucher can't be used for this type of room",
      "voucher-success": "Voucher successfully applied",
      "no-room-found": "No available rooms for the chosen period",
      "enjoy-dream-vacation": "Enjoy the dream vacation.",
      size: "Size",
      "capacity-single": "Capacity",
      "total-price": "Total price",
      continue: "Continue",
      "dates-were-booked": "The dates are already booked",
      "no-rooms": "No available rooms for the desired period.",
      "view-room": "View room",

      "our-rooms": "Our rooms",

      "required-field": "The field is required!",
      "room-number": "№ rooms",
      "year-cap": "(to 11.99y)",
      "rooms-and-suites": "Rooms & Suites",
      "valid-name": "Please enter your name.",
      "valid-email": "Please enter valid email address",
      "valid-text": "Please enter some text.",
      "additional-services": "Additional services",
      "choose-adults": "Please select a number of adults.",
      "room-single": "room",
      "already-paid":
        "The reservation is already paid. Please, contact us for more information.",
      "cannot-cancel":
        "The reservation cannot be cancelled. Please, contact us for more information.",
      "type-reference-number": "Please, provide a reference number",
      "already-cancelled": "The booking is already cancelled.",
      subscribe: "Subscribe now",
      newsletter: "Newsletter",
      "invalid-email": "Invalid email address",
      "subscribe-success": "Successfully subscribed to our newsletter!",
      reserve: "Book now",
      "food-board": "Board Basis Type",
      "bed-and-breakfast": "Bed & Breakfast",
      "half-board": "Half Board",
      "full-board": "Full Board",
      "all-light": "All-inclusive light",
      sqm: "m2",
      "send-inquiry": "Send Inquiry",
      "ultra-all-inclusive": "Ultra All Inclusive",

      "voucher-min-nights":
        "The voucher can be used for bookings of a minimum of {{minNights}} nights",

      gallery_all: "All",
      gallery_hotel: "Hotel",
      gallery_rooms: "Rooms",
      gallery_wiener_cafe: "Viennese Cafe",
      gallery_asian_restaurant: "Asian Restaurant",
      gallery_azzurro: "Azzurro",
      gallerry_piano_bar: "Piano Bar",
      gallerry_pool_bar_and_beach: "Pool Bar & Beach",
      gallerry_fitness_and_spa: "Fitness & Spa",
      authenticating_payment: "Payment authentication in progress",

      cant_accomodate_with_children:
        "The selected rooms do not have capacity for {{adults}} adults and {{children}} children. Please select more rooms!",
      cant_accomodate:
        "The selected rooms do not have capacity for {{adults}} adults. Please select more rooms!",
      ny_min_stay: "Minimum stay of 3 nights",
    },
  },
  de: {
    translation: {
      // Room names
      "double-standard-deluxe": "Doppelzimmer Standard  DeLuxe",
      "double-sea-deluxe": "Doppelzimmer mit Aussicht aufs Meer DeLuxe",
      "double-sea-superior-deluxe": "Doppelzimmer Meer Superior DeLuxe",
      "junior-suit-deluxe": "Junior Suite DeLuxe",
      "apartment-deluxe": "Appartment DeLuxe",
      "double-standard-park": "Doppelzimmer Standard",
      "double-standard-sea": "Doppelzimmer Meer",
      "double-standard-sea-superior": "Doppelzimmer Meer Superior",
      "junior-suit": "Junior Suite",
      "boutique-apartment": "Boutique-Apartment",

      // Room descriptions
      "double-standard-deluxe-description":
        "Die komplett renovierten Zimmer verbinden stilvolles Design mit moderner Technologie, die Zimmer verfügen über ein Doppelbett /king size/ oder zwei Einzelbetten.",
      "double-sea-deluxe-description":
        "Die komplett renovierten Zimmer verbinden stilvolles Design mit moderner Technologie und bieten einen atemberaubenden Meerblick. Sie verfügen über ein Doppelbett /king size/ oder zwei Einzelbetten.",
      "double-sea-superior-deluxe-description":
        "Geräumig und komfortabel, komplett renoviert, mit einer Kombination aus stilvollem Design und moderner Technologie, bieten die Zimmer einen atemberaubenden Meerblick. Sie verfügen über ein Doppelbett /king size/ und ein Ausziehsofa. ",
      "junior-suit-deluxe-description":
        "Die komplett renovierten junior Suite Appartments mit feiner Atmosphäre und modernem Design bestehen aus einem Schlafzimmer und einem geräumigen Wohnzimmer, die zu einem großen Raum mit Panoramablick aufs Meer verschmolzen sind. Sie verfügen über ein Doppelbett /king size/ und ein Ausziehsofa.",
      "apartment-deluxe-description":
        "Die Appartments  wurden komplett renoviert und befinden sich in der obersten neunten Etage des Hotels. Sie verfügen über ein feines Dekor und ein unverwechselbares Design. Sie bestehen aus einem Wohnzimmer und einem Schlafzimmer und bieten einen einzigartigen Panoramablick auf den Strand und die Ortschaft. Sie verfügen über ein Doppelbett /king-size/ und ein Ausziehsofa.",
      "double-standard-park-description":
        "Die geräumigen und hellen Standard-Doppelzimmer vereinen ein komfortables Design und alle Bequemlichkeiten für einen vollwertigen Aufenthalt. Die Zimmer verfügen über zwei Einzelbetten.",
      "double-standard-sea-description":
        "Die elegant eingerichteten Doppelzimmer mit Aussicht aufs Meer bieten modernsten Komfort. Sie zeichnen sich durch ihren einzigartigen Aussicht aufs Meer aus. Die Zimmer verfügen über zwei Einzelbetten.",
      "double-standard-sea-superior-description":
        "Die Superior-Doppelzimmer bieten einen atemberaubenden Meerblick und Panoramablick auf das Zentrum der Ortschaft. Sie verfügen über ein Doppelbett /king size/ und ein Ausziehsofa.",
      "junior-suit-description":
        "Die Appartments  junior Suite bestehen aus einem Schlafzimmer und einem geräumigen Wohnzimmer, die zu einem geräumigen Raum mit Panoramablick auf das Meer verschmolzen sind. Sie verfügen über ein Doppelbett  / king size/ und ein Ausziehsofa.",
      "boutique-apartment-description":
        "Dieses luxuriöse Boutique-Apartment mit herrlichem Meerblick befindet sich im 9. Stock. Entworfen in einem stilvollen Design, mit einem beeindruckenden Dekor, besteht die Boutique aus 2 Schlafzimmern, zwei Badezimmern mit Dusche, einem offenen Wohnbereich mit Küche. drei Balkone mit Meerblick, ein Esstisch und ein riesiges Sofa vor einem 75-Zoll-Fernseher",

      // Tabs
      home: "Startseite",
      about: "Über uns",
      accommodation: "Unterkunft",
      dining: "Lokale",
      events: "Events",
      offers: "Angebote",
      reviews: "Bewertungen",
      gallery: "Bildergalerie",
      contact: "Kontakt",
      services: "Ausstattung",
      sport: "Erholung und Sport",

      // Room info
      room: "Zimmer",
      rooms: "Räume",
      sea: "Meer",
      park: "Park",

      // Home
      welcome: "Willkommen zu",
      "hotel-admiral": "Hotel Admiral",
      "one-sentence-about":
        "Das für das Golden Sands Resort symbolische, direkt am Strand gelegene Hotel Admiral bietet außergewöhnliche Bedingungen für Ihren kompletten Urlaub.",
      "one-sentence-about-2":
        "Im Rahmen unseres herzlichen Willkommens können Sie persönliche Betreuung erwarten und ein aufrichtiges Lächeln, das unser Engagement für außergewöhnliche Gastfreundschaft widerspiegelt. Unser Die Mitarbeiter an der Rezeption sind mit dem Kundenservice bestens vertraut und geben ihr Bestes Meile, um Ihren Komfort und Ihre Zufriedenheit während Ihres gesamten Aufenthalts zu gewährleisten. Ob sie Benötigen Sie Hilfe bei Restaurantreservierungen, Transportarrangements oder Für alle anderen Anfragen steht Ihnen unser Rezeptionsteam rund um die Uhr zur Verfügung. Wir schauen Ich freue mich auf die Begrüßung Sie im Hotel Admiral zu begrüßen und Ihnen ein wunderbares Erlebnis zu bieten, das Ihre Erwartungen übertrifft. Unsere Gäste sind bei uns jederzeit herzlich willkommen",
      // Services
      wifi: "Wi-Fi",
      minibar: "minibar",
      ergonomic: "Ergonomische Matratzen",
      balcon: "Balkon",
      telephone: "Telefon",
      "TV-75": "Interaktives Fernsehen/ Bildschirm 75 inch /",
      "TV-55": "Interaktives Fernsehen/ Bildschirm 55 inch /",
      "TV-32": "Interaktives Fernsehen/ Bildschirm 32 inch /",
      vault: "Tresor",
      nespresso: `Kaffeemaschine Nespresso`,
      cosmetics: "Kosmetik Aqua dellËlba",
      AC: "Klimaanlage",
      armchair: "Ausziehsofa",
      couch: "Haartrockner",
      "touch-light": "Smart Beleuchtung",
      "coffee-machine": "Kaffeemaschine",
      showerhead: "Duschkabine / Badewanne",
      spa: "AquaBliss Spa",
      breakfast: "Frühstück",

      // About
      "about-text-one":
        "Mit seiner atemberaubenden und unvergleichlichen Lage im Zentrum des Golden Sands-Komplexes und direkt am Strand bietet das Admiral Hotel, das für den Sommer 2023 komplett renoviert wird, seinen Gästen in jeder Hinsicht ein hohes Maß an Qualität.",
      "about-text-two":
        "Die 300 Doppelzimmer, Familienzimmer (zwei durch eine Zwischentür verbundene Zimmer) und Suiten sind elegant eingerichtet und bieten eine außergewöhnliche Atmosphäre für einen unvergesslichen und perfekten Urlaub.",
      "about-text-three":
        "Mit einem Willkommensdrink beginnt das Luxury Ultra All Inclusive. ",
      "own-beach": "Strand",
      "outdoor-pool": "Außenpool",
      parking: "Parken",
      "pool-table": "Billardtisch",
      "lobby-bar": "Lobby-Bar",
      "grill-service": "Grill",
      "piano-bar-service": "Pianobar",
      "rent-bicycle-service": "Mieten Sie ein Fahrrad",
      "amore-service": "aMore Moonlight",
      confectionery: "Wiener Patisserie",
      "confectionery-working-hours": "Öffnungszeit – 09:00 – 23:00",

      "bar-mezzanine": "Lounge Piano bar",
      fitness: "Fitnessraum",
      "currency-exchange": "Wechselstube",
      restaurant: "Admiral",
      restaurants: "Restaurants",
      conference: "Konferenzräume",
      laundry: "Wäscheservice",

      // Sport
      "admiral-beach": "Strand Admiral",
      "admiral-beach-text":
        "Das Hotel Admiral verfügt über einen eigenen Strand, nur einige Meter vom Hotel entfernt, den seine Gäste genießen können. Der breite Strandstreifen ist geradlinig, gedeckt mit goldenem feinem Sand. Der sandige Meeresboden geht fließend ins Meer über, es fehlen Felsen und scharfe Meeresbodenänderungen. Das reine und stille Meereswasser mit einem niedrigen Salzinhalt, niedriger als im Mittelmeer zum Beispiel, und das Fehlen von gefährlichen für die Menschen Fischen und Tieren, sind passend für Kinder und Unterwasserschwimmen. Das Hotel Admiral verfügt über einen eigenen Strand, nur einige Meter vom Hotel entfernt, den seine Gäste genießen können.",
      "admiral-beach-working-hours": "Работно време: 08:00 - 17:30",
      "outdoor-swimming-pool": "Außenpool",
      "outdoor-swimming-pool-text":
        "Der große Außenpool vor dem Hotel, liegend direkt am Strand, ist einer der Lieblingsorte für Erholung der Hotelgäste. Ausgestattet mit mehreren Sonnenschirmen und bequemen Liegestühlen, bietet der Pool den Gästen die Möglichkeit an, die Meeresbrise und den Meeresgeräusch, nur Metern vom Hotel entfernt, zu genießen. ",
      "outdoor-swimming-pool-working-hours": "Öffnungszeit: 08:00 - 17:00",
      gym: "Fitnessraum",
      "gym-text":
        "Seinen Gästen, die Sportliebhaber sind, bietet das Hotel Admiral Zugang zu einem modern ausgestatteten Fitnessraum, liegend in demselben Hotel.",
      "gym-working-hours": "Öffnungszeit: 08:00 - 20:00",
      "indoor-swimming-pool": "Innenpool mit Mineralwasser",
      "indoor-swimming-pool-text":
        "Für den angenehmen und erholsamen Aufenthalt seiner Gäste verfügt das Hotel Admiral über einen Innenpool mit Mineralwasser, der an Regentagen zugänglich ist und sich auf einer separaten Etage im Gebäude befindet.",
      "indoor-swimming-pool-working-hours": "Öffnungszeit: 08:00 - 20:00",
      "spa-text":
        'Wir stellen Ihnen unser brandneues "AquaBliss" Admiral Spa im Hotel Admiral, Golden Sands vor! Entdecken Sie eine Oase der Ruhe und Erholung in unserem hochmodernen Spa-Bereich. Mit einer Reihe luxuriöser Annehmlichkeiten und außergewöhnlichem Service laden wir Sie ein, sich dem ultimativen Entspannungserlebnis hinzugeben. Unser freundliches Rezeptionspersonal informiert die Gäste über Zusatzleistungen, wie z. B. eine Auswahl an verschiedenen Massagen, 3 Saunatypen – Hochtemperatur-, Niedertemperatur- und Infrarotsauna und ein Dampfbad.',
      "bicycle-rent": "Mieten Sie ein Fahrrad",
      "bicycle-rent-text":
        "Leihen Sie sich ein Fahrrad, um einen aktiven Tag oder ein Wochenende zu verbringen. Radfahren ist eine unterhaltsame, effiziente und einfache Art, sich in Golden Sands fortzubewegen - so wie es die Einheimischen tun! Selbst wenn Sie nur für ein paar Stunden in die Pedale treten, ist das Radfahren ein wesentliches Erlebnis in Golden Sands. Die beste Art, sich im Ferienort fortzubewegen, ist mit dem Fahrrad. Wenn Sie sich für Sightseeing mit dem Fahrrad interessieren, haben wir einige tolle Vorschläge für Radtouren. Ein Fahrrad in Golden Sands zu mieten ist ziemlich einfach. Wir vermieten Fahrräder für eine Stunde oder einen Tag, wobei die gängigste Mietdauer bis zu 3 Stunden beträgt. Wenn Sie ein Fahrrad mieten, müssen Sie eine Kaution hinterlegen.",
      "bicycle-rent-working-hours": "Öffnungszeit – 24/7",

      // Events
      parties: "Hochzeiten und Firmenfeiern",
      "parties-text-1":
        "Das Hotel Admiral verfügt über einen multifunktionalen Raum, geeignet für Festbälle, Hochzeiten und Firmenfeiern.",
      "parties-text-2":
        "Das Hotelteam wird mit Ideen und Professionalismus für die perfekte Organisation und die Durchführung der Veranstaltung entgegenkommen.",
      conferences: "Konferenze und business meetings",
      "conferences-text-1":
        "Das Hotel ist ganzjährlich geöffnet und bietet ausgezeichnete Bedingungen für die Organisation von verschiedener Art Veranstaltungen: Konferenzen, Seminaren, geschäftlichen Forums, Präsentationen, Ausstellungen, Mitrbeiterschulungen und Feiern.",
      "conferences-text-2":
        "Der Seminarraum verfügt über eine maximale Kapazität von 80 Sitzplätzen /bei einer theatralischen Konfiguration/ und eine Fläche von 100 qm. Geeignet für Seminare, Presskonferenzen und geschäftliche Meetings.",
      "conferences-text-3":
        "Der multifunktionale Raum verfügt über eine maximale Kapazität von 550 Sitzplätzen und eine  Fläche von 520 qm.",
      "conferences-text-4":
        "Geeignet für Konferenzen, Präsentationen, Cocktailpartys und Galaempfänge.",

      "check-availability": "Die Verfügbarkeit prüfen",
      "contact-us": "Kontakt",
      "useful-links": "Nützliche Links",
      "email-address": "E-Mail addresse",
      name: "Name",
      "last-name": "Nachname",
      subject: "Thema",
      message: "Botschaft",
      "send-message": "Nachricht senden",
      email: "E-Mail",
      address: "Addresse",
      fax: "Fax",
      "add-review": "Bewertung hinzufügen",
      send: "Senden",
      "general-terms": "Bedingungen und Konditionen",
      privacy: "Datenschutz",
      terms: "Nutzungsbedingungen",
      seminars: "Seminare",
      "check-in": "Einchecken",
      "check-out": "Auschecken",
      children: "Kinder",
      child: "Kind",
      guests: "Gäste",
      book: "Buchen",
      beds: "Betten",
      "room-size": "Zimmergröße",
      capacity: "Raumkapazität",
      price: "Preis",
      night: "Nacht",
      all: "Alle",
      hotel: "Hotel",
      pool: "Pool",
      bar: "Bar",
      beach: "Strand",
      "room-details": "Zimmerdetails",
      azzurro: "Azzurro",
      "asian-samba": "Asiatisches Restaurant",
      amore: "aMore Moonlight",
      tortuga: "Tortuga Beach Club",
      "affair-bbq": "Аffair BBQ strand",

      "more-about-us": "Mehr über uns",
      "hotel-address": "9007 Golden Strand, Varna",
      "all-rights-reserved": "Hotel Admiral. Alle Rechte vorbehalten",
      "see-more": "Mehr sehen",
      "clients-say": "Bewertung unserer Gäste",

      // Restaurandouble-standart-superior-junior.jpgts page
      "restaurant-text":
        "Unser Hauptrestaurant Admiral wurde komplett renoviert und bietet eine große Auswahl an Speisen und Getränken. Genießen Sie köstlich zubereitete Meeresfrüchte, Fleischspezialitäten, frische Salate und verlockende Desserts, die auf einem eleganten Buffet serviert werden. Mit garantierter Servicequalität und kulinarischen Köstlichkeiten laden wir Sie ein, sich kulinarisch verwöhnen zu lassen!",
      "restaurant-text-2":
        "Einmal pro Woche können Sie unser bulgarisches und italienisches Themenabendessen genießen.",
      "restaurant-working-hours": "Öffnungszeit:",
      "restaurant-working-hours-1": "Frühstücksbuffet – 07:30 – 10:00",
      "restaurant-working-hours-2": "Mittagsbuffet – 12:30 – 14:30",
      "restaurant-working-hours-3": "Abendbuffet – 18:00 – 21:00",
      "lobby-bar-text":
        "Im Herzen des Hotels gelegen, bietet unsere Lobby-Bar eine große Auswahl an Kaffeespezialitäten, erfrischenden Cocktails und erstklassigen Getränken, ideal für eine kühle Entspannung nach einem langen Tag oder um Freunde zu treffen.",
      "lobby-bar-working-hours": "Öffnungszeit – 24/7",
      "confectionery-text":
        "Die Wiener Patisserie des Hotels Admiral, die für die Saison 2024 komplett renoviert wurde, verbindet Komfort und Raffinesse und bietet eine raffinierte Speisekarte mit zahlreichen süßen Köstlichkeiten aus eigener Produktion. Kommen Sie und genießen Sie die süßen Dinge des Lebens!",
      "bar-mezzanine-text": "Melodies of Elegance 🔝🎹🎶",
      "bar-mezzanine-text-2":
        "Unsere Lounge Piano Bar im Admiral Hotel ist mehr als nur ein Ort - sie ist eine Einladung, dem Alltag zu entfliehen und in eine Welt exquisiter Genüsse einzutauchen. Es ist eine Gelegenheit, kostbare Erinnerungen zu schaffen, wo die harmonische Mischung aus Live-Musik, raffinierter Atmosphäre und tadellosem Service zu einem Abend führt, der Ihnen noch lange in Erinnerung bleiben wird. Begeben Sie sich auf diese außergewöhnliche Reise und lassen Sie uns Ihre Vorstellung von Luxus neu definieren.",
      "bar-mezzanine-text-3": "Jeden Abend Live-Musik",
      "bar-mezzanine-working-hours": "Öffnungszeit – 20:30 – 22:30",
      "swim-up": "Swim-up-Poolbar",
      "swim-up-text":
        "Die Swim-up Pool Bar des Admiral Hotels ist der perfekte Ort für eine erfrischende Pause und unvergessliche Sommermomente. Genießen Sie das einzigartige Erlebnis, Cocktails und erfrischende Getränke zu schlürfen, ohne den Pool zu verlassen. Unsere Bar bietet eine Auswahl an exotischen Cocktails, frischen Säften und Snacks, die Ihren Geschmack treffen, während Sie die Sonne und das Wasser genießen. In einer idealen Kombination aus Entspannung und Spaß schafft die Swim-up Pool Bar die perfekte Atmosphäre für Erholung und Unterhaltung. Kommen Sie und genießen Sie den Sommer mit Stil an der Swim-up Pool Bar im Admiral Hotel!",
      "swim-up-working-hours": "Öffnungszeit - 09:00 – 18:00",
      "azzurro-text":
        "Willkommen in unserem mediterranen Restaurant Azzurro, das direkt am Strand vor dem Admiral Hotel am Ufer des Golden Sands liegt. Während Sie das kulinarische Angebot genießen, können Sie Ihre Mahlzeit mit einer Auswahl aus unserer sorgfältig zusammengestellten Weinkarte ergänzen. Unser engagiertes Personal ist bestrebt, Ihnen einen außergewöhnlichen Service zu bieten, um sicherzustellen, dass jeder Moment Ihres Besuchs ein Vergnügen ist. Ganz gleich, ob Sie einen besonderen Anlass feiern, einen romantischen Abend verbringen oder einfach nur ein unvergessliches Essen genießen möchten, unser aufmerksames Personal wird dafür sorgen, dass Ihr Besuch unvergesslich bleibt. Kommen Sie und genießen Sie die wahre Essenz des Mittelmeers, wo sich köstliche Küche und der Charme des Strandes zu einem außergewöhnlichen kulinarischen Erlebnis verbinden.",
      "azzurro-text-2":
        "1 kostenloses Abendessen für Gäste mit einem Mindestaufenthalt von 7 Nächten - mit einer Reservierung von mindestens 1 Tag im Voraus an der Rezeption",
      "asian-samba-text":
        "Gönnen Sie sich eine kulinarische Reise wie keine andere in unserem asiatischen Restaurant. Tauchen Sie ein in eine Welt voller exquisiter Aromen und fesselnder Düfte, die Sie in das Herz Asiens entführt. Unsere erfahrenen Köche haben sorgfältig eine Speisekarte zusammengestellt, die die reiche Vielfalt der asiatischen Küche zeigt. Unser aufmerksames Personal ist bestrebt, Ihnen ein unvergessliches Erlebnis zu bieten, und sorgt dafür, dass alle Ihre Wünsche erfüllt werden. Besuchen Sie unser asiatisches Restaurant im Admiral Hotel und lassen Sie sich auf ein kulinarisches Abenteuer ein, das Lust auf mehr macht. Wir heißen Sie herzlich willkommen, die Aromen Asiens zu entdecken und mit uns unvergessliche Erinnerungen zu schaffen.",
      "asian-samba-text-2":
        "Für Gäste mit einem Mindestaufenthalt von 7 Nächten, 1 kostenloses Abendessen - mit einer Reservierung von mindestens 1 Tag im Voraus an der Rezeption",
      "amore-text":
        "Wir stellen Ihnen ein atemberaubendes Erlebnis vor, das Sie umhauen und in eine Welt voller Zauber entführen wird – unser exklusives romantisches Abendessen am Strand, veranstaltet im aMore Moonlight Restaurant, direkt am Strand vor dem Admiral Hotel. Wenn Sie sich an Ihrem gemütlichen Tisch am Strand niederlassen, der mit zartem Kerzenlicht und exquisiten Blumenarrangements geschmückt ist, werden Sie von unserem herzlichen und aufmerksamen Personal begrüßt, das dafür sorgt, dass jeder Moment Ihres Abends voller Magie ist. Egal, ob Sie einen besonderen Anlass feiern, Ihren Partner mit einer herzlichen Geste überraschen oder sich einfach ein unvergessliches Erlebnis gönnen, unser romantisches Abendessen am Strand im aMore Moonlight Restaurant ist der Inbegriff von Romantik und Luxus. Tauchen Sie ein in die Schönheit der Natur, die exquisite Küche und die glückselige Verbindung mit Ihrem Liebsten. Reservieren Sie jetzt Ihren Tisch an der Rezeption und lassen Sie uns einen Abend gestalten, der für immer in Ihrem Herzen verankert sein wird und Ihnen Erinnerungen hinterlassen wird, die Sie ein Leben lang schätzen werden.",
      "amore-text-2":
        "1 kostenloses Abendessen für Gäste mit einem Aufenthalt von mindestens 7 Nächten - nach vorheriger Reservierung mindestens 1 Tag vorher an der Rezeption.",
      "amore-text-3":
        '"Amore moonlight" - Abendessen bei Kerzenlicht ist in der Hochsaison (Juli, August) und je nach Wettervorhersage möglich.',
      "tortuga-text":
        "la carte surf & turf restaurant - with prior reservation for an additional fee. Only Admiral Hotel guests enjoy a special discount of 10% off menu prices. Get ready to discover the full Tortuga experience - from the shared celebration to the intimate dining and well-being.                                                                           This season, you'll have the opportunity to embark on a culinary journey like no other, as we've prepared many new additions to our menu, as well as our new breakfast menu. We look forward to seeing you there!",
      "tortuga-text-2": "",
      "tortuga-text-3": "",
      "affair-bbq-text": "Grillen am Strand",

      "azzurro-working-hours-1": "Brunch 10:30 - 12:00",
      "azzurro-working-hours": "Mittagessen 12:30 - 14:30",
      "azzurro-working-hours-2": "Abendessen 19:30 - 22:30",
      "asian-samba-working-hours": "Abendessen 19:00 - 22:00",
      // "asian-samba-working-hours-2": "Bar bis 00:30",
      "amore-working-hours": "Abendessen 20:30 - 22:30",
      "amore-note": "Das Restaurant ist im Juli und August geöffnet",
      "affair-working-hours": "Mittagessen 12:30 - 14:30",
      "tortuga-working-hours": "Öffnungszeit 10:00 - 00:00",

      payment: "Zahlung",
      pay: "Zahlen",
      "card-details": "Kartendetails",
      details: "Einzelheiten",
      "payment-success": "Erfolgreiche Zahlung",
      "payment-fail": "Zahlung fehlgeschlagen",
      pricing: "Preise",
      meters: "m²",
      "booking-summary": "Zusammenfassung",
      bgn: "BGN",
      view: "Aussicht",
      tv: "Fernseher",
      conditioner: "Klimaanlage",
      dryer: "Haartrockner",
      phone: "Telefon",
      adults: "Erwachsene",
      wrong: "Etwas ist schief gelaufen",
      "send-review-success": "Sie haben Ihre Bewertung erfolgreich gesendet!",
      "send-email-success": "Sie haben Ihre Nachricht erfolgreich gesendet!",
      "send-email-fail": "Es gab ein Problem beim Senden Ihrer Nachricht!",
      "no-offers": "Keine Angebote gefunden.",
      "invalid-period": "Bitte wählen Sie einen gültigen Zeitraum.",
      reservations: "Reservierungen",
      cancellation: "Reservierung stornieren",
      "ref-number": "Referenznummer",
      "ref-not-found": "Ungültige Referenznummer",
      "booking-cancelled": "Buchung storniert",
      apply: "Anwenden",
      "not-found": "Kein Gutschein gefunden.",
      "voucher-expired": "Der Gutschein ist abgelaufen.",
      "wrong-room-type":
        "Gutschein kann für diesen Zimmertyp nicht verwendet werden.",
      voucher: "Gutschein",
      "voucher-success": "Der Gutschein wurde erfolgreich angewendet.",
      "no-room-found": "Keine Zimmer für den gewählten Zeitraum verfügbar",
      "enjoy-dream-vacation": "Genießen Sie Ihren Traumurlaub!",
      size: "Bereich",
      "capacity-single": "Bereich",
      "total-price": "Gesamtkosten",
      continue: "Mach weiter",
      "dates-were-booked": "Die ausgewählten Termine sind vergeben.",
      "no-rooms": "Keine Zimmer für den gewählten Zeitraum verfügbar.",
      "view-room": "Zimmer ansehen",

      "our-rooms": "Unsere Zimmer",

      "required-field": "Das Feld ist obligatorisch!",
      "room-number": "Anzahl der Räume",
      "year-cap": "(bis 11.99J.)",
      "rooms-and-suites": `Zimmer & Appartements`,

      "valid-name": "Bitte geben Sie Ihren Namen ein.",
      "valid-email": "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
      "valid-text": "Bitte Text eingeben",
      "additional-services": "Zusätzliche Dienstleistungen",
      "choose-adults": "Personenanzahl (Erwachsene)",

      "room-single": "Zimmer",
      "already-paid":
        "Die Buchung ist bezahlt. Kontaktieren Sie uns gerne, wenn Sie Unterstützung benötigen.",
      "cannot-cancel":
        "The reservation cannot be cancelled. Please, contact us for more information.",
      "type-reference-number": "Geben Sie bitte eine Buchungsnummer ein.",
      "already-cancelled": "Diese Buchung wurde bereits storniert.",
      subscribe: "Hier abonnieren",
      newsletter: "Abonnenten",
      "invalid-email": "Ungültige Mailadresse",
      subscribers: "Абонати",
      "subscribe-success":
        "Sie haben sich erfolgreich für unseren Newsletter abonniert.",
      reserve: "Buchen",
      "food-board": "Verpflegung",
      "bed-and-breakfast": "Frühstück",
      "half-board": "Halbpension",
      "full-board": "Vollpension",
      "all-light": "Alles-inklusive light",
      sqm: "m2",
      "send-inquiry": "Anfrage senden",
      "ultra-all-inclusive": "Ultra All Inclusive",
      "voucher-min-nights":
        "Der Gutschein kann für Buchungen von mindestens {{minNights}} Nächten verwendet werden",

      gallery_all: "Alle",
      gallery_hotel: "Hotel",
      gallery_rooms: "Räume",
      gallery_wiener_cafe: "Wiener Café",
      gallery_asian_restaurant: "Asiatisches Restaurant",
      gallery_azzurro: "Azzurro",
      gallerry_piano_bar: "Piano Bar",
      gallerry_pool_bar_and_beach: "Pool Bar & Strand",
      gallerry_fitness_and_spa: "Fitness & Spa",
      authenticating_payment: "Zahlungsauthentifizierung läuft",
      cant_accomodate_with_children:
        "Die ausgewählten Zimmer haben nicht die Kapazität für {{adults}} Erwachsene und {{children}} Kinder. Bitte wählen Sie mehr Zimmer!",
      cant_accomodate:
        "Die ausgewählten Zimmer haben nicht die Kapazität für {{adults}} Erwachsene. Bitte wählen Sie mehr Zimmer!",
      ny_min_stay: "Mindestaufenthalt 3 Nächte",
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
