import { Link } from "react-router-dom";
import React from "react";
import PageBanner from "../components/PageBanner";
import Layouts from "../layouts/Layouts";

const RoomGrid = () => {
  return (
    <Layouts>
      <PageBanner pageName={"Room Grid"} />
      <section className="section-twenty-five">
        <div className="auto-container">
          <div className="row">
            <div className="col-lg-4">
              <div className="block-twenty-five">
                <div className="image">
                  <img
                    src="../public/assets/images/resource/image-30.jpg"
                    alt=""
                  />
                </div>
                <div className="lower-content">
                  <div className="pricing">150/Night</div>
                  <div className="icon-list">
                    <ul>
                      <li>
                        <i className="flaticon-user" />
                        <h4>Capacity</h4>
                        <div className="text">4 Person</div>
                      </li>
                      <li>
                        <i className="flaticon-preview" />
                        <h4>Size</h4>
                        <div className="text">600sqm</div>
                      </li>
                      <li>
                        <i className="flaticon-view" />
                        <h4>View</h4>
                        <div className="text">City, Ocean</div>
                      </li>
                    </ul>
                  </div>
                  <h3>Delux Queen Luxury Room</h3>
                  <Link to="/room-details">
                    <a href="/home" className="theme-btn btn-style-four">
                      <span>View Room</span>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="block-twenty-five">
                <div className="image">
                  <img
                    src="../public/assets/images/resource/image-31.jpg"
                    alt=""
                  />
                </div>
                <div className="lower-content">
                  <div className="pricing">150/Night</div>
                  <div className="icon-list">
                    <ul>
                      <li>
                        <i className="flaticon-user" />
                        <h4>Capacity</h4>
                        <div className="text">4 Person</div>
                      </li>
                      <li>
                        <i className="flaticon-preview" />
                        <h4>Size</h4>
                        <div className="text">600sqm</div>
                      </li>
                      <li>
                        <i className="flaticon-view" />
                        <h4>View</h4>
                        <div className="text">City, Ocean</div>
                      </li>
                    </ul>
                  </div>
                  <h3>Delux Queen Luxury Room</h3>
                  <Link to="/room-details">
                    <a href="/home" className="theme-btn btn-style-four">
                      <span>View Room</span>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="block-twenty-five">
                <div className="image">
                  <img
                    src="../public/assets/images/resource/image-32.jpg"
                    alt=""
                  />
                </div>
                <div className="lower-content">
                  <div className="pricing">150/Night</div>
                  <div className="icon-list">
                    <ul>
                      <li>
                        <i className="flaticon-user" />
                        <h4>Capacity</h4>
                        <div className="text">4 Person</div>
                      </li>
                      <li>
                        <i className="flaticon-preview" />
                        <h4>Size</h4>
                        <div className="text">600sqm</div>
                      </li>
                      <li>
                        <i className="flaticon-view" />
                        <h4>View</h4>
                        <div className="text">City, Ocean</div>
                      </li>
                    </ul>
                  </div>
                  <h3>Delux Queen Luxury Room</h3>
                  <Link to="/room-details">
                    <a href="/home" className="theme-btn btn-style-four">
                      <span>View Room</span>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="block-twenty-five">
                <div className="image">
                  <img
                    src="../public/assets/images/resource/image-71.jpg"
                    alt=""
                  />
                </div>
                <div className="lower-content">
                  <div className="pricing">150/Night</div>
                  <div className="icon-list">
                    <ul>
                      <li>
                        <i className="flaticon-user" />
                        <h4>Capacity</h4>
                        <div className="text">4 Person</div>
                      </li>
                      <li>
                        <i className="flaticon-preview" />
                        <h4>Size</h4>
                        <div className="text">600sqm</div>
                      </li>
                      <li>
                        <i className="flaticon-view" />
                        <h4>View</h4>
                        <div className="text">City, Ocean</div>
                      </li>
                    </ul>
                  </div>
                  <h3>Delux Queen Luxury Room</h3>
                  <Link to="/room-details">
                    <a href="/home" className="theme-btn btn-style-four">
                      <span>View Room</span>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="block-twenty-five">
                <div className="image">
                  <img
                    src="../public/assets/images/resource/image-72.jpg"
                    alt=""
                  />
                </div>
                <div className="lower-content">
                  <div className="pricing">150/Night</div>
                  <div className="icon-list">
                    <ul>
                      <li>
                        <i className="flaticon-user" />
                        <h4>Capacity</h4>
                        <div className="text">4 Person</div>
                      </li>
                      <li>
                        <i className="flaticon-preview" />
                        <h4>Size</h4>
                        <div className="text">600sqm</div>
                      </li>
                      <li>
                        <i className="flaticon-view" />
                        <h4>View</h4>
                        <div className="text">City, Ocean</div>
                      </li>
                    </ul>
                  </div>
                  <h3>Delux Queen Luxury Room</h3>
                  <Link to="/room-details">
                    <a href="/home" className="theme-btn btn-style-four">
                      <span>View Room</span>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="block-twenty-five">
                <div className="image">
                  <img
                    src="../public/assets/images/resource/image-73.jpg"
                    alt=""
                  />
                </div>
                <div className="lower-content">
                  <div className="pricing">150/Night</div>
                  <div className="icon-list">
                    <ul>
                      <li>
                        <i className="flaticon-user" />
                        <h4>Capacity</h4>
                        <div className="text">4 Person</div>
                      </li>
                      <li>
                        <i className="flaticon-preview" />
                        <h4>Size</h4>
                        <div className="text">600sqm</div>
                      </li>
                      <li>
                        <i className="flaticon-view" />
                        <h4>View</h4>
                        <div className="text">City, Ocean</div>
                      </li>
                    </ul>
                  </div>
                  <h3>Delux Queen Luxury Room</h3>
                  <Link to="/room-details">
                    <a href="/home" className="theme-btn btn-style-four">
                      <span>View Room</span>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="block-twenty-five">
                <div className="image">
                  <img
                    src="../public/assets/images/resource/room-grid.jpg"
                    alt=""
                  />
                </div>
                <div className="lower-content">
                  <div className="pricing">150/Night</div>
                  <div className="icon-list">
                    <ul>
                      <li>
                        <i className="flaticon-user" />
                        <h4>Capacity</h4>
                        <div className="text">4 Person</div>
                      </li>
                      <li>
                        <i className="flaticon-preview" />
                        <h4>Size</h4>
                        <div className="text">600sqm</div>
                      </li>
                      <li>
                        <i className="flaticon-view" />
                        <h4>View</h4>
                        <div className="text">City, Ocean</div>
                      </li>
                    </ul>
                  </div>
                  <h3>Delux Queen Luxury Room</h3>
                  <Link to="/room-details">
                    <a href="/home" className="theme-btn btn-style-four">
                      <span>View Room</span>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="block-twenty-five">
                <div className="image">
                  <img
                    src="../public/assets/images/resource/image-75.jpg"
                    alt=""
                  />
                </div>
                <div className="lower-content">
                  <div className="pricing">150/Night</div>
                  <div className="icon-list">
                    <ul>
                      <li>
                        <i className="flaticon-user" />
                        <h4>Capacity</h4>
                        <div className="text">4 Person</div>
                      </li>
                      <li>
                        <i className="flaticon-preview" />
                        <h4>Size</h4>
                        <div className="text">600sqm</div>
                      </li>
                      <li>
                        <i className="flaticon-view" />
                        <h4>View</h4>
                        <div className="text">City, Ocean</div>
                      </li>
                    </ul>
                  </div>
                  <h3>Delux Queen Luxury Room</h3>
                  <Link to="/room-details">
                    <a href="/home" className="theme-btn btn-style-four">
                      <span>View Room</span>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="block-twenty-five">
                <div className="image">
                  <img
                    src="../public/assets/images/resource/image-76.jpg"
                    alt=""
                  />
                </div>
                <div className="lower-content">
                  <div className="pricing">150/Night</div>
                  <div className="icon-list">
                    <ul>
                      <li>
                        <i className="flaticon-user" />
                        <h4>Capacity</h4>
                        <div className="text">4 Person</div>
                      </li>
                      <li>
                        <i className="flaticon-preview" />
                        <h4>Size</h4>
                        <div className="text">600sqm</div>
                      </li>
                      <li>
                        <i className="flaticon-view" />
                        <h4>View</h4>
                        <div className="text">City, Ocean</div>
                      </li>
                    </ul>
                  </div>
                  <h3>Delux Queen Luxury Room</h3>
                  <Link to="/room-details">
                    <a href="/home" className="theme-btn btn-style-four">
                      <span>View Room</span>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="block-twenty-five">
                <div className="image">
                  <img
                    src="../public/assets/images/resource/image-77.jpg"
                    alt=""
                  />
                </div>
                <div className="lower-content">
                  <div className="pricing">150/Night</div>
                  <div className="icon-list">
                    <ul>
                      <li>
                        <i className="flaticon-user" />
                        <h4>Capacity</h4>
                        <div className="text">4 Person</div>
                      </li>
                      <li>
                        <i className="flaticon-preview" />
                        <h4>Size</h4>
                        <div className="text">600sqm</div>
                      </li>
                      <li>
                        <i className="flaticon-view" />
                        <h4>View</h4>
                        <div className="text">City, Ocean</div>
                      </li>
                    </ul>
                  </div>
                  <h3>Delux Queen Luxury Room</h3>
                  <Link to="/room-details">
                    <a href="/home" className="theme-btn btn-style-four">
                      <span>View Room</span>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="block-twenty-five">
                <div className="image">
                  <img
                    src="../public/assets/images/resource/image-78.jpg"
                    alt=""
                  />
                </div>
                <div className="lower-content">
                  <div className="pricing">150/Night</div>
                  <div className="icon-list">
                    <ul>
                      <li>
                        <i className="flaticon-user" />
                        <h4>Capacity</h4>
                        <div className="text">4 Person</div>
                      </li>
                      <li>
                        <i className="flaticon-preview" />
                        <h4>Size</h4>
                        <div className="text">600sqm</div>
                      </li>
                      <li>
                        <i className="flaticon-view" />
                        <h4>View</h4>
                        <div className="text">City, Ocean</div>
                      </li>
                    </ul>
                  </div>
                  <h3>Delux Queen Luxury Room</h3>
                  <Link to="/room-details">
                    <a href="/home" className="theme-btn btn-style-four">
                      <span>View Room</span>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="block-twenty-five">
                <div className="image">
                  <img
                    src="../public/assets/images/resource/image-79.jpg"
                    alt=""
                  />
                </div>
                <div className="lower-content">
                  <div className="pricing">150/Night</div>
                  <div className="icon-list">
                    <ul>
                      <li>
                        <i className="flaticon-user" />
                        <h4>Capacity</h4>
                        <div className="text">4 Person</div>
                      </li>
                      <li>
                        <i className="flaticon-preview" />
                        <h4>Size</h4>
                        <div className="text">600sqm</div>
                      </li>
                      <li>
                        <i className="flaticon-view" />
                        <h4>View</h4>
                        <div className="text">City, Ocean</div>
                      </li>
                    </ul>
                  </div>
                  <h3>Delux Queen Luxury Room</h3>
                  <Link to="/room-details">
                    <a href="/home" className="theme-btn btn-style-four">
                      <span>View Room</span>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layouts>
  );
};
export default RoomGrid;
