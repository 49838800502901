import React, { Fragment, useState } from "react";
import Search from "../Search";
import Sidebar from "../Sidebar";
import MobileHeader from "./MobileHeader";
import { useTranslation } from "react-i18next";

import iconBar from "../../assets/images/icons/icon-bar.png";
import logoBig from "../../assets/images/logo-no-background-big.png";
import i18n from "../../i18n";
import { Link } from "react-router-dom";

const Header = ({ headernumber }) => {
  const { t } = useTranslation();

  const [sidebarTrigger, setSidebarTrigger] = useState(false);
  const [searchBarTrigger, setSearchBarTrigger] = useState(false);
  const [mobileHeader, setMobileHeader] = useState(false);
  const activeTab = window.location.href.split("/")[3];

  function mobileHeaderTrigger() {
    setMobileHeader(!mobileHeader);
  }

  const tabs = [
    "home",
    "about",
    "rooms",
    "dining",
    "sport",
    "events",
    "offers",
    "reviews",
    "gallery",
    "contact",
  ];
  const clickHandler = () => {
    window.scrollTo(10, 0);
  };
  return (
    <Fragment>
      <header
        className={`main-header header-style-one ${
          headernumber ? "" : "style-two"
        } ${mobileHeader ? "mobile-menu-visible" : ""}`}
      >
        <div className="auto-container">
          <div className="text-center">
            {/*Logo*/}
            <div className="logo-box main-logo">
              <div className="logo">
                <Link to="/home">
                  <img src={logoBig} alt="" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* Header Upper */}
        <div className="header-upper">
          <div className="auto-container">
            <div className="inner-container">
              {/*Nav Box*/}
              <div className="nav-outer">
                {/*Mobile Navigation Toggler*/}
                <div
                  className="mobile-nav-toggler"
                  onClick={mobileHeaderTrigger}
                >
                  <img src={iconBar} alt="" />
                </div>
                {/* Main Menu */}
                <nav className="main-menu navbar-expand-md navbar-light">
                  <div className="collapse navbar-collapse show clearfix">
                    <ul className="navigation">
                      {tabs.map((tab, idx) => {
                        return (
                          <li key={idx}>
                            <Link
                              onClick={clickHandler}
                              to={tab}
                              className={`${
                                activeTab === tab && "navigation-active-tab"
                              }`}
                            >
                              {t(tab === "rooms" ? "accommodation" : tab)}
                            </Link>
                          </li>
                        );
                      })}

                      <TranslationDropdown />
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
        {/*End Header Upper*/}
        {/* Sticky Header  */}
        <div className="sticky-header">
          <div className="header-upper">
            <div className="auto-container">
              <div className="inner-container">
                {/*Nav Box*/}
                <div className="nav-outer">
                  {/*Mobile Navigation Toggler*/}
                  <div className="mobile-nav-toggler">
                    <img src={iconBar} alt="" />
                  </div>
                  {/* Main Menu */}
                  <nav className="main-menu navbar-expand-md navbar-light">
                    <div className="collapse navbar-collapse show clearfix">
                      <ul className="navigation">
                        {tabs.map((tab, idx) => {
                          return (
                            <li key={idx}>
                              <Link onClick={clickHandler} to={tab}>
                                {t(tab === "rooms" ? "accommodation" : tab)}
                              </Link>
                            </li>
                          );
                        })}
                        <TranslationDropdown />
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Sticky Menu */}
        {/* Mobile Menu  */}
        <MobileHeader
          tabs={tabs}
          closeMobileHeader={() => mobileHeaderTrigger()}
          activeTab={activeTab}
        />
        {/* End Mobile Menu */}
        <div
          className="nav-overlay"
          style={{ display: sidebarTrigger ? "block" : "none" }}
          onClick={() => setSidebarTrigger(false)}
        >
          <div className="cursor" />
          <div className="cursor-follower" />
        </div>
      </header>
      <Sidebar
        open={sidebarTrigger}
        closeSidebar={() => setSidebarTrigger(false)}
      />
      <Search
        open={searchBarTrigger}
        closeSidebar={() => setSearchBarTrigger(false)}
      />
    </Fragment>
  );
};
const TranslationDropdown = () => {
  const languages = ["bg", "en", "de"];
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("hotel-admiral-language", lang);
  };
  return (
    <li className="dropdown">
      <i className="fa fa-globe"></i>
      <ul className="translation-dropdown-content">
        {languages.map((lang, index) => {
          return (
            <li key={index} onClick={() => changeLanguage(lang)}>
              {lang.toUpperCase()}
            </li>
          );
        })}
      </ul>
    </li>
  );
};

export default Header;
