import React, { useEffect, useState } from "react";
import useRoomPrices from "../utils/useRoomPrices";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import RoomInfo from "../utils/RoomInfo";
export default function RoomsHomeView() {
  const { t } = useTranslation();
  const [roomsPrices, roomData] = useRoomPrices();
  const [filteredData, setFilteredData] = useState();

  useEffect(() => {
    if (roomData) {
      let copy = roomData.filter((x, i) => x.name.includes("deluxe"));
      copy = copy.slice(0, 4);
      setFilteredData(copy);
    }
  }, [roomsPrices, roomData]);

  const history = useHistory();
  const redirectToRoom = (title, price) => {
    window.scrollTo(10, 0);
    history.push({
      pathname: "/room-single",
      state: {
        name: title,
        price,
        isPriceFormatted: false,
      },
    });
  };

  const seeMore = () => {
    window.scrollTo(10, 0);
    history.push("/rooms");
  };

  return (
    <div className="rooms-home-view-container">
      <h2 className="sec-title text-center">{t("rooms-and-suites")}</h2>
      <div className="row">
        {filteredData &&
          roomsPrices &&
          filteredData.map((room, index) => {
            return (
              <SingleRoom
                key={index}
                price={roomsPrices[room.name]}
                title={room.name}
                t={t}
                onClick={redirectToRoom}
                image={RoomInfo[room.name].image}
              />
            );
          })}
      </div>
      <div
        onClick={seeMore}
        className="theme-btn btn-style-one dark horizontal-margin-auto"
      >
        {t("see-more")}
      </div>
    </div>
  );
}

const SingleRoom = ({ price, title, t, onClick, image }) => {
  return (
    <div
      onClick={() => onClick(title, price)}
      className="col-lg-6 rooms-home-view__single-room"
    >
      <div className="block-four">
        <div className="image">
          <img src={image} alt="" />
        </div>
        <div className="inner-box">
          {price ? (
            <div className="pricing">
              {price}
              {t("bgn")}
            </div>
          ) : null}
          <div className="text">{t(title)}</div>
        </div>
      </div>
    </div>
  );
};
