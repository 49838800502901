import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useHistory } from "react-router-dom";

import Layouts from "../../layouts/Layouts";

import config from "../../config/config.json";

import "./style.css";
import LoadingSpinner from "../LoadingSpinner";

const stripePublicKey = config.stripePublicKey;
const stripePromise = loadStripe(stripePublicKey);

export default function AuthorizePayment() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo(10, 0);
  }, []);

  return (
    <Layouts>
      <Elements stripe={stripePromise} options={{ locale: "bg" }}>
        <div className="payment-page">
          <div className="payment-container">
            <Authorize t={t} i18n={i18n} />
          </div>
        </div>
      </Elements>
    </Layouts>
  );
}

const Authorize = ({ t, i18n }) => {
  const stripe = useStripe();
  const history = useHistory();

  const [successText, setSuccessText] = useState({
    textOne: "",
    textTwo: "",
    textThree: "",
  });
  const [paymentStatus, setPaymentStatus] = useState("");

  const [errorText, setErrorText] = useState({
    textOne: "",
  });

  const clientSecret = new URLSearchParams(window.location.search).get(
    "client_secret"
  );
  const redirectStatus = new URLSearchParams(window.location.search).get(
    "redirect_status"
  );

  const bookingReferenceId = new URLSearchParams(window.location.search).get(
    "booking_reference_id"
  );

  const setResultsTexts = useCallback(
    (status) => {
      let success = { ...successText };
      let error = { ...errorText };

      const lang = i18n.language;
      switch (lang) {
        case "bg":
          success.textOne = "Плащането беше успешно!";
          success.textTwo = `Референтният номер на вашата резервация е: ${bookingReferenceId}`;
          success.textThree =
            "Имейлът за потвърждение е на път и трябва да го получите скоро.";

          error.textOne = "Плащането е неуспешно!";

          break;
        case "en":
          success.textOne = `The payment was successful!`;
          success.textTwo = `Your booking reference number is: ${bookingReferenceId}`;
          success.textThree = `The confirmation email is on its way and you should receive it soon.`;

          error.textOne = "The payment was unsuccessful!";
          break;
        case "ro":
          success.textOne = `Plata a avut succes!`;
          success.textTwo = `Numărul de referință al rezervării dumneavoastră este: ${bookingReferenceId}`;
          success.textThree = `E-mailul de confirmare este pe drum și ar trebui să îl primiți în curând.`;

          error.textOne = "Plata a eșuat!";
          break;
        case "ru":
          success.textOne = `Платеж прошел успешно!`;
          success.textTwo = `Номер вашего бронирования: ${bookingReferenceId}`;
          success.textThree = `Электронное письмо с подтверждением находится в пути, и вы должны получить его в ближайшее время.`;

          error.textOne = "Платеж не удался!";
          break;
        case "de":
          success.textOne = `Die Zahlung war erfolgreich!`;
          success.textTwo = `Ihre Buchungsreferenznummer lautet: ${bookingReferenceId}`;
          success.textThree = `Die Bestätigungs-E-Mail ist unterwegs und Sie sollten sie bald erhalten.`;

          error.textOne = "Die Zahlung war nicht erfolgreich!";
          break;
        default:
          break;
      }
      if (status === "succeeded") {
        setSuccessText(success);
      } else {
        setErrorText(error);
      }
    },
    [
      i18n.language,
      setErrorText,
      setSuccessText,
      successText,
      errorText,
      bookingReferenceId,
    ]
  );

  useEffect(() => {
    if (clientSecret && stripe && !redirectStatus) {
      stripe
        .confirmPayment({
          clientSecret,
          confirmParams: {
            // Return URL where the customer should be redirected after the PaymentIntent is confirmed.
            return_url:
              "https://admiral.bg/authorize-payment?booking_reference_id=" +
              bookingReferenceId,
          },
        })
        .then((res) => {
          if (res.error) {
            setResultsTexts("error");
            setPaymentStatus("error");
          }
        });
    }
    // eslint-disable-next-line
  }, [clientSecret, stripe, redirectStatus, bookingReferenceId]);

  useEffect(() => {
    if (redirectStatus && bookingReferenceId) {
      setPaymentStatus(redirectStatus);
      setResultsTexts(redirectStatus);
    }
    // eslint-disable-next-line
  }, [redirectStatus, bookingReferenceId, i18n.language]);

  if (!bookingReferenceId && !clientSecret) {
    history.push({
      pathname: "/",
    });
  }

  return (
    <div>
      {paymentStatus ? (
        paymentStatus === "succeeded" ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: 24,
            }}
          >
            <h4 style={{ paddingVertical: 24 }}>{successText.textOne}</h4>
            <h5>{successText.textTwo}</h5>
            <h5 style={{ paddingTop: 12 }}>{successText.textThree}</h5>
          </div>
        ) : (
          <h4>{errorText.textOne}</h4>
        )
      ) : (
        <div>
          <h4>{t("authenticating_payment")}</h4>
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
};
