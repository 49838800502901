import React from "react";
import "../assets/css/navbar.css";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
export default function Navbar() {
  const adminTabs = [
    "rooms",
    "reservations",
    "all-offers",
    "create-offer",
    "reviews",
    "vouchers",
    "subscribers",
  ];
  const { t } = useTranslation();
  const history = useHistory();
  const redirect = (page) => {
    history.push(page);
  };
  const adminTab = window.location.href.split("/")[4];
  return (
    <div>
      <ul
        className="admin-navbar"
        style={{ display: "flex", justifyContent: "center" }}
      >
        {adminTabs.map((tab, index) => {
          return (
            <li
              className={`${adminTab === tab && "admin-tab-active"}`}
              onClick={() => redirect(tab)}
              style={{ padding: "12px" }}
              key={index}
            >
              {t(tab)}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
