import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Layouts from "../layouts/Layouts";

export default function PrivacyPolicy() {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const bulgarianPolicy = (
    <Fragment>
      <h4 className="center-text">
        <strong>
          ПОЛИТИКА ЗА ЗАЩИТА НА ЛИЧНИТЕ ДАННИТЕ В<span class="Apple-converted-space">&nbsp;</span>
        </strong>
      </h4>
      <p className="center-text">
        <strong>&bdquo;ЗЛАТНИ ПЯСЪЦИ&ldquo; АД</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        Ние, &bdquo;Златни пясъци&ldquo; АД осъзнаваме важността за защита на личните данни на клиентите и партньорите
        си, като се стремим да поддържаме добри политики и практики, осигуряващи в максимална степен защита на
        предоставените от клиенти лични данни, обработвани при и/или по повод предоставяне на основни и/или допълнителни
        туристически услуги по настаняване и изхранване, както и други услуги, предоставяни на място в хотела,
        стопанисван от нас &ndash;
        <span class="Apple-converted-space">&nbsp; </span>хотел &bdquo;Адмирал&ldquo; .
      </p>
      <p>&nbsp;</p>
      <p>
        Настоящата Политика за поверителност се основава на изискванията на Закона за защита на личните данни и
        Регламент (ЕС) 2016/679 на Европейския парламент и на Съвета от 27 април 2016 г. относно защитата на физическите
        лица във връзка с обработването на лични данни и относно свободното движение на такива данни и за отмяна на
        Директива 95/46/ЕО (Общ регламент относно защитата на данните). Настоящата Политика за защита на личните данни
        се прилага от хотела и официалния му уебсайт.
      </p>
      <p>&nbsp;</p>
      <p>
        Всички изменения и допълнения в Политиката за поверителност ще бъдат прилагани след публикуване на актуалното ѝ
        съдържание, достъпно чрез нашия уебсайт: www.admiral.bg
      </p>
      <p>&nbsp;</p>
      <p>
        Политиката за поверителност е приложима за лични данни на гости, ако те са физическо лице или представител на
        юридическо лице, което ползва или желае да ползва услугите, предоставяни в стопанисваният от дружество
        &bdquo;Златни пясъци&rsquo;&rsquo; АД хотел -&bdquo;Адмирал&ldquo;, включително и тези, предлагани онлайн чрез
        специализирана платформа на нашия уебсайт, включително и чрез социалните мрежи.
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>
        <strong>1.Определения:</strong>
      </p>
      <p>
        В рамките на GDPR са изброени общо 26 определения и не е уместно те да бъдат възпроизведени тук. Въпреки това,
        основните определения по отношение на тази политика са следните:
      </p>
      <p>
        <strong>&bdquo;Лични данни&ldquo; означава:</strong>&nbsp;всяка информация, свързана с идентифицирано физическо
        лице или физическо лице, което може да бъде идентифицирано (&bdquo;субект на данни&ldquo;); физическо лице,
        което може да бъде идентифицирано, е лице, което може да бъде идентифицирано, пряко или непряко, по-специално
        чрез идентификатор като име, идентификационен номер, данни за местонахождение, онлайн идентификатор или по един
        или повече признаци, специфични за физическата, физиологичната, генетичната, психическата, умствената,
        икономическата, културната или социална идентичност на това физическо лице;
      </p>
      <p>
        <strong>&bdquo;Обработване&ldquo; означава:</strong>&nbsp;всяка операция или съвкупност от операции, извършвана
        с лични данни или набор от лични данни чрез автоматични или други средства като събиране, записване,
        организиране, структуриране, съхранение, адаптиране или промяна, извличане, консултиране, употреба, разкриване
        чрез предаване, разпространяване или друг начин, по който данните стават достъпни, подреждане или комбиниране,
        ограничаване, изтриване или унищожаване;
      </p>
      <p>
        <strong>&bdquo;Администратор&ldquo; означава:</strong>&nbsp;физическо или юридическо лице, публичен орган,
        агенция или друга структура, която сама или съвместно с други определя целите и средствата за обработването на
        лични данни; когато целите и средствата за това обработване се определят от правото на Съюза или правото на
        държава членка, администраторът или специалните критерии за неговото определяне могат да бъдат установени в
        правото на Съюза или в правото на държава членка;
      </p>
      <p>
        <strong>&bdquo;съгласие на субекта на данните &ldquo;означава:</strong>
        &nbsp;всяко свободно изразено, конкретно, информирано и недвусмислено указание за волята на субекта на данните,
        посредством изявление или ясно потвърждаващо действие, което изразява съгласието му свързаните с него лични
        данни да бъдат обработени;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>2.Принципи, свързани с обработването на лични данни:</strong>
      </p>
      <p>Съществуват редица фундаментални принципи, на които се основава GDPR. Те са както следва:</p>
      <p>Личните данни са:</p>
      <p>
        &nbsp; &bull; обработвани законосъобразно, добросъвестно и по прозрачен начин по отношение на субекта на данните
        (&bdquo;законосъобразност, добросъвестност и прозрачност&ldquo;);
      </p>
      <p>
        &nbsp; &bull; събирани за конкретни, изрично указани и легитимни цели и не се обработват по-нататък по начин,
        несъвместим с тези цели; по-нататъшното обработване за целите на архивирането в обществен интерес, за научни или
        исторически изследвания или за статистически цели не се счита, съгласно член 89, параграф 1, за несъвместимо с
        първоначалните цели (&bdquo;ограничение на целите&ldquo;);
      </p>
      <p>
        &nbsp; &bull; подходящи, свързани със и ограничени до необходимото във връзка с целите, за които се обработват
        (&bdquo;свеждане на данните до минимум&ldquo;);
      </p>
      <p>
        &nbsp; &bull; точни и при необходимост да бъдат поддържани в актуален вид; трябва да се предприемат всички
        разумни мерки, за да се гарантира своевременното изтриване или коригиране на неточни лични данни, като се имат
        предвид целите, за които те се обработват (&bdquo;точност&ldquo;);
      </p>
      <p>
        &nbsp; &bull; съхранявани във форма, която да позволява идентифицирането на субекта на данните за период, не
        по-дълъг от необходимото за целите, за които се обработват личните данни; личните данни могат да се съхраняват
        за по-дълги срокове, доколкото ще бъдат обработвани единствено за целите на архивирането в обществен интерес, за
        научни или исторически изследвания или за статистически цели съгласно член 89, параграф 1, при условие че бъдат
        приложени подходящите технически и организационни мерки, предвидени в настоящия регламент с цел да бъдат
        гарантирани правата и свободите на субекта на данните (&bdquo;ограничение на съхранението&ldquo;);
      </p>
      <p>
        &nbsp; &bull; обработвани по начин, който гарантира подходящо ниво на сигурност на личните данни, включително
        защита срещу неразрешено или незаконосъобразно обработване и срещу случайна загуба, унищожаване или повреждане,
        като се прилагат подходящи технически или организационни мерки (&bdquo;цялостност и поверителност&ldquo;);
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>3.Кой обработва и носи отговорност за предоставени лични данни:</strong>
      </p>
      <p>
        &bdquo;Златни пясъци&rdquo; АД (&bdquo;ние&rdquo;) е търговското дружество, регистрирано в Търговския регистър и
        регистъра на юридическите лица с нестопанска цел към Агенцията по вписванията с ЕИК BG813095472, което събира,
        обработва и съхранява лични данни при условията на тази Политика и приложимото законодателство на Европейския
        съюз и Р.България.
      </p>
      <p>&nbsp;</p>
      <p>
        Златни пясъци АД е администратор на лични данни по смисъла на Регламент (ЕС) 2016/679 на Европейския парламент и
        на Съвета от 27 април 2016 година относно защитата на физическите лица във връзка с обработването на лични данни
        и относно свободното движение на такива данни и Закона за защита на личните данни.
      </p>
      <p>&nbsp;</p>
      <p>
        По всички въпроси, свързани с обработването на лични данни, може да се обръщате към нас на адреса ни на
        управление: гр. Варна 9007, к.к. Златни пясъци или на следните координати за връзка:
      </p>
      <p>&nbsp; &bull; уеб сайт: www.admiral.bg</p>
      <p>&nbsp; &bull; имейл: reservations@admiral.bg</p>
      <p>&nbsp;</p>
      <p>
        <strong>4. Какви данни, за какви цели и на какво правно основание обработваме:</strong>
      </p>
      <p>
        <strong>4.1.</strong>&nbsp;В зависимост от конкретните цели и основания &bdquo;Златни пясъци&rdquo; АД обработва
        посочените по-долу данни самостоятелно или в комбинация помежду им, а именно .
      </p>
      <p>
        <strong>&nbsp; А)</strong>&nbsp;Предоставени от клиенти данни, необходими за идентификация и изпълнение на
        направени и потвърдени резервации, като:
      </p>
      <p>
        &nbsp; &nbsp; &bull; три имена, телефон и/или адрес на електронната поща за връзка, или посочено лице за
        контакт, дата и час на настаняване и заминаване, деца и възрастта им;
      </p>
      <p>
        &nbsp; &nbsp; &bull; данни, събирани при плащане, направено към нас &ndash; номер на кредитна или дебитна карта,
        банкова сметка и друга информация, събирана при и обработвана във връзка с извършване на плащането по банков
        път, чрез директен дебит или чрез Пос-терминал на &bdquo;Златни пясъци&rdquo; АД;
      </p>
      <p>
        &nbsp; &nbsp; &bull; данни за ползваните услуги и получената чрез тях информация относно предпочитани услуги,
        предлагани от нас;
      </p>
      <p>&nbsp; &nbsp; &bull; други данни, които ни предоставяте във връзка с ползваните вече услуги.</p>
      <p>
        <strong>&nbsp; Б)</strong>&nbsp;Данни, предоставени от клиенти и съхранявани от &bdquo;Златни пясъци&rdquo; АД в
        процеса на предоставяне на услугите по настаняване на място във хотел &bdquo;Адмирал&ldquo;, които данни се
        събират, обработват и съхраняват съгласно действащите нормативни изисквания относно воденето на регистър на
        настанените туристи от лицата, извършващи дейност хотелиерство, а именно:
      </p>
      <p>
        &nbsp; &nbsp; &bull; имена на лицето; ЕГН /за български граждани/ или ЛНЧ /за чужди граждани с разрешение за
        пребиваване в БГ/, или дата на раждане /при всички останали случаи/;
      </p>
      <p>&nbsp; &nbsp; &bull; пол;</p>
      <p>&nbsp; &nbsp; &bull; националност;</p>
      <p>
        &nbsp; &nbsp; &bull; номер на лична карта/ валиден национален документ за самоличност, държава издала
        националния документ,
      </p>
      <p>
        <strong>&nbsp; В)</strong>&nbsp;Други:
      </p>
      <p>
        &nbsp; &nbsp; &bull; Дигитални данни- видеозаписи. Това са данните, събирани чрез системите за видеонаблюдение,
        използвани от &bdquo;Златни пясъци&rdquo; АД във всички обществено-достъпни места в хотел &bdquo;Адмирал&ldquo;
        (фоайе, рецепция, ресторант, лоби бар, коридори, стълбища, входове, паркинг,), с цел охрана, наблюдение, контрол
        и защита на обществения ред;.
      </p>
      <p>&nbsp; &nbsp; &bull; IP адрес при посещение на уебсайта ни/ платформата ни за онлайн резервация,</p>
      <p>
        &nbsp; &nbsp; &bull; Данни във връзка с предявена рекламация по отношение на предоставяна от нас и ползвана от
        гостите туристическа услуга;
      </p>
      <p>
        &nbsp; &nbsp; &bull; Информация за вида и съдържанието на резервация, както и всяка друга информация, свързана с
        нея, включително електронна поща, писма, искания, молби, жалби, оплаквания и друга обратна връзка, която
        получаваме от вас;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>4.2. Цели и правни основания за обработване на личните данни:</strong>
      </p>
      <p>
        Водещо основание за обработване и съхраняване на лични данни е изпълнението на договор, по който субектът на
        данни е страна или за предприемане на стъпки по искане на субекта на данните преди сключването на такъв договор.
        Същевременно, обработването на определени лични данни е необходимо за спазването на законови задължения на
        дружеството по Кодекса на труда, Закона за туризма, Закона за гражданската регистрация, Закона за счетоводството
        и други приложими нормативни актове, както и за защитата на легитимните интереси на администратора или на трета
        страна, като:
      </p>
      <p>
        <strong>&nbsp; А)</strong>&nbsp;Ние обработване и съхраняваме лични данни, които са минимално необходими и
        нормативно изискуеми за целите на предоставяне на туристическите услуги по настаняване и изхранване, както и за
        всички други услуги, предоставяни на място в хотел &bdquo;Адмирал&ldquo; като:
      </p>
      <p>
        &nbsp; &nbsp; &bull; Идентифициране на клиент при: извършване, изменение и прекратяване на резервация, както и
        при предоставянето на туристически услуги по настаняване и изхранване, както и всички други услуги, предоставяни
        на място в хотела. Идентифицирането на клиент се извършва по всички търговски и комуникационни канали &ndash; на
        рецепция в хотела чрез предоставяне на документ за самоличност, по телефон, на онлайн платформата ни чрез
        електронна форма за контакт, по електронна поща и др.;
      </p>
      <p>
        &nbsp; &nbsp; &bull; Актуализиране лични данни или на информацията за услуги в хотел &bdquo;Адмирал&ldquo; по
        заявка за корекция/изменение на данни/услуги;
      </p>
      <p>&nbsp; &nbsp; &bull; Обслужване и отговор на клиентски оплаквания/запитвания/жалби;</p>
      <p>
        &nbsp; &nbsp; &bull; Корекции на дължими суми по вече реализирани резервации по настаняване и изхранване при
        наличие на основание за това;
      </p>
      <p>
        <strong>&nbsp; Б)</strong>&nbsp;В изпълнение на свои законови задължения, &bdquo;Златни пясъци&rdquo; АД
        обработва лични данните за следните цели:
      </p>
      <p>
        &nbsp; &nbsp; &bull; Предоставяне на информация на Министерство на вътрешните работи, компетентната община по
        местонахождение на хотела, Комисията за защита на потребителите и на Комисията за защита на личните данни, във
        връзка с изпълнение на задълженията ни като хотелиер, произтичащи от действащите нормативни актове в тази
        област;
      </p>
      <p>
        &nbsp; &nbsp; &bull; Обработване на лични данни в издадени на име на клиент фактури за други цели, съвместими с
        първоначалната цел за събирането им
      </p>
      <p>&nbsp; &nbsp; &bull; Извършване на данъчно-осигурителен контрол от съответните компетентни държавни органи;</p>
      <p>
        &nbsp; &nbsp; &bull; Предоставяне на информация на съда и трети лица в рамките на производства пред съд, с цел
        защита на законните си интереси, включително събиране на вземания от клиенти по съдебен ред;
      </p>
      <p>
        <strong>&nbsp; В)</strong>&nbsp;&bdquo;Златни пясъци&rdquo; АД обработва лични данни
        <span class="Apple-converted-space">&nbsp; </span>и за целите на следните наши легитимни интереси:
      </p>
      <p>
        &nbsp; &nbsp; &bull; За целите на директния маркетинг &ndash; отправяне на оферти съдържащи информация за
        актуални наши оферти, промоции и отстъпки за настаняване в хотел &bdquo;Адмирал&ldquo; и/или изменение в
        условията на вече използваните такива;
      </p>
      <p>
        &nbsp; &nbsp; &bull; За включване на име на гост, фотографии, видео и други данни в рекламни брошури, уеб сайт и
        др. публикации на &bdquo;Златни пясъци&rdquo; АД в резултат на участието в организирани от нас групови
        мероприятия и занимания в хотела (танци, спортна дейност и други развлечения).
      </p>
      <p>
        &nbsp; &nbsp; &bull; За защита, упражняване или запазване на законовите права, неприкосновеността, безопасността
        или собствеността на администратора, на потребителите на услугите на администратора и членовете на обществото;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>5. Категории трети лица, които получават достъп и обработват лични данни:</strong>
      </p>
      <p>
        Във връзка с осъществяване на дейността си и изпълнение на договорните ни ангажименти с нашите клиенти, ние
        предоставяме личните данни на следните групи лица &ldquo;получатели&ldquo;, а именно:
      </p>
      <p>&nbsp; &bull; на субекта на данните- винаги, когато упражнят това свое право;</p>
      <p>&nbsp; &bull; на потребителите/клиентите, за които се отнасят данните;</p>
      <p>
        &nbsp; &bull; на бизнес партньори &ndash; за целите на изпълнението на направените от гостите резервации:
        туристически агенти и представители в България и чужбина, транспортни фирми и авиокомпании, доставчици на
        съответните основни и допълнителни туристически услуги, и други подизпълнители, с които имаме сключени договори;
      </p>
      <p>&nbsp; &bull; доставчиците на платежни услуги за разплащане онлайн чрез кредитни карти;</p>
      <p>
        &nbsp; &bull; застрахователни компании при заявяване на настъпило застрахователно събитие с турист, настанен в
        хотела ни;
      </p>
      <p>
        &nbsp; &bull; IT компании поддържащи информационни системи, фирмения ни уебсайт, софтуери и платформи за
        управление на резервациите на нашите клиенти и др.;
      </p>
      <p>
        &nbsp; &bull; на публични органи (МВР, община, НАП, НОИ, Комисията за защита на потребителите, Комисията за
        защита на личните данни, съдебни и др. контролни органи);
      </p>
      <p>
        &nbsp; &bull; Други администратори на лични данни, на които &bdquo;Златни пясъци&rdquo; АД предоставя лични
        данни на законно основание и/или на база двустранно подписан договор.
      </p>
      <p>&nbsp;</p>
      <p>
        Възможно е да се наложи &ndash; по закон, при съдебен спор и/или по заявка на обществени и правителствени органи
        в или извън страната на местоживеене, както и за целите на националната сигурност, правоприлагането или други
        проблеми от обществена значимост, ние да разкрием лични данни, когато такова разкриване е необходимо или
        уместно.
      </p>
      <p>&nbsp;</p>
      <p>
        Възможно е и да разкрием информация за гост, ако установим, че такова разкриване е обосновано и необходимо за
        прилагането на нашите фирмени правила и условия, или за да защитим своите дейности, законни интереси и правата и
        законните интереси и на други потребители. Освен това, в случай на реорганизация, сливане или продажба е
        възможно да прехвърлим всякаква и цялата събрана база данни на съответната трета страна- правоприемник.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>6. За какъв срок се съхраняват лични данни. Сигурност:</strong>
      </p>
      <p>Продължителността на съхранение на лични данни зависи от целите на обработването, за които са събрани:</p>
      <p>
        &nbsp; &bull; Личните данни, обработвани с цел предоставяне и изпълнение на туристически услуги по настаняване и
        изхранване в хотел &bdquo;Адмирал&ldquo;, се съхраняват за срок до 3 (три) години, считано от датата на
        напускането на хотела, както и до окончателно уреждане на всички финансови отношения между страните, спазвайки
        нормативно определените срокове, когато има такива;
      </p>
      <p>
        &nbsp; &bull; Личните данни, обработвани с цел издаване на счетоводни/финансови документи за осъществяване на
        данъчно-осигурителния контрол, както но не само &ndash; фактури, дебитни, кредитни известия, се съхраняват поне
        3 (три) години, освен ако приложимото законодателство не предвижда по-дълъг срок.
      </p>
      <p>&nbsp; &bull; Картина (Видеозапис) &ndash; до 30 дни от създаване на записа.</p>
      <p>&nbsp;</p>
      <p>
        Съхраняването на личните данни за по-дълги срокове е възможно за защита на законните интереси на &bdquo;Златни
        пясъци&rdquo; АД, както и до изтичане на съответната погасителна давност с цел защита при евентуални претенции
        на клиенти във връзка с изпълнение/прекратяване на предоставяните туристически услуги по настаняване и
        изхранване в хотелите ни, както и за по-дълъг срок в случай на вече възникнал правен спор &ndash; документите се
        съхраняват до окончателното му решаване с влязло в сила съдебно/арбитражно решение.
      </p>
      <p>&nbsp;</p>
      <p>
        Като Администратор на лични данни- &bdquo;Златни пясъци&rdquo; АД. полага дължимата грижа и предприема
        съответните и изисквани от нормативната уредба административни, технически и физически мерки, както и такива,
        свързани с персонала /обучение, информираност и др./, за да опази информацията, с която разполага, включително и
        опазване на личните данни на клиентите си от загуба, кражба и неразрешено ползване, разкриване, модифициране и
        всякакви други незаконни форми на обработване. Ние разполагаме с физически, електронни и процедурни защитни
        средства, които съответстват на нашите законови задължения по отношение защитата на личните данни и които
        поддържаме в съответствие със съвременните технологични достижения.
      </p>
      <p>&nbsp;</p>
      <p>
        Ние отговаряме за защитата на личните данни на клиента, станали ни известни по повод осъществяване на дейността
        ни като работодател и хотелиер, при предоставяне на предлаганите от нас туристически услуги, конкретизирани в
        предмета на Общите условия и на тази Политика, освен в случаите на непреодолима сила, случайно събитие или
        злоумишлени действия на трети лица, както и в случаите, при които клиента сам е направил тази информация
        достъпна за трети лица.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>7. Видеонаблюдение:</strong>
      </p>
      <p>
        Общите площи на хотел &bdquo;Адмирал&ldquo; са обект на денонощно видеонаблюдение, което се осъществява чрез
        разположени на съответните места стационарни охранителни камери.
      </p>
      <p>
        Видеонаблюдението се осъществява с цел защита, упражняване или запазване на законовите права,
        неприкосновеността, безопасността или собствеността на администратора, неговите служители и/или контрагенти,
        както и за обезпечаване безопасността, неприкосновеността и сигурността на гостите на хотела и членовете на
        обществото.
      </p>
      <p>
        Видеонаблюдението се организира и контролира от служители на администратора, специално обучени за защита на
        данните.
      </p>
      <p>
        Записите се съхраняват на видео-сървъри за срок от 30 дни, освен при регистрирано нарушение на правата на
        администратора, туристите или третите лица, в който случай срокът на пазене на записите може да бъде продължен
        според конкретните нужди.
      </p>
      <p>
        Госта има право да поиска преглед на записите, ако твърди нарушение на права, които се отнасят до трети лица или
        до лице, по отношение на което упражнявате надзор, като искането ще бъде разгледано в сроковете, предвидени в
        раздел 8 от настоящата политика.
      </p>
      <p>
        Дружеството може да откаже да разгледа искания, които се повтарят неоснователно, изискват несъразмерни
        технически усилия или застрашават поверителността на други потребители.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          8. Какви са правата във връзка с обработването на личните данни от ,,Златни пясъци&rdquo; АД и какви действия
          следва да бъдат предприети, за да бъдат упражнявани:
        </strong>
      </p>
      <p>
        &nbsp; &bull; право на достъп до отнасящите се за него данни- клиентът има право по всяко време да поиска от нас
        потвърждение за това, дали отнасящи се до него данни се обработват, информация за целите на това обработване, за
        категориите данни и за получателите или категориите получатели, на които данните се разкриват;
      </p>
      <p>
        &nbsp; &bull; право да коригира и актуализира своите лични данни, когато те са неточни или непълни с оглед
        целите на обработката им;
      </p>
      <p>
        &nbsp; &bull; право на изтриване (правото &bdquo;да бъдеш забравен&ldquo;), когато данните му се обработват
        незаконосъобразно или с отпаднало основание (изпълнена е първоначалната цел, за която са били събрани и
        обработвани, изтекъл срок на съхранение (вкл. и давностен), оттеглено съгласие за обработване, възразили сте за
        тяхната обработка, и др.), няма друго основание за обработването им или националното или европейското
        законодателство изискват това;
      </p>
      <p>
        &nbsp; &bull; право на ограничаване на обработването&ndash; при наличие на правен спор между &bdquo;Златни
        пясъци&rdquo; АД и физическото лице до неговото решаване и/или за установяването, упражняването или защитата на
        правни претенции; когато обработването е неправомерно, но субектът на данните не желае личните данни да бъдат
        изтрити, а изисква вместо това ограничаване на използването им; при отправено от госта възражение срещу
        обработването на лични данни за срока на проверката на основателността му;
      </p>
      <p>
        &nbsp; &bull; право на преносимост на данните- субектът на данните има право да поиска от нас да прехвърлим
        личните му данни в машинно четим формат на друг изрично посочен от него администратор без възпрепятстване;
      </p>
      <p>
        &nbsp; &bull; задължение за уведомяване при коригиране или изтриване на лични данни или ограничаване на
        обработването- клиентът има право да изиска от нас да уведомим третите лица, на които са разкрити негови лични
        данни, за всяко заличаване, коригиране или блокиране на тези данни, с изключение на случаите, когато това е
        невъзможно или е свързано с прекомерни усилия за &bdquo;Златни пясъци&rdquo; АД
      </p>
      <p>
        &nbsp; &bull; право на уведомяване за нарушение на сигурността на лични данни- в случаите, когато има вероятност
        нарушението на сигурността на данните да породи висок риск за правата и свободите на физическите лица. Ние не
        сме длъжни да<span class="Apple-converted-space">&nbsp; </span>
        уведомяваме лицата, ако: сме предприели подходящи технически и организационни мерки за защита по отношение на
        данните, засегнати от нарушението на сигурността, както и ако сме взели впоследствие мерки, които гарантират, че
        нарушението няма да доведе до висок риск за правата на гостите и ако уведомяването би изисквало непропорционални
        усилия.
      </p>
      <p>
        &nbsp; &bull; право на възражение срещу обработването на личните данни&ndash; по всяко време и на основания,
        свързани с конкретната ситуация на лицето, при условие, че не съществуват убедителни законови основания за
        обработването, които се ползват с предимство пред интересите, правата и свободите на субекта на данни, или
        съдебен процес.
      </p>
      <p>
        &nbsp; &bull; Когато се обработват лични данни за целите на директния маркетинг, Потребителят има право по всяко
        време да направи възражение срещу обработване на лични данни, отнасящо се до него за този вид маркетинг, което
        включва и профилиране, доколкото то е свързано с директния маркетинг. Най-късно в момента на първото
        осъществяване на контакт с потребителя, той изрично се уведомява за съществуването на правото на възражение
        описано по-горе, което му се предоставя чрез уведомление по ясен начин и отделно от всяка друга информация.
      </p>
      <p>
        &nbsp; &bull; право на защита по съдебен и административен ред&ndash; право на подаване на жалба до надзорен
        орган, право на ефективна съдебна защита срещу надзорен орган, право на ефективна съдебна защита срещу
        администратор или обработващ лични данни; право на обезщетение за претърпени вреди.
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>Данни за надзорния орган:</p>
      <p>КОМИСИЯ ЗА ЗАЩИТА НА ЛИЧНИТЕ ДАННИ /КЗЛД/,</p>
      <p>гр.София 1592, бул.&ldquo;Проф.Цветан Лазаров&ldquo; №2,</p>
      <p>тел.: 02/91-53-518, факс: 02/91-53-525,</p>
      <p>e-mail: kzld@cpdp.bg</p>
      <p>www.cpp.bg</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>
        <strong>9. Може ли да се откаже предоставянето на лични данни на &bdquo;Златни пясъци&rdquo;</strong>{" "}
        <strong>АД и какви са последиците от това?</strong>
      </p>
      <p>
        За да изпълним направената резервация и за да предоставим заявените от гостите услуги по настаняване в хотел
        &bdquo;Адмирал&ldquo;, ние се нуждаем от определени данни, които са нормативно определени от действащото в
        Република България законодателство.
      </p>
      <p>
        Непредоставянето на посочените в т.4. лични данни препятства възможността &bdquo;Златни пясъци&rdquo; АД да
        приеме резервацията и съответно да<span class="Apple-converted-space">&nbsp; </span>предостави заявените услуги
        на място в хотел &bdquo;Адмирал&ldquo;.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>10. Политиката ни за използване на бисквитки (Cookie Policy):</strong>
      </p>
      <p>
        &bdquo;Златни пясъци&rdquo; АД използва т.нар &bdquo;бисквитки&ldquo; (Cookies) в своя сайт: www.admiral.bg
        <span class="Apple-converted-space">&nbsp; </span>които са важни за коректната му работа. Посещавайки нашия
        сайт, посетителите приемате използването на бисквити.
      </p>
      <p>&nbsp;</p>
      <p>Видове бисквитки, които използваме:</p>
      <p>
        &nbsp; &bull; Задължителни бисквитки &ndash; тези бисквитки са необходими за коректната работа на уебсайта.
        Например, с тези бисквитки показваме информацията на нашия сайт, снимки, видеа и др., както и помагат за
        правилното функциониране на търсачката, за да не се налага да въвеждате една и съща информация на различните
        страници. Тези бисквитки са временни и се изтриват при затваряне на
        <span class="Apple-converted-space">&nbsp; </span>браузер.
      </p>
      <p>
        &nbsp; &bull; Аналитични бисквитки &ndash; благодарение на тези бисквитки ние следим посещаемостта на нашия сайт
        и можем да анализираме доколко нашите потребители работят лесно с него (бисквитки на Google Analytics). Тези
        бисквитки не ни дават никаква информация за лични данни. Те ни показват кои страници от сайта ни са разгледани,
        дали сайтът ни е посетен през мобилно или десктоп устройство и други анонимни данни.
      </p>
      <p>&nbsp;</p>
      <p>
        Настройките на бисквитките, които получавате от нашия сайт, могат да бъдат направени в браузъра, който
        използвате. Ограничението на някои видове бисквитки, е възможно сайтът ни да не работи напълно коректно и да не
        можете да използвате пълните му функционалности.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>11. Промени в политиката за поверителност:</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        &bdquo;Златни пясъци&rdquo; АД има право, когато обстоятелствата налагат това, едностранно да актуализира,
        изменя и допълва политиката за защита на личните данни по всяко време в бъдеще. Всяко допълване или промяна на
        тази Политика ще бъде публикувано в уебсайта на хотела: www.admiral.bg и/или ще се предоставя при поискване на
        клиента.
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </Fragment>
  );

  const englishPolicy = (
    <Fragment>
      <p className="center-text">
        <strong>PERSONAL DATA PROTECTION POLICY</strong>
      </p>
      <p className="center-text">
        <strong>
          <em>GOLDEN SANDS JSC</em>
        </strong>
      </p>
      <p>&nbsp;</p>
      <p>
        We, Golden Sands JSC are aware of the importance of protecting the personal data of our customers and partners,
        and strive to maintain good policies and practices that ensure maximum protection of any personal data provided
        by customers, processed during and/or on the occasion of providing basic and/or additional tourist accommodation
        and catering services, as well as other services provided on location in the hotel we managed ‒ the Admiral
        Hotel.
      </p>
      <p>&nbsp;</p>
      <p>
        This Privacy Policy is based on the requirements of the Personal Data Protection Act and Regulation (EU)
        2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of individuals with
        regard to the processing of personal data and on the free movement of such data and repealing Directive 95/46/EC
        (General Data Protection Regulation). This Privacy Policy is applied in the hotel and on its official website.
      </p>
      <p>&nbsp;</p>
      <p>
        All changes and additions to the Privacy Policy will be applied after the publication of its current content,
        available on our website: www.admiral.bg
      </p>
      <p>&nbsp;</p>
      <p>
        The privacy policy is applicable to personal data of guests if they are a natural person or a representative of
        a legal entity that uses or wishes to use the services provided in the hotel managed by Golden Sands JSC ‒ the
        Admiral Hotel, including those offered online through a specialized platform on our website, including through
        social networks.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>1. Definitions:</strong>
      </p>
      <p>
        A total of 26 definitions are listed in the GDPR and it is not appropriate to reproduce them here. However, the
        main definitions of this policy are as follows:
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>'Personal data' means:</strong> any information relating to an identified or identifiable natural person
        ('data subject'); an identifiable natural person is a directly or indirectly identifiable person, in particular
        by means of an identifier, such as name, identification number, location data, online identifier or one or more
        features specific to the natural person, the physiological, genetic, mental, intellectual, economic, cultural or
        social identity of that individual;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>"Processing" means:</strong> any operation or set of operations carried out with personal data or a
        personal data set by automatic or other means, such as collecting, recording, organising, structuring, storing,
        adapting or modifying, retrieving, consulting, using, disclosing by transmission , disseminating or otherwise
        making the data accessible, arranging or combining, restricting, deleting or destroying such data;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>'Administrator' means:</strong> a natural or legal person, public authority, agency or any other body
        which alone or jointly with others determines the purposes and means of the processing of personal data; where
        the purposes and means of such processing are determined by EU law or the law of a Member State, the controller
        or the specific criteria for its determination may be laid down in EU legislation or in the legislation of a
        Member State;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>"Consent of the data subject" means:</strong> any freely expressed, specific, informed and unambiguous
        indication of the data subject's will, by means of a statement or clearly confirmatory action expressing his or
        her consent to the processing of personal data relating to him or her;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>2.</strong> <strong>Principles relating to personal data processing of: </strong>
        There are a number of fundamental principles on which the GDPR is based. They are as follows:
      </p>
      <p>Personal data are:</p>
      <p>
        <span class="Apple-converted-space">&nbsp; </span>&bull; processed lawfully, in good faith and in a transparent
        manner with regard to the data subject ("lawfulness, good faith and transparency");
      </p>
      <p>
        <span class="Apple-converted-space">&nbsp; </span>&bull; collected for specific, explicitly stated and
        legitimate purposes and not further processed in a way incompatible with those purposes; further processing for
        archiving purposes in the public interest, for scientific or historical research or for statistical purposes
        shall not be considered, in accordance with Article 89 (1), incompatible with the original purposes ("limitation
        of purposes");
      </p>
      <p>
        &bull; appropriate, related to and limited to what is necessary in relation to the purposes for which they are
        processed ("data minimization");
      </p>
      <p>
        <span class="Apple-converted-space">&nbsp; </span>&bull; accurate and, if necessary, kept up to date; all
        reasonable measures must be taken to ensure the timely erasure or correction of inaccurate personal data, taking
        into account the purposes for which they are processed ("accuracy");
      </p>
      <p>
        &bull; stored in a way that allows the identification of the data subject for a period no longer than necessary
        for the purposes for which the personal data are processed; personal data may be stored for longer periods
        insofar as they will be processed solely for archiving purposes in the public interest, for scientific or
        historical research or for statistical purposes in accordance with Article 89 (1), provided that the appropriate
        technical and organizational measures provided for in this Regulation in order to guarantee the rights and
        freedoms of the data subject ("storage restriction");
      </p>
      <p>
        &nbsp; &bull; processed in a way that ensures an appropriate level of security of personal data, including
        protection against unauthorized or unlawful processing and against accidental loss, destruction or damage,
        applying appropriate technical or organizational measures ("integrity and confidentiality");
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>
        <strong>3. Who processes and is responsible for personal data provided:</strong>
      </p>
      <p>
        Golden Sands JSC (we) is the company registered in the Commercial Register and the register of non‒profit legal
        entities at the Registry Agency with UIC BG813095472, which collects, processes and stores personal data under
        the terms of this policy and applicable legislation of the European Union and the Republic of Bulgaria.
      </p>
      <p>&nbsp;</p>
      <p>
        Golden Sands JSC is a personal data controller within the meaning of Regulation (EU) 2016/679 of the European
        Parliament and the Council of 27 April 2016 on the protection of individuals in connection with the processing
        of personal data and on the free movement of such data and the law to protect personal data.
      </p>
      <p>
        On all issues relating to the processing of personal data, you can contact us at our address of management:
        Varna 9007, Golden Sands Resort, or the following contact coordinates:
      </p>
      <p>
        <span class="Apple-converted-space">&nbsp;&nbsp; </span>&bull; Website: www.admiral.bg
      </p>
      <p>
        <span class="Apple-converted-space">&nbsp;&nbsp; </span>&bull; Email: reservations@admiral.bg
      </p>
      <p>
        <strong>4. What data do we process, for what purposes and on what legal basis:</strong>
      </p>
      <p>
        <strong>4.1.</strong>&nbsp;Depending on the specific goals and grounds, Golden Sands JSC processes the only the
        data appointed below or in combination between them, namely:
      </p>
      <p>
        <strong>&nbsp; А)</strong>&nbsp;Data provided by clients and needed in order to identify and carry out
        reservations made and confirmed, such as:
      </p>
      <p>
        &nbsp; &nbsp; &nbsp;&bull; Three names, telephone and/or e‒mail address, or a specified contact person, date and
        time of accommodation and departure, children and their age;
      </p>
      <p>
        <span class="Apple-converted-space">&nbsp; &nbsp; </span>&bull; Data collected upon payment made to us &ndash;
        credit or debit card number, bank account and other information collected at and processed in connection with
        the payment by bank transfer, through a direct debit or through the POS Golden Sands JSC;
      </p>
      <p>
        <span class="Apple-converted-space">&nbsp; &nbsp; </span>&bull; Data on the services used and the information
        obtained about their preferred services that we offer;
      </p>
      <p>
        <span class="Apple-converted-space">&nbsp; &nbsp; </span>&bull; Other data you provide to us in connection with
        the services already used.
      </p>
      <p>
        <strong>&nbsp; B)</strong>&nbsp;Data provided by clients and stored by Golden Sands JSC in the process of
        providing the accommodation services at the Admiral Hotel, which are collected, processed and stored in
        accordance with the current regulatory requirements regarding the register of accommodated tourists from the
        persons operating hotel businesses, namely:
      </p>
      <p>
        <span class="Apple-converted-space">&nbsp;&nbsp; &nbsp; </span>&bull; Full name of the person; PIN /for
        Bulgarian citizens/ or LNS /for foreign nationals with a residence permit in BG/ or date of birth /in all other
        cases/;
      </p>
      <p>
        <span class="Apple-converted-space">&nbsp;&nbsp; &nbsp; </span>&bull; gender;
      </p>
      <p>
        <span class="Apple-converted-space">&nbsp;&nbsp; &nbsp; </span>&bull; nationality;
      </p>
      <p>
        <span class="Apple-converted-space">&nbsp;&nbsp; &nbsp; </span>&bull; ID card number/valid national identity
        document, country that issued the national identity document.
      </p>
      <p>
        <strong>&nbsp; C)</strong>&nbsp;Other:
      </p>
      <p>
        <span class="Apple-converted-space">&nbsp; &nbsp; </span>&bull; Digital data ‒ videos. These are the data
        collected through CCTV systems used by Golden Sands JSC in all public and accessible places at the Admiral Hotel
        (lobby, reception, restaurant, lobby bar, corridors, stairs, entrances, parking,) for the purpose of security,
        monitoring, control and protection of public order;
      </p>
      <p>
        <span class="Apple-converted-space">&nbsp; &nbsp; </span>&bull; IP address when visiting our website/ platform
        for online reservation,
      </p>
      <p>
        <span class="Apple-converted-space">&nbsp; &nbsp; </span>&bull; Data regarding a claim filed with respect to the
        tourist service provided by us and used by the guests;
      </p>
      <p>
        <span class="Apple-converted-space">&nbsp; &nbsp; </span>&bull; Information on the type and content of the
        reservation, as well as any other information related to it, including e‒mail, letters, applications, requests,
        complaints, and other feedback we receive from you;
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>4.2. Objectives and legal grounds for processing personal data:</strong>
      </p>
      <p>
        The leading basis for the processing and storage of personal data is the execution of a contract to which the
        data subject is a party or for the purpose of taking steps at the request of the data subject before the
        conclusion of such a contract. At the same time, the processing of certain personal data is necessary for
        compliance with the legal obligations of the company under the Labor Code, the Tourism Act, the Civil
        Registration Act, the Accounting Act and other applicable normative documents, as well as for the protection of
        the legitimate interests of the Administrator or of third parties, such as:
      </p>
      <p>
        <strong>&nbsp; А)</strong>&nbsp;We process and store personal data that are minimally necessary and statutory
        for the purpose of providing tourist accommodation and catering services, as well as for all other services
        provided on the spot at the Admiral Hotel, such as:
      </p>
      <p>
        &nbsp; &nbsp;&bull; Identification of a client when: carrying out, amendment and termination of a reservation,
        as well as when providing tourist accommodation and catering services, as well as all other services provided on
        location at the hotel. The identification of a client is carried out through all commercial and communication
        channels ‒ at the hotel reception by providing an identity document, by telephone, on our online platform
        through an electronic contact form, by e‒mail, etc.;
      </p>
      <p>
        <span class="Apple-converted-space">&nbsp;&nbsp; </span>&bull; Servicing and responding to client
        complaints/inquiries/grievances;
      </p>
      <p>
        <span class="Apple-converted-space">&nbsp; &nbsp; </span>&bull; Adjustments of amounts due on already realized
        reservations for accommodation and catering if there are grounds for such an action;
      </p>
      <p>
        <strong>&nbsp; B)</strong>&nbsp; In fulfillment of their legal obligations, Golden Sands JSC process personal
        data for the following purposes:
      </p>
      <p>
        <span class="Apple-converted-space">&nbsp; &nbsp; </span>&bull; Providing information to the Ministry of
        Interior, the competent municipality where the hotel is located, the Consumer Protection Commission and the
        Commission for Personal Data Protection, in connection with the fulfillment of our obligations as a hotelier,
        arising from the current regulations in this area;
      </p>
      <p>
        <span class="Apple-converted-space">&nbsp; &nbsp; </span>&bull; Processing of personal data in invoices issued
        in the name of a client for other purposes compatible with the original purpose of their collection
      </p>
      <p>
        <span class="Apple-converted-space">&nbsp; &nbsp; </span>&bull; Carrying out tax and social security control by
        the respective competent state bodies;
      </p>
      <p>
        <span class="Apple-converted-space">&nbsp; &nbsp; </span>&bull; Providing information to the court and third
        parties in court proceedings in order to protect their legitimate interests, including the collection of
        receivables from customers through court proceedings..
      </p>
      <p>
        <strong>&nbsp; C)</strong>&nbsp;"Golden Sands" AD processes personal data also for the purposes of our following
        legitimate interests:
      </p>
      <p>
        &nbsp;<span class="Apple-converted-space">&nbsp; &nbsp; </span>&bull; For direct marketing purposes ‒ sending
        offers containing information about our current offers, promotions and discounts for accommodation at the
        Admiral Hotel and/or changing the conditions of those already used;
      </p>
      <p>
        <span class="Apple-converted-space">&nbsp; &nbsp; </span>&bull; To include the guest's name, photographs, video
        and other data in advertising brochures, website etc. publications of
        <span class="Apple-converted-space">&nbsp; </span>Golden Sands JSC as a result of participation in group events
        and activities at the hotel organized by us (dances, sports activities and other entertainment).
      </p>
      <p>
        &bull; To protect, exercise or preserve the legal rights, integrity, safety or property of the administrator,
        users of the administrator's services and members of the public;
      </p>
      <div>
        <div>5. Categories of third parties that gain access and process personal data:</div>
        <br />
        <div>
          In connection with the implementation of our activities and fulfillment of our contractual commitments with
          our customers, we provide personal data to the following groups of persons hereunder called recipients,
          namely:
        </div>
        <br />
        <div>&nbsp; &bull; the data subject ‒ whenever they exercise this right;</div>
        <br />
        <div>&nbsp; &bull; the users/customers to whom the data relates;</div>
        <br />
        <div>
          &nbsp; &bull; to business partners &ndash; for the purposes of fulfilling the bookings made by the guests:
          travel agents and representatives in Bulgaria and abroad, transport companies and airlines, suppliers of the
          respective basic and additional tourist services, and other subcontractors with whom we have signed agreements
          thereof;
        </div>
        <br />
        <div>&nbsp; &bull; credit card payment service providers;</div>
        <br />
        <div>
          &nbsp; &bull; insurance companies when ordering an insurance event with a tourist accommodated in our hotel;
        </div>
        <br />
        <div>
          &nbsp; &bull; IT companies supporting information systems, our company website, software and platforms for
          managing the reservations of our clients, etc.;
        </div>
        <br />
        <div>
          &bull; public bodies (Ministry of Interior, municipality, NRA, NSSI, Consumer Protection Commission, Personal
          Data Protection Commission, judicial and other control bodies);
        </div>
        <br />
        <div>
          &nbsp; &bull; Other administrators of personal data to whom Golden Sands JSC provides personal data on a legal
          basis and/or on the basis of a bilaterally signed contract.
        </div>
        <br />
        <div>
          It may be necessary &ndash;&nbsp; by law, in litigation and /or at the request of public and governmental
          authorities in or outside the country of residence, as well as for the purposes of national security, law
          enforcement or other issues of public importance, that we disclose personal data when such disclosure is
          necessary or appropriate.
        </div>
        <br />
        <div>
          We may also disclose guest information if we find that such disclosure is justified and necessary for the
          application of our company terms and conditions or to protect our activities, legitimate interests and the
          rights and legitimate interests of other users. In addition, in the event of a reorganization, merger or sale,
          it is possible to transfer any and all of the collected database to the relevant successor third party.
        </div>
      </div>
      <br />
      <br />
      <div>
        <div>6.&nbsp; Period of storing personal data. Security:</div>
        <br />
        <div>
          The duration of storage of personal data depends on the purposes of processing for which they have been
          collected:
        </div>
        <br />
        <div>
          &nbsp; &bull; Personal data processed for the purpose of providing and performing tourist accommodation and
          catering services in the Admiral Hotel are stored for a period of up to 3 (three) years from the date of
          leaving the hotel, as well as until the final settlement of all financial issues between the parties, in
          observance of all normatively determined terms, whenever applicable;
        </div>
        <br />
        <div>
          &nbsp;&nbsp; &bull; Personal data processed for the purpose of issuing accounting/financial documents for tax
          and social security control, as well as but not only ‒ invoices, debit, credit notices, are stored for at
          least 3 (three) years, unless a longer term is stipulated by&nbsp; applicable law;
        </div>
        <br />
        <div>&nbsp; &bull; Picture (Video) ‒ up to 30 days from the creation of the recording.</div>
        <br />
        <div>
          It may be possible to store personal data for longer periods in order to protect the legitimate interests of
          Golden Sands JSC, as well as until the expiration of the relevant statute of limitations fоr protection
          purposes in case of claims from customers in connection with the implementation/termination of travel
          services, accommodation and meals in our hotels, as well as for a longer period in case of a lawsuit that has
          already been filed ‒ the documents are stored until its final resolution with a court/arbitration judgement
          that has entered into force.
        </div>
        <br />
        <div>
          As personal data administrator Golden Sands JSC takes due care and the appropriate and required by law
          administrative, technical and physical measures, as well as those related to staff&nbsp; /training, awareness,
          etc./ to protect the information at its disposal, including the protection of personal data of its customers
          from loss, theft and unauthorized use, disclosure, modification and any other illegal form of processing. We
          have physical, electronic and procedural safeguards that comply with our legal obligations regarding the
          protection of personal data, which we maintain in accordance with all contemporary technological means.
        </div>
        <br />
        <div>
          We are responsible for the protection of personal data of the client, which have became known to us in
          connection with our activities as an employer and hotelier, when providing our travel services specified in
          the subject of the General Terms and Conditions and this Policy, except in cases of force majeure, accidents
          or malicious actions from third parties, as well as in cases where the client himself has made this
          information available to third parties.
        </div>
        <br />
        <div>7. Video surveillance:</div>
        <br />
        <div>
          The common areas of the Admiral Hotel are subject to round‒the‒clock video surveillance, which is carried out
          through stationary security cameras located at the respective places.
        </div>
        <br />
        <div>
          The video surveillance is carried out in order to protect, exercise or preserve the legal rights,
          inviolability, safety or property of the administrator, his employees and/or contractors, as well as to ensure
          the safety, inviolability and safety of hotel guests and members of society.
        </div>
        <br />
        <div>
          The video surveillance is organized and controlled by employees of the administrator, specially trained for
          data protection.
        </div>
        <br />
        <div>
          The recordings are stored on video servers for a period of 30 days, except in case of registered violation of
          the rights of the administrator, tourists or third parties, in which case the term of keeping the recordings
          may be extended according to the specific needs.
        </div>
        <br />
        <div>
          The guest has the right to request a review of the records if he/she alleges there is a violation of rights
          relating to third parties or to a supervised person, and the request will be considered within the time limits
          provided in section 8 of this policy.
        </div>
        <br />
        <div>
          The Company may refuse to consider requests that are unreasonably repeated, require disproportionate technical
          effort or endanger the privacy of other users.
        </div>
        <br />
        <div>
          8. What are the rights of the guests relating to the processing of personal data by Golden Sands JSC and what
          actions should be taken in order to exercise those:
        </div>
        <br />
        <div>
          &nbsp;&nbsp; &bull; right of access to the data relating to them ‒ the client has the right at all times to
          request confirmation of whether the data relating to them are processed, information on the purposes of such
          processing, on the categories of data and on the recipients or categories of recipients , to which the data
          are disclosed;
        </div>
        <br />
        <div>
          &nbsp;&nbsp; &bull; the right to correct and update their personal data when they are inaccurate or incomplete
          in view of the purposes of their processing;
        </div>
        <br />
        <div>
          &nbsp; &bull; right to deletion (the right to be forgotten) when the subject's data are processed unlawfully
          or for no reason (the original purpose for which they were collected and processed has been fulfilled, the
          storage period has expired (including statute of limitations), consent for processing has been withdrawn, you
          have objected to their processing, etc.), there is no other reason for their processing or the national
          or&nbsp; European legislation requires it;
        </div>
        <div>
          &nbsp;&nbsp; &bull; right to restrict processing &ndash; in the event of a legal dispute between Golden Sandsa
          AD and the individual until its resolution, and/or for the establishment, exercise or defense of legal claims;
          where the processing is unlawful, but the data subject does not wish the personal data to be deleted, but
          instead requests the restriction of its use; in the event of an objection raised by the guest against the
          processing of personal data for the period of the verification of its validity;
        </div>
        <br />
        <div>
          &nbsp;&nbsp; &bull; right to data portability ‒ the data subject has the right to request us to transfer his
          personal data in a machine‒readable format to another controller explicitly indicated by him without
          hindrance;
        </div>
        <br />
        <div>
          &nbsp; &bull; notification obligation to in case of correction or deletion of personal data or restriction of
          processing ‒ the customer has the right to require us to notify the third parties to whom his personal data
          has been disclosed of any deletion, correction or blocking of this data, with the exception of the cases when
          this is impossible if it would require a disproportionate effort of Golden Sands JSC
        </div>
        <br />
        <div>
          &nbsp; &bull; the right to be notified of a breach of personal data security ‒ in cases where there is a
          possibility that the breach of data security will create a high risk to the rights and freedoms of natural
          persons. We are not obliged to notify individuals if: we have taken appropriate technical and organizational
          protection measures with respect to the data affected by the security breach, and if we have subsequently
          taken measures that ensure the breach will not result in a high risk to guests' rights and if notification
          would require a disproportionate effort.
        </div>
        <br />
        <div>
          &nbsp; &bull; right to object to the processing of personal data &ndash; at any time and on grounds related to
          the specific situation of the person, provided that there are no convincing legal grounds for the processing
          that take precedence over the interests, rights and freedoms of the data subject or a lawsuit.
        </div>
        <br />
        <div>
          &nbsp; &bull; When personal data is processed for the purposes of direct marketing, the user has the right at
          any time to object to the processing of personal data concerning them for this type of marketing, which
          includes profiling, as far as it is related to direct marketing. At the time of the first contact with the
          user at the latest, he is expressly informed of the existence of the right of objection described above, which
          is provided to him by notification in a clear way and separately from any other information.
        </div>
        <br />
        <div>
          &nbsp; &bull; right to legal and administrative protection &ndash; right to file a complaint with a
          supervisory authority, right to effective legal protection against a supervisory authority, right to effective
          legal protection against an administrator or processor of personal data; right to compensation for damages
          sincured.
        </div>
        <br />
        <div>Details of the supervisory authority:</div>
        <br />
        <div>COMMISSION FOR PERSONAL DATA PROTECTION</div>
        <br />
        <div>Sofia 1592, 2 Prof. Tsvetan Lazarov Blvd.</div>
        <br />
        <div>phone: 02/91‒53‒518, fax: 02/91‒53‒525,</div>
        <br />
        <div>e‒mail: kzld@cpdp.bg</div>
        <br />
        <div>www.cpp.bg</div>
        <br />
        <br />
      </div>
      <div>
        <div>
          9. Can a data subject refuse to provide personal data to Golden Sands JSC and what are the consequences of
          such reusal?
        </div>
        <br />
        <div>
          In order to fulfill the reservation made and to provide the accommodation services requested by the guests at
          the Admiral hotel, we need certain data, which are normatively determined by the legislation in force in the
          Republic of Bulgaria.
        </div>
        <br />
        <div>
          Failure to provide the personal data specified in item 4 prevents the possibility of Golden Sands JSC to
          accept the reservation and, accordingly, to provide the requested services on location at the Admiral hotel.
        </div>
        <br />

        <div>10. Our Cookie Policy:</div>
        <br />
        <div>
          Golden Sands JSC uses so‒called "cookies" on its website: www.sofiahotel.net, which are important for its
          correct operation. By visiting our site, visitors accept the use of cookies.
        </div>
        <br />
        <div>Types of cookies we use:</div>
        <br />
        <div>
          &nbsp; &bull; Mandatory cookies &ndash; these cookies are necessary for the correct operation of the website.
          For example, with these cookies we display the information on our site, photos, videos, etc., as well as help
          the search engine to function correctly so that you do not have to enter the same information on different
          pages. These cookies are temporary and are deleted when you close the browser.
        </div>
        <br />
        <div>
          &nbsp;&nbsp; &bull; Analytical cookies &ndash; thanks to these cookies we monitor the traffic of our site and
          can analyze how easily our users work with it (Google Analytics cookies). These cookies do not give us any
          personal data information. They show us which pages of our site have been viewed, whether our site has been
          accessed via a mobile or desktop device, and other anonymous data.
        </div>
        <br />
        <div>
          The cookie settings you receive from our site can be made in the browser you use. The restriction of certain
          types of cookies may prevent our site from working properly and you may not be able to use its full
          functionality.
        </div>
        <br />
        <div>11. Privacy Policy Changes:</div>
        <br />
        <div>
          Golden Sands JSC has the right, when the circumstances require it, to unilaterally update, amend and
          supplement the personal data protection policy at any time in the future. Any addition or change to this
          Policy will be published on the hotel website: www.admiral.bg and/or will be provided upon the client's
          request.
        </div>
        <br />
        <br />
      </div>
    </Fragment>
  );

  return (
    <Layouts>
      <div style={{ padding: "30px" }}>{lang === "bg" ? bulgarianPolicy : englishPolicy}</div>
    </Layouts>
  );
}
