import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Layouts from "../layouts/Layouts";

export default function HotelPolicy() {
  const { i18n } = useTranslation();
  const bulgarianPolicy = (
    <Fragment>
      <p className="center-text">
        <strong>
          Политика на хотел ,,Адмирал&rdquo; и условия за настаняване
        </strong>
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>Условия за настаняване</li>
      </ul>
      <p>&nbsp;</p>
      <p>Настаняване след 14:00 ч.</p>
      <p>Напускане преди 12:00 ч.</p>
      <p>Домашни любимци не се допускат в хотела.&nbsp;</p>
      <p>&nbsp;</p>
      <p>
        В случай, че хотелът не е в състояние да приеме госта след потвърдена
        резервация, хотелът се задължава да предостави алтернативно настаняване
        в хотел от същата категоризация, без промяна в условията на
        резервацията.
      </p>
      <p>&nbsp;</p>
      <p>
        Хотелът разполага с платен паркинг със 150 места в близост до рецепция.
      </p>
      <p>Резервация на паркомясто не е възможна.</p>
      <p>Паркоместата са до изчерпване на наличните свободни.</p>
      <p>
        В случай на пълна заетост, хотелът не се ангажира с осигуряване на
        свободно място за паркиране, но бихме съдействали с информация за други
        в близост.
      </p>
      <p>&nbsp;</p>
      <p>
        Хотелът разполага със собствен плаж и открит басейн.Всяка платена
        резервация включва използването на чадър и шезлонг на басейна, или на
        плажа(според наличността).В цената на двойна стая са включени два
        шезлонга и един чадър.
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>Условия за плащане</li>
      </ul>
      <p>
        В процеса на Вашата резервация, имате възможност да изберете начин на
        плащане.
      </p>
      <p>Плащане според условията на резервацията.</p>
      <p>
        Плащане с карта, като ако изберете този метод, вие трябва да платите
        пълната сума за настаняването.&nbsp;
      </p>
      <p>Плащане по банков път</p>
      <p>
        Плащанията по банков път трябва да бъдат постъпили до 5 дни от датата,
        която е направена резервацията. В противен случай резервацията се
        анулира.
      </p>
      <p>&nbsp;</p>
      <p>Банкови детайли в ЛЕВА:</p>
      <p>
        <strong>,,Златни пясъци&rdquo; АД</strong>
      </p>
      <p>
        <strong>Гр. Варна 9007, България</strong>
      </p>
      <p>
        <strong>К.к. Златни пясъци</strong>
      </p>
      <p>
        <strong>МОЛ: Кирил Олегов Кирилов</strong>
      </p>
      <p>
        <strong>ИН по ДДС&nbsp; BG813095472</strong>
      </p>
      <p>
        <strong>АСЕТ БАНК АД</strong>
      </p>
      <p>
        <strong>IBAN : BG44IABG74791000416503</strong>
      </p>
      <p>Основание за плащане: № на резервацията&hellip;..</p>
      <p>&nbsp;</p>
      <p>Банкови детайли в ЕВРО:</p>
      <p>
        <strong>,,</strong>
        <strong>Zlatni Piasatsi&rdquo; AD ,,Golden sands&rdquo; JSC)</strong>
      </p>
      <p>
        <strong>Varna 9007, Bulgaria</strong>
      </p>
      <p>
        <strong>Golden sands resort</strong>
      </p>
      <p>
        <strong>Kiril Olegov Kirilov</strong>
      </p>
      <p>
        <strong>VAT&nbsp; BG813095472</strong>
      </p>
      <p>
        <strong>INTERNATIONAL ASSET BANK AD</strong>
      </p>
      <p>
        <strong>IBAN: BG80IABG74791400416501</strong>
      </p>
      <p>
        <strong>SWIFT : IABGBGSF</strong>
      </p>
      <p>&nbsp;</p>
      <p>Основание за плащане: № на резервацията&hellip;..</p>
      <p>&nbsp;</p>
      <ul>
        <li>Условия за анулация</li>
      </ul>
      <p>
        При анулация на потвърдена резервация до 7 дни преди пристигане, не се
        дължи неустойка. При получено плащане от Ваша страна, се възстановява в
        срок от 7 работни дни, считано от датата на получената анулация.
      </p>
      <p>&nbsp;</p>
      <p>
        При анулация на резервация 6 или по-малко дни преди пристигане, се дължи
        неустойка в размер на 1 нощувка за всяка резервирана стая. Останалата
        сума се възстановява по сметката ви в срок от 7 работни дни, считано
        датата на получената анулация.
      </p>
      <p>&nbsp;</p>
      <p>
        В случай на неявяване, сумата по Вашата резервация няма да ви бъде
        възстановена.
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>Допълнителна информация:</li>
      </ul>
      <p>
        За допълнителни въпроси относно резервация или начин на плащане, може да
        се свържете с нас на имейл: reservations@admiral.bg или на телефон:
        <a className="hotel-policy-phone" href="tel:+35952390200">
          {" "}
          + 359 52 390200
        </a>{" "}
        /{" "}
        <a className="hotel-policy-phone" href="tel:+35952355252">
          +359 52 355 252
        </a>
      </p>
    </Fragment>
  );

  const englishPolicy = (
    <Fragment>
      <p className="center-text">
        <strong>Admiral Hotel Policy and Accommodation Conditions</strong>
      </p>
      <ul>
        <li>Accommodation</li>
      </ul>
      <p>&nbsp;</p>
      <p>Check-in after 2:00 p.m.</p>
      <p>Check out before 12:00 p.m.</p>
      <p>Pets are not allowed in the hotel.</p>
      <p>&nbsp;</p>
      <p>
        In the event that the hotel is unable to accept the guest after a
        confirmed reservation, the hotel undertakes to provide alternative
        accommodation in a hotel of the same category, without changing the
        conditions of the reservation.
      </p>
      <p>&nbsp;</p>
      <p>
        The hotel has a paid parking lot with 150 spaces in close proximity to
        the reception.
      </p>
      <p>
        Parking space booking is not possible.
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>
        Parking spaces are subject to availability. In case of full occupancy,
        the hotel is not responsible and does not undertake to provide a free
        parking space but may assist with information about others nearby.
      </p>
      <p>&nbsp;</p>
      <p>
        The hotel has its own beach and an outdoor pool. Every paid reservation
        includes the use of an umbrella and chaise-longue at the pool or on the
        beach (subject to availability). Two chaise-longues and one umbrella are
        included in the price of a double room.
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>
          Payment conditions<span class="Apple-converted-space">&nbsp;</span>
        </li>
      </ul>
      <p>
        In the process of making a reservation, you have the opportunity to
        choose the payment method.
      </p>
      <p>Payment according to the terms of the reservation.</p>
      <p>
        Bank card payment, and if you choose this method you must pay the full
        amount for the accommodation.
      </p>
      <p>Bank payment</p>
      <p>
        Bank payments must be received within 5 days of the date the
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p>reservation was made. Otherwise, the reservation is cancelled.</p>
      <p>&nbsp;</p>
      <p>Bank details in BGN:</p>
      <p>
        <strong>Zlatni Piasatsi AD</strong>
      </p>
      <p>
        <strong>Varna 9007, Bulgaria</strong>
      </p>
      <p>
        <strong>Golden Sands Resort</strong>
      </p>
      <p>
        <strong>Kiril Olegov Kirilov</strong>
      </p>
      <p>
        <strong>VAT&nbsp; BG813095472</strong>
      </p>
      <p>
        <strong>INTERNATIONAL ASSET BANK AD</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>IBAN : BG44IABG74791000416503</strong>
      </p>
      <p>Основание за плащане: № на резервацията&hellip;..</p>
      <p>&nbsp;</p>
      <p>Bank details in EURO:</p>
      <p>
        <strong>Zlatni Piasatsi AD/Golden sands JSC</strong>
      </p>
      <p>
        <strong>Varna 9007, Bulgaria</strong>
      </p>
      <p>
        <strong>Golden sands resort</strong>
      </p>
      <p>
        <strong>Kiril Olegov Kirilov</strong>
      </p>
      <p>
        <strong>VAT&nbsp; BG813095472</strong>
      </p>
      <p>
        <strong>INTERNATIONAL ASSET BANK AD</strong>
      </p>
      <p>
        <strong>IBAN: BG80IABG74791400416501</strong>
      </p>
      <p>
        <strong>SWIFT : IABGBGSF</strong>
      </p>
      <p>&nbsp;</p>
      <p>Grounds for payment: The number of the reservation</p>
      <p>&nbsp;</p>
      <ul>
        <li>Cancellations</li>
      </ul>
      <p>
        In the event of a cancellation of a confirmed reservation up to 7 days
        before arrival, no penalty is due. If payment has been made by you, such
        payment shall be refunded within 7 work days from the date of the
        received cancellation.
      </p>
      <p>&nbsp;</p>
      <p>
        Cancellations made 6 days or less prior to arrival incur a penalty of 1
        night for each room booked. The remaining amount is refunded to your
        account within 7 work days, counting from the date of the cancellation
        received.
      </p>
      <p>&nbsp;</p>
      <p>
        In the event of no-show, the amount of your reservation will not be
        refunded.
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>For more information:</li>
      </ul>
      <p>
        For additional questions regarding reservation or payment methods you
        can contact us by email at reservations@admiral.bg or by phone:
        <a className="hotel-policy-phone" href="tel:+35952390200">
          {" "}
          + 359 52 390200
        </a>{" "}
        /{" "}
        <a className="hotel-policy-phone" href="tel:+35952355252">
          +359 52 355 252
        </a>
      </p>
    </Fragment>
  );
  const lang = i18n.language;

  return (
    <Layouts>
      <div style={{ padding: "30px" }}>
        {lang === "bg" ? bulgarianPolicy : englishPolicy}
      </div>
    </Layouts>
  );
}
