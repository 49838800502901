import React from "react";
import loadingSpinner from "../assets/images/loading-spinner.gif";
import "../assets/css/LoadingSpinner.css";

export default function LoadingSpinner() {
  return (
    <div className="loading-spinner-container">
      <img className="loading-spinner" src={loadingSpinner} alt="" />
    </div>
  );
}
