const periods = {
  periodOne: ["2025-05-01 - 2025-05-21", "2024-09-22 - 2024-11-30"],
  periodTwo: ["2025-05-22 - 2025-06-09", "2025-09-16 - 2025-09-21"],
  periodThree: ["2025-06-10 - 2025-07-02", "2025-09-01 - 2025-09-15"],
  periodFour: ["2025-07-03 - 2025-08-31"],
  periodFive: ["2025-03-01 - 2025-04-30"],
  periodSix: ["2024-12-01 - 2024-12-28"],
  periodSeven: ["2025-01-03 - 2025-02-28"],
  periodEight: ["2024-12-29 - 2025-01-02"],
};

export default periods;
