import hotel1 from "../assets/images/gallery/hotel/hotel-1.jpg";
import hotel2 from "../assets/images/gallery/hotel/hotel-2.jpg";
import hotel3 from "../assets/images/gallery/hotel/hotel-3.jpg";
import hotel4 from "../assets/images/gallery/hotel/hotel-4.jpg";
import hotel5 from "../assets/images/gallery/hotel/hotel-5.jpg";
import hotel6 from "../assets/images/gallery/hotel/hotel-6.jpg";
import hotel7 from "../assets/images/gallery/hotel/hotel-7.jpg";
import hotel8 from "../assets/images/gallery/hotel/hotel-8.jpg";
import hotel9 from "../assets/images/gallery/hotel/hotel-9.jpg";
import hotel10 from "../assets/images/gallery/hotel/hotel-10.jpg";
import hotel11 from "../assets/images/gallery/hotel/hotel-11.jpg";

import rooms1 from "../assets/images/rooms/deluxe-double.jpg";
import rooms2 from "../assets/images/rooms/deluxe-double-2.jpg";
import rooms3 from "../assets/images/rooms/deluxe-double-4.jpg";
import rooms4 from "../assets/images/rooms/deluxe-double-5.jpg";
import rooms5 from "../assets/images/rooms/deluxe-double-6.jpg";
import rooms6 from "../assets/images/rooms/deluxe-superior.jpg";
import rooms7 from "../assets/images/rooms/deluxe-superior-3.jpg";
import rooms8 from "../assets/images/rooms/deluxe-superior-4.jpg";
import rooms9 from "../assets/images/rooms/deluxe-junior-suite.jpg";
import rooms10 from "../assets/images/rooms/deluxe-junior-suite-2.jpg";
import rooms11 from "../assets/images/rooms/deluxe-junior-suite-4.jpg";
import rooms12 from "../assets/images/rooms/deluxe-apartment.jpg";
import rooms13 from "../assets/images/rooms/deluxe-apartment-2.jpg";

// import confectionery1 from "../assets/images/gallery/confectionery/confectionery-1.jpg";
// import confectionery2 from "../assets/images/gallery/confectionery/confectionery-2.jpg";
// import confectionery3 from "../assets/images/gallery/confectionery/confectionery-3.jpg";
import confectionery4 from "../assets/images/gallery/confectionery/confectionery-4.jpg";
import confectionery5 from "../assets/images/gallery/confectionery/confectionery-5.jpg";
import confectionery6 from "../assets/images/gallery/confectionery/confectionery-6.jpg";
import confectionery7 from "../assets/images/gallery/confectionery/confectionery-7.jpg";
import confectionery8 from "../assets/images/gallery/confectionery/confectionery-8.jpg";
import confectionery9 from "../assets/images/gallery/confectionery/confectionery-9.jpg";

import asianRestaurant1 from "../assets/images/gallery/asian-restaurant/asian-1.jpg";
import asianRestaurant2 from "../assets/images/gallery/asian-restaurant/asian-2.jpg";
import asianRestaurant3 from "../assets/images/gallery/asian-restaurant/asian-3.jpg";
import asianRestaurant4 from "../assets/images/gallery/asian-restaurant/asian-4.jpg";
import asianRestaurant5 from "../assets/images/gallery/asian-restaurant/asian-5.jpg";
import asianRestaurant6 from "../assets/images/gallery/asian-restaurant/asian-6.jpg";
import asianRestaurant7 from "../assets/images/gallery/asian-restaurant/asian-7.jpg";

import azzurro1 from "../assets/images/gallery/azzurro/azzurro-1.jpg";
import azzurro2 from "../assets/images/gallery/azzurro/azzurro-2.jpg";
import azzurro3 from "../assets/images/gallery/azzurro/azzurro-3.jpg";
import azzurro4 from "../assets/images/gallery/azzurro/azzurro-4.jpg";
import azzurro5 from "../assets/images/gallery/azzurro/azzurro-5.jpg";
import azzurro6 from "../assets/images/gallery/azzurro/azzurro-6.jpg";
import azzurro7 from "../assets/images/gallery/azzurro/azzurro-7.jpg";
import azzurro8 from "../assets/images/gallery/azzurro/azzurro-8.jpg";
import azzurro9 from "../assets/images/gallery/azzurro/azzurro-9.jpg";
import azzurro10 from "../assets/images/gallery/azzurro/azzurro-10.jpg";
import azzurro11 from "../assets/images/gallery/azzurro/azzurro-11.jpg";
import azzurro12 from "../assets/images/gallery/azzurro/azzurro-12.jpg";
import azzurro13 from "../assets/images/gallery/azzurro/azzurro-13.jpg";
import azzurro14 from "../assets/images/gallery/azzurro/azzurro-14.jpg";
import azzurro15 from "../assets/images/gallery/azzurro/azzurro-15.jpg";
import azzurro16 from "../assets/images/gallery/azzurro/azzurro-16.jpg";
import azzurro17 from "../assets/images/gallery/azzurro/azzurro-17.jpg";
import azzurro18 from "../assets/images/gallery/azzurro/azzurro-18.jpg";
import azzurro19 from "../assets/images/gallery/azzurro/azzurro-19.jpg";
import azzurro20 from "../assets/images/gallery/azzurro/azzurro-20.jpg";
import azzurro21 from "../assets/images/gallery/azzurro/azzurro-21.jpg";
import azzurro22 from "../assets/images/gallery/azzurro/azzurro-22.jpg";
import azzurro23 from "../assets/images/gallery/azzurro/azzurro-23.jpg";
import azzurro24 from "../assets/images/gallery/azzurro/azzurro-24.jpg";
import azzurro25 from "../assets/images/gallery/azzurro/azzurro-25.jpg";
import azzurro26 from "../assets/images/gallery/azzurro/azzurro-26.jpg";

import bar1 from "../assets/images/gallery/bar/bar-1.jpg";
import bar2 from "../assets/images/gallery/bar/bar-2.jpg";
import bar3 from "../assets/images/gallery/bar/bar-3.jpg";
import bar4 from "../assets/images/gallery/bar/bar-4.jpg";
import bar5 from "../assets/images/gallery/bar/bar-5.jpg";
import bar6 from "../assets/images/gallery/bar/bar-6.jpg";
import bar7 from "../assets/images/gallery/bar/bar-7.jpg";
import bar8 from "../assets/images/gallery/bar/bar-8.jpg";
import bar9 from "../assets/images/gallery/bar/bar-9.jpg";
import bar10 from "../assets/images/gallery/bar/bar-10.jpg";
import bar11 from "../assets/images/gallery/bar/bar-11.jpg";
import bar12 from "../assets/images/gallery/bar/bar-12.jpg";
import bar13 from "../assets/images/gallery/bar/bar-13.jpg";
import bar14 from "../assets/images/gallery/bar/bar-14.jpg";
import bar15 from "../assets/images/gallery/bar/bar-15.jpg";
import bar16 from "../assets/images/gallery/bar/bar-16.jpg";
import bar17 from "../assets/images/gallery/bar/bar-17.jpg";
import bar18 from "../assets/images/gallery/bar/bar-18.jpg";
import bar19 from "../assets/images/gallery/bar/bar-19.jpg";

import beach1 from "../assets/images/gallery/beach/beach-1.jpg";
import beach2 from "../assets/images/gallery/beach/beach-2.jpg";
import beach3 from "../assets/images/gallery/beach/beach-3.jpg";
import beach4 from "../assets/images/gallery/beach/beach-4.jpg";
import beach5 from "../assets/images/gallery/beach/beach-5.jpg";
import beach6 from "../assets/images/gallery/beach/beach-6.jpg";
import beach7 from "../assets/images/gallery/beach/beach-7.jpg";
import beach8 from "../assets/images/gallery/beach/beach-8.jpg";
import beach9 from "../assets/images/gallery/beach/beach-9.jpg";
import beach10 from "../assets/images/gallery/beach/beach-10.jpg";
import beach11 from "../assets/images/gallery/beach/beach-11.jpg";
import beach12 from "../assets/images/gallery/beach/beach-12.jpg";

// import spa1 from "../assets/images/gallery/spa/spa-1.jpg";
// import spa2 from "../assets/images/gallery/spa/spa-2.jpg";
// import spa3 from "../assets/images/gallery/spa/spa-3.jpg";
// import spa4 from "../assets/images/gallery/spa/spa-4.jpg";
// import spa5 from "../assets/images/gallery/spa/spa-5.jpg";
// import spa6 from "../assets/images/gallery/spa/spa-6.jpg";
// import spa7 from "../assets/images/gallery/spa/spa-7.jpg";
// import spa8 from "../assets/images/gallery/spa/spa-8.jpg";
// import spa9 from "../assets/images/gallery/spa/spa-9.jpg";
// import spa10 from "../assets/images/gallery/spa/spa-10.jpg";
// import spa11 from "../assets/images/gallery/spa/spa-11.jpg";
// import spa12 from "../assets/images/gallery/spa/spa-12.jpg";
// import spa13 from "../assets/images/gallery/spa/spa-13.jpg";
// import spa14 from "../assets/images/gallery/spa/spa-14.jpg";
// import spa15 from "../assets/images/gallery/spa/spa-15.jpg";
// import spa16 from "../assets/images/gallery/spa/spa-16.jpg";
// import spa17 from "../assets/images/gallery/spa/spa-17.jpg";
// import spa18 from "../assets/images/gallery/spa/spa-18.jpg";
// import spa19 from "../assets/images/gallery/spa/spa-19.jpg";
// import spa20 from "../assets/images/gallery/spa/spa-20.jpg";
// import spa21 from "../assets/images/gallery/spa/spa-21.jpg";
// import spa22 from "../assets/images/gallery/spa/spa-22.jpg";
// import spa23 from "../assets/images/gallery/spa/spa-23.jpg";
// import spa24 from "../assets/images/gallery/spa/spa-24.jpg";
// import spa25 from "../assets/images/gallery/spa/spa-25.jpg";
// import spa26 from "../assets/images/gallery/spa/spa-26.jpg";
// import spa27 from "../assets/images/gallery/spa/spa-27.jpg";
// import spa28 from "../assets/images/gallery/spa/spa-28.jpg";
// import spa29 from "../assets/images/gallery/spa/spa-29.jpg";
// import spa30 from "../assets/images/gallery/spa/spa-30.jpg";
// import spa31 from "../assets/images/gallery/spa/spa-31.jpg";
// import spa32 from "../assets/images/gallery/spa/spa-32.jpg";

import spa1 from "../assets/images/gallery/spa/spa-new-1.jpg";
import spa2 from "../assets/images/gallery/spa/spa-new-2.jpg";
import spa3 from "../assets/images/gallery/spa/spa-new-3.jpg";
import spa4 from "../assets/images/gallery/spa/spa-new-4.jpg";
import spa5 from "../assets/images/gallery/spa/spa-new-5.jpg";
import spa6 from "../assets/images/gallery/spa/spa-new-6.jpg";
import spa7 from "../assets/images/gallery/spa/spa-new-7.jpg";
import spa8 from "../assets/images/gallery/spa/spa-new-8.jpg";
import spa9 from "../assets/images/gallery/spa/spa-new-9.jpg";
import spa10 from "../assets/images/gallery/spa/spa-new-10.jpg";
import spa11 from "../assets/images/gallery/spa/spa-new-11.jpg";
import spa12 from "../assets/images/gallery/spa/spa-new-12.jpg";
import spa13 from "../assets/images/gallery/spa/spa-new-13.jpg";
import spa14 from "../assets/images/gallery/spa/spa-new-14.jpg";
import spa15 from "../assets/images/gallery/spa/spa-new-15.jpg";
import spa16 from "../assets/images/gallery/spa/spa-new-16.jpg";
import spa17 from "../assets/images/gallery/spa/spa-new-17.jpg";
import spa18 from "../assets/images/gallery/spa/spa-new-18.jpg";
import spa19 from "../assets/images/gallery/spa/spa-new-19.jpg";
import spa20 from "../assets/images/gallery/spa/spa-new-20.jpg";
import spa21 from "../assets/images/gallery/spa/spa-new-21.jpg";
import spa22 from "../assets/images/gallery/spa/spa-new-22.jpg";

const hotelImages = [
  hotel1,
  hotel2,
  hotel3,
  hotel4,
  hotel5,
  hotel6,
  hotel7,
  hotel8,
  hotel9,
  hotel10,
  hotel11,
];

const roomsImages = [
  rooms1,
  rooms2,
  rooms3,
  rooms4,
  rooms5,
  rooms6,
  rooms7,
  rooms8,
  rooms9,
  rooms10,
  rooms11,
  rooms12,
  rooms13,
];

const confectioneryImages = [
  // confectionery1,
  // confectionery2,
  // confectionery3,
  confectionery4,
  confectionery5,
  confectionery6,
  confectionery7,
  confectionery8,
  confectionery9,
];

const asianRestaurantImages = [
  asianRestaurant1,
  asianRestaurant2,
  asianRestaurant3,
  asianRestaurant4,
  asianRestaurant5,
  asianRestaurant6,
  asianRestaurant7,
];

const azzurroImages = [
  azzurro1,
  azzurro2,
  azzurro3,
  azzurro4,
  azzurro5,
  azzurro6,
  azzurro7,
  azzurro8,
  azzurro9,
  azzurro10,
  azzurro11,
  azzurro12,
  azzurro13,
  azzurro14,
  azzurro15,
  azzurro16,
  azzurro17,
  azzurro18,
  azzurro19,
  azzurro20,
  azzurro21,
  azzurro22,
  azzurro23,
  azzurro24,
  azzurro25,
  azzurro26,
];

const barImages = [
  bar1,
  bar2,
  bar3,
  bar4,
  bar5,
  bar6,
  bar7,
  bar8,
  bar9,
  bar10,
  bar11,
  bar12,
  bar13,
  bar14,
  bar15,
  bar16,
  bar17,
  bar18,
  bar19,
];

const beachImages = [
  beach1,
  beach2,
  beach3,
  beach4,
  beach5,
  beach6,
  beach7,
  beach8,
  beach9,
  beach10,
  beach11,
  beach12,
];

const spaImages = [
  spa1,
  spa2,
  spa3,
  spa4,
  spa5,
  spa6,
  spa7,
  spa8,
  spa9,
  spa10,
  spa11,
  spa12,
  spa13,
  spa14,
  spa15,
  spa16,
  spa17,
  spa18,
  spa19,
  spa20,
  spa21,
  spa22,
  // spa23,
  // spa24,
  // spa25,
  // spa26,
  // spa27,
  // spa28,
  // spa29,
  // spa30,
  // spa31,
  // spa32,
];

const imagesObj = {
  hotelImages,
  roomsImages,
  confectioneryImages,
  asianRestaurantImages,
  azzurroImages,
  barImages,
  beachImages,
  spaImages,
};

export default imagesObj;
