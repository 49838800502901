import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import {
  getDateDB,
  getDateView,
  sevenDaysBeforeBooking,
} from "../../utils/date";
import RoomInfo from "../../utils/RoomInfo";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import bookingsSVC from "../../services/bookingsService";
import XMLReportSVC from "../../services/XMLReportService";

import "./style.css";
// import Button from "../../components/Button";
// import { toast } from "react-toastify";

const oldRooms = {
  "double-standard-park": {
    name: "",
    view: "park",
    size: 23,
    bed: "",
    adults: 2,
    children: 1,
    roomKey: "double-standard-park",
  },
  "double-standard-sea": {
    name: "",
    view: "sea",
    size: 23,
    bed: "",
    adults: 2,
    children: 1,
    roomKey: "double-standard-sea",
  },
  "double-standard-sea-superior": {
    name: "",
    view: "sea",
    size: 40,
    bed: "",
    adults: 2,
    children: 2,
    roomKey: "double-standard-sea-superior",
  },
  "junior-suit": {
    name: "",
    view: "sea",
    size: 57,
    bed: "",
    adults: 2,
    children: 2,
    roomKey: "junior-suit",
  },
};

export default function AdminReservations() {
  const { t } = useTranslation();
  const [bookings, setBookings] = useState();
  const [shownBookings, setShownBookings] = useState();
  const [showArchived, setShowArchived] = useState(false);

  const [reportMonth, setReportMonth] = useState({ label: "Януари", value: 0 });
  const [reportYear, setReportYear] = useState({ label: "2022", value: 2022 });

  useEffect(() => {
    bookingsSVC.getBookings().then((res) => {
      if (res.status === 200) {
        const reservations = res.data.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );
        setBookings(reservations);
        setShownBookings(
          reservations.filter((x) => {
            const today = new Date().getTime();
            const endDate = new Date(x.endDate).getTime();
            return endDate >= today;
          })
        );
      }
    });
  }, []);

  useEffect(() => {
    const today = new Date().getTime();
    if (bookings) {
      if (showArchived) {
        setShownBookings(
          bookings.filter((x) => {
            const endDate = new Date(x.endDate).getTime();
            return endDate < today;
          })
        );
      } else {
        // console.log(bookings);
        setShownBookings(
          bookings.filter((x) => {
            const endDate = new Date(x.endDate).getTime();
            return endDate >= today;
          })
        );
      }
    }
  }, [showArchived, bookings]);

  const getRoomName = (room) => {
    const allRooms = Object.assign(RoomInfo, oldRooms);
    const roomObject = Object.keys(allRooms).find((x) => x === room.roomName);
    return `${t(allRooms[roomObject].roomKey)} - ${room.count}бр.`;
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case "scheduled":
        return { backgroundColor: "#00BDEB" };
      case "paid":
        return { backgroundColor: "var(--color-green-1)" };
      case "unsuccessful":
        return {
          backgroundColor: "var(--toastify-color-error)",
          color: "black",
        };
      case "cancelled":
        return { backgroundColor: "var(--color-red-1)" };
      case "pending":
        return { backgroundColor: "lightblue" };
      case "pending-scheduled":
        return { backgroundColor: "lightblue" };
      case "reqForCancellation":
        return { backgroundColor: "orange" };
      default:
        break;
    }
  };

  const getStatusText = (status, scheduleDate) => {
    switch (status) {
      case "paid":
        return "Платена";
      case "unsuccessful":
        return "Неуспешна";
      case "scheduled":
        return `Насрочена за плащане на ${getDateView(
          getDateDB(sevenDaysBeforeBooking(scheduleDate))
        )}`;
      case "cancelled":
        return "Анулирана";
      case "pending":
        return "Изчакваща";
      case "pending-scheduled":
        return "Изисква се 3D верификация";
      case "reqForCancellation":
        return "Заявена за анулиране";
      default:
        return "";
    }
  };

  // const getBoardAbbr = (board) => {
  //   switch (board) {
  //     case "bed-and-breakfast":
  //       return "BB";
  //     case "half-board":
  //       return "HB";
  //     case "full-board":
  //       return "FB";
  //     case "all-light":
  //       return "ALL";
  //     default:
  //       return "-";
  //   }
  // };

  const onGenerateReport = async () => {
    const XMLString = await XMLReportSVC.generate({
      month: reportMonth.value,
      year: reportYear.value,
    });

    // Download the file
    const blob = new Blob([XMLString]);
    const url = URL.createObjectURL(blob);

    let a = document.createElement("A");
    a.href = url;
    a.download = `${reportMonth.label}_${reportYear.label}_report.xml`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  // const handleArchive = (ref, visibility) => {
  //   bookingsSVC.changeVisiblity(ref, visibility).then((res) => {
  //     if (res.status === 200) {
  //       const bookingsCopy = [...bookings];
  //       const index = bookingsCopy.findIndex((x) => x.bookingReference === ref);
  //       bookingsCopy[index].archived = visibility === "archive" ? true : false;

  //       setBookings(bookingsCopy);

  //       setShownBookings((prev) =>
  //         prev.filter((x) => x.bookingReference !== ref)
  //       );
  //       toast.success("Видимостта на резервацията беше променена успешно!");
  //     }
  //   });
  // };

  const renderReservation = (reservation, index) => {
    return (
      <tr key={index}>
        <td>{getDateView(getDateDB(reservation.date))}</td>
        <td>{reservation.bookingReference}</td>
        <td>{reservation.name}</td>
        <td>{reservation.email}</td>
        <td>{reservation.phone}</td>
        <td>{getDateView(getDateDB(reservation.startDate))}</td>
        <td>{getDateView(getDateDB(reservation.endDate))}</td>
        <td>
          {reservation.roomType
            .map((x) => getRoomName(x))
            .map((x, index2) => (
              <React.Fragment key={index2}>
                {x}
                <br />
              </React.Fragment>
            ))}
        </td>
        <td>{reservation.adults}</td>
        <td>{reservation.children}</td>
        <td>ALL</td>
        <td>{reservation.price} лв.</td>
        <td>{reservation.voucherUsed ? reservation.voucherUsed.name : "-"}</td>
        <td style={getStatusStyle(reservation.status)}>
          {getStatusText(reservation.status, reservation.startDate)}
        </td>
        <td>
          {reservation.status === "paid" ? (
            <a target="_blank" rel="noreferrer" href={reservation.receipt}>
              Виж
            </a>
          ) : (
            "-"
          )}
        </td>
        {/* <td>
          <Button
            text={reservation.archived ? "Възстанови" : "Архивирай"}
            onClick={() => {
              handleArchive(
                reservation.bookingReference,
                reservation.archived ? "unarchive" : "archive"
              );
            }}
          />
        </td> */}
      </tr>
    );
  };

  return (
    <div>
      <Navbar />
      <div className="reservations-page">
        <div className="report-wrapper">
          <div className="admin-room-page__select-wrapper">
            <h5>Месец</h5>
            <Select
              options={[
                { label: "Януари", value: 0 },
                { label: "Февруари", value: 1 },
                { label: "Март", value: 2 },
                { label: "Април", value: 3 },
                { label: "Май", value: 4 },
                { label: "Юни", value: 5 },
                { label: "Юли", value: 6 },
                { label: "Август", value: 7 },
                { label: "Септември", value: 8 },
                { label: "Октомври", value: 9 },
                { label: "Ноември", value: 10 },
                { label: "Декември", value: 11 },
              ]}
              onChange={(opt) => setReportMonth(opt)}
              defaultValue={{ label: "Януари", value: 0 }}
            />
          </div>
          <div className="admin-room-page__select-wrapper">
            <h5>Година</h5>
            <Select
              options={[
                { label: "2022", value: 2022 },
                { label: "2023", value: 2023 },
                { label: "2024", value: 2024 },
                { label: "2025", value: 2025 },
                { label: "2026", value: 2026 },
                { label: "2027", value: 2027 },
                { label: "2028", value: 2028 },
                { label: "2029", value: 2029 },
                { label: "2030", value: 2030 },
              ]}
              onChange={(opt) => setReportYear(opt)}
              defaultValue={{ label: "2022", value: 2022 }}
            />
          </div>
          <button
            className="main-button admin-room-page__submit-button"
            onClick={() => onGenerateReport()}
          >
            Генерирай Репорт
          </button>
        </div>

        <h3 className="center-text">Резервации</h3>
        <div className="reservation-tabs-wrapper">
          <div
            className={`reservation-tab ${
              !showArchived ? "reservation-tab--active" : ""
            }`}
            onClick={() => setShowArchived(false)}
          >
            <h4>Текущи</h4>
          </div>
          <div
            className={`reservation-tab ${
              showArchived ? "reservation-tab--active" : ""
            }`}
            onClick={() => setShowArchived(true)}
          >
            <h4>Архив</h4>
          </div>
        </div>
        <table className="reservations-table">
          <thead>
            <tr>
              <th>Дата</th>
              <th>Реф. номер</th>
              <th>Име</th>
              <th>Имейл</th>
              <th>Телефон</th>
              <th>Начална дата</th>
              <th>Крайна дата</th>
              <th>Тип стая</th>
              <th>Възрастни</th>
              <th>Деца</th>
              <th>Изхранване</th>
              <th>Цена</th>
              <th>Ваучер</th>
              <th>Статус</th>
              <th>Разписка</th>
              {/* <th>-</th> */}
            </tr>
          </thead>
          <tbody>
            {shownBookings &&
              shownBookings.map((reservation, index) => {
                return renderReservation(reservation, index);
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
