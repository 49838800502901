import React, { useState } from "react";
import PageBanner from "../components/PageBanner";
import Layouts from "../layouts/Layouts";
import { useTranslation } from "react-i18next";
import images from "../utils/galleryImages";
import GalleryFilter, {
  FullScreenGallery,
  GalleryContent,
} from "../components/GalleryFilter";

const allImages = [
  ...images.hotelImages,
  ...images.roomsImages,
  ...images.confectioneryImages,
  ...images.asianRestaurantImages,
  ...images.azzurroImages,
  ...images.barImages,
  ...images.beachImages,
  ...images.spaImages,
];

const items = {
  1: allImages,
  2: [...images.hotelImages],
  3: [...images.roomsImages],
  4: [...images.confectioneryImages],
  5: [...images.asianRestaurantImages],
  6: [...images.azzurroImages],
  7: [...images.barImages],
  8: [...images.beachImages],
  9: [...images.spaImages],
};

const Gallery = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("1");
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedTabImages, setSelectedTabImages] = useState(
    items[activeTab] || []
  );

  const handleClick = (tabId) => {
    setActiveTab(tabId);
    setSelectedTabImages(items[tabId] || []);
  };

  const selectImage = (image) => setSelectedImage(image);

  const renderItem = (img) => (
    <div className="image" key={img}>
      <img src={img} alt="" onClick={() => selectImage(img)} />
    </div>
  );

  const tabs = [
    "gallery_all",
    "gallery_hotel",
    "gallery_rooms",
    "gallery_wiener_cafe",
    "gallery_asian_restaurant",
    "gallery_azzurro",
    "gallerry_piano_bar",
    "gallerry_pool_bar_and_beach",
    "gallerry_fitness_and_spa",
  ];

  return (
    <Layouts>
      <PageBanner pageName={t("gallery")} />
      <GalleryFilter {...{ tabs, activeTab, handleClick }} />
      <GalleryContent {...{ items, activeTab, renderItem, selectImage }} />
      {selectedImage !== "" && (
        <FullScreenGallery
          images={[
            selectedImage,
            ...selectedTabImages.filter((image) => image !== selectedImage),
          ]}
          onClose={() => setSelectedImage("")}
        />
      )}
    </Layouts>
  );
};

export default Gallery;
