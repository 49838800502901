import http from "./httpService";
import config from "../config/config.json";
const apiUrl = config.apiUrl;
const apiEndpoint = apiUrl + "/auth";
const tokenKey = "jwt";

http.setJwt(getJwt());

const login = async (email, password) => {
  const { data: jwt } = await http.post(`${apiEndpoint}`, {
    email,
    password,
  });
  localStorage.setItem(tokenKey, jwt);
  http.setJwt(getJwt());
};

/**
 * used to login a user, specialist, admin by jwt token
 * if successful sets the received jwt token to the local storage
 *
 * @param {string} jwt the jwt token from the local storage
 */
function loginWithJwt(jwt) {
  localStorage.setItem(tokenKey, jwt);
  http.setJwt(getJwt());
}

/**
 * gets the jwt token from the local storage
 *
 * @returns {string | null} the jwt token or null if it does not exists in the local storage
 */
function getJwt() {
  return localStorage.getItem(tokenKey);
}

const exportedFunctions = {
  login,
  loginWithJwt,
  getJwt,
};

export default exportedFunctions;
