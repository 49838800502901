import http from "./httpService";
import config from "../config/config.json";

const { apiUrl, fromEmail } = config;

const apiEndpoint = apiUrl + "/emails";

const sendEmail = async (data) => {
  const senderEmail = fromEmail;
  const from = fromEmail;
  const body = {
    ...data,
    fromEmail: senderEmail,
    from,
  };
  return await http.post(apiEndpoint + "/send", body);
};

const exportedFunctions = {
  sendEmail,
};

export default exportedFunctions;
