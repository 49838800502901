import http from "./httpService";
import config from "../config/config.json";
const apiUrl = config.apiUrl;

const apiEndpoint = apiUrl + "/vouchers";

const getAllVouchers = async () => {
  return await http.get(apiEndpoint);
};

const getVoucher = async (voucher, roomTypes) => {
  return await http.put(`${apiEndpoint}/getVoucher`, {
    voucher,
    roomTypes,
  });
};

const getVoucherById = async (id) => {
  return await http.get(`${apiEndpoint}/voucherById/${id}`);
};

const createVoucher = async (data) => {
  const voucher = await http.post(apiEndpoint, data);
  return voucher;
};

const deleteVoucher = async (id) => {
  const voucher = await http.put(`${apiEndpoint}/delete/${id}`);
  return voucher;
};

const exportedFunctions = {
  createVoucher,
  getVoucher,
  getAllVouchers,
  deleteVoucher,
  getVoucherById,
};

export default exportedFunctions;
