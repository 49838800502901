import React from "react";
import PageBanner from "../components/PageBanner";
import Layouts from "../layouts/Layouts";
import { useTranslation } from "react-i18next";

import parties1 from "../assets/images/parties1.jpg";
import conferences1 from "../assets/images/conferences1.jpg";
import conferences2 from "../assets/images/conferences2.jpg";

const Events = () => {
  const { t } = useTranslation();

  return (
    <Layouts>
      <PageBanner pageName={t("events")} />
      {/* section fourty eight */}
      <section className="section-fourty-eight">
        <div className="auto-container">
          <div className="row">
            <div className="col-lg-6">
              <div className="block-fourty-eight">
                {/* <div className="sub-title">Eat &amp; Drinks</div> */}
                <h2 className="sec-title mb-30">{t("parties")}</h2>
                <div className="text">{t("parties-text-1")}</div>
                <div className="text-two">{t("parties-text-2")}</div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="block-fourty-nine">
                <div className="image">
                  <img src={parties1} alt="" />
                </div>
                {/* <div className="content-box">
                  <div className="text-three">On Platter</div>
                  <div className="pricing">
                    -50<span>%</span>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-fourty-eight end">
        <div className="auto-container">
          <div className="row">
            <div className="col-lg-6">
              <div className="block-fourty-nine">
                <div className="image leading-image">
                  <img src={conferences1} alt="" />
                </div>
                <div
                  className="image leading-image"
                  style={{ paddingTop: "20px" }}
                >
                  <img src={conferences2} alt="" />
                </div>
                {/* <div className="content-box">
                  <div className="text-three">On Platter</div>
                  <div className="pricing">
                    -50<span>%</span>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="block-fourty-eight">
                {/* <div className="sub-title">Eat &amp; Drinks</div> */}
                <h2 className="sec-title mb-30">{t("conferences")}</h2>
                <div className="text">{t("conferences-text-1")}</div>
                <div className="text-two">{t("conferences-text-2")}</div>
                <div className="text-two">{t("conferences-text-3")}</div>
                <div className="text-two">{t("conferences-text-4")}</div>
              </div>
            </div>
            <div className="image mobile-image">
              <img src={conferences1} alt="" />
            </div>
            <div className="image mobile-image" style={{ paddingTop: "20px" }}>
              <img src={conferences2} alt="" />
            </div>
          </div>
        </div>
      </section>
    </Layouts>
  );
};
export default Events;
