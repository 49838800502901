import React from "react";
import sprite from "../../assets/sprite.svg";
import classNames from "classnames";

export const Icon = ({ classes, icon, onClick }) => {
  return (
    <svg className={`${classNames(classes)}`} style={{ width: '4rem', height: '4rem' }} onClick={onClick}>
      <use href={`${sprite}#${icon}`}></use>
    </svg>
  );
};
