import React, { useState, useEffect, Fragment } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
// import ReactSelect from "react-select";

import Layouts from "../../layouts/Layouts";
import LineSeparator from "../../components/LineSeparator";
import Button from "../../components/Button";
import PaymentForm from "../../components/PaymentForm";

import useWindowDimensions from "../../utils/useWindowsDimensions";
import { getDateDB, getDateView, getDateZeroHours } from "../../utils/date";
import { boardPrices } from "../../utils/RoomInfo";
import config from "../../config/config.json";
import voucherSVC from "../../services/vouchersService";

import "./style.css";

const stripePublicKey = config.stripePublicKey;
const stripePromise = loadStripe(stripePublicKey);

export default function PaymentPage() {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const { width } = useWindowDimensions();

  const data = location.state.bookingData;
  const roomsPrices = location.state.roomsPrices;
  const bookedDays = location.state.bookedDays;

  const [bookingData, setBookingdata] = useState({ ...data });
  const [voucher, setVoucher] = useState("");
  const [voucherError, setVoucherError] = useState(false);
  const [validVoucherUsed, setValidVoucherUsed] = useState();
  // const [lastFoodBoardPrice, setLastFoodBoardPrice] = useState();
  // const [selectedFoodBoard, setSelectedFoodBoard] = useState();

  // const foodBoardOptions = Object.keys(boardPrices).map((x) => {
  //   const labelPrice = boardPrices[x] ? ` - ${boardPrices[x]}${t("bgn")}` : "";
  //   return { label: `${t(x)}${labelPrice}`, value: x };
  // });

  useEffect(() => {
    window.scrollTo(10, 0);
  }, []);

  useEffect(() => {
    if (data) {
      const dataCopy = { ...data };
      setBookingdata({ ...dataCopy });
    }
  }, [data]);

  const validateVoucher = async (e) => {
    e.preventDefault();
    if (!voucher) {
      setVoucherError(t("not-found"));
      return;
    }
    try {
      const res = await voucherSVC.getVoucher(voucher, bookingData.rooms);
      if (res.status === 200) {
        let dataCopy = { ...bookingData };
        const voucherData = res.data.voucher;
        const voucherStartDate = getDateZeroHours(
          new Date(voucherData.startDate)
        );
        const voucherEndDate = getDateZeroHours(new Date(voucherData.endDate));
        const voucherMinNights = voucherData.minNights;

        if (bookedDays.length - 1 < voucherMinNights) {
          setVoucherError(
            t("voucher-min-nights", { minNights: voucherMinNights })
          );
          return;
        }

        let priceAfterDiscount = 0;
        let foodBoardPrice = 0;
        let foodBoardPriceDiscounted = 0;
        let totalFoodBoardPrice = 0;

        const validRooms = res.data.rooms;
        const discountPercentage = res.data.voucher.discount / 100;
        console.log(discountPercentage, "discount");
        // If there is a food board selected - calculate both the normal
        // price and the discounted price, and then use them when needed
        if (dataCopy.foodBoard) {
          foodBoardPrice = boardPrices[dataCopy.foodBoard];
          foodBoardPriceDiscounted =
            foodBoardPrice - foodBoardPrice * discountPercentage;
        }

        for (let i = 0; i < dataCopy.rooms.length; i++) {
          const currentRoom = dataCopy.rooms[i];
          const currentRoomCount = currentRoom.count;
          const currentRoomDaysInPeriod = currentRoom.daysInPeriods;
          let previousSlicedDayIndex = 0;

          // eslint-disable-next-line no-loop-func
          Object.keys(currentRoomDaysInPeriod).forEach((periodKey) => {
            const daysInCurrentPeriod = bookedDays.slice(
              previousSlicedDayIndex,
              currentRoomDaysInPeriod[periodKey] + previousSlicedDayIndex
            );
            previousSlicedDayIndex = currentRoomDaysInPeriod[periodKey];
            const priceForDay = roomsPrices[currentRoom.roomName][periodKey];

            daysInCurrentPeriod.forEach((day) => {
              const currentDay = getDateZeroHours(new Date(day));

              // If the current day falls into the voucher period
              // apply the discounted prices
              if (
                currentDay.getTime() >= voucherStartDate.getTime() &&
                currentDay.getTime() <= voucherEndDate.getTime()
              ) {
                if (validRooms.includes(currentRoom.roomName)) {
                  const priceForDayAfterDiscount =
                    priceForDay - priceForDay * discountPercentage;
                  const totalPriceForDay = priceForDayAfterDiscount;
                  const totalPriceForDayMultiplied =
                    totalPriceForDay * currentRoomCount;
                  priceAfterDiscount += totalPriceForDayMultiplied;
                } else {
                  priceAfterDiscount += priceForDay * currentRoomCount;
                }

                // When looping through the booked rooms - add the food board price
                // only on the first iteration !!!
                if (i === 0) {
                  totalFoodBoardPrice += foodBoardPriceDiscounted;
                }
              } else {
                const totalPriceForDay = priceForDay * currentRoomCount;
                priceAfterDiscount += totalPriceForDay;

                if (i === 0) {
                  totalFoodBoardPrice += foodBoardPrice;
                }
              }
            });
          });
        }

        toast.success(t("voucher-success"));
        totalFoodBoardPrice = totalFoodBoardPrice * dataCopy.adults.value;
        dataCopy.price = Number(
          priceAfterDiscount + totalFoodBoardPrice
        ).toFixed(2);
        dataCopy.price = Number(dataCopy.price);

        setBookingdata({ ...dataCopy });
        setValidVoucherUsed(voucherData);
        setVoucherError(null);
        // setLastFoodBoardPrice(totalFoodBoardPrice);
      }
    } catch (ex) {
      console.log(ex, "ex");
      if (ex.response && ex.response.status === 404) {
        setVoucherError(t(ex.response.data));
      }
    }
  };

  // const handleBoardChange = (board) => {
  //   let dataCopy = { ...bookingData };
  //   const days = dataCopy.daysLength;

  //   // If we have previously selected a food board deduct its price
  //   // from the total price, and then add the new food board price
  //   if (dataCopy.foodBoard) {
  //     dataCopy.price -= lastFoodBoardPrice;
  //   }

  //   const boardPrice = boardPrices[board.value];
  //   let priceToAdd = days * boardPrice;

  //   // If a voucher was used apply it to the food board price
  //   if (validVoucherUsed) {
  //     const voucherStartDate = getDateZeroHours(
  //       new Date(validVoucherUsed.startDate)
  //     );
  //     const voucherEndDate = getDateZeroHours(
  //       new Date(validVoucherUsed.endDate)
  //     );
  //     const discountPercentage = validVoucherUsed.discount / 100;

  //     let discountedFoodBoardPrice = 0;
  //     const foodBoardPriceDiscounted = Number(
  //       boardPrice - boardPrice * discountPercentage
  //     );

  //     const currentRoom = bookingData.rooms[0];
  //     const currentRoomDaysInPeriod = currentRoom.daysInPeriods;
  //     let previousSlicedDayIndex = 0;

  //     // eslint-disable-next-line no-loop-func
  //     Object.keys(currentRoomDaysInPeriod).forEach((periodKey) => {
  //       const daysInCurrentPeriod = bookedDays.slice(
  //         previousSlicedDayIndex,
  //         currentRoomDaysInPeriod[periodKey] + previousSlicedDayIndex
  //       );
  //       previousSlicedDayIndex = currentRoomDaysInPeriod[periodKey];

  //       daysInCurrentPeriod.forEach((day) => {
  //         const currentDay = getDateZeroHours(new Date(day));

  //         // If the current day falls into the voucher period
  //         // apply the discounted prices
  //         if (
  //           currentDay.getTime() >= voucherStartDate.getTime() &&
  //           currentDay.getTime() <= voucherEndDate.getTime()
  //         ) {
  //           discountedFoodBoardPrice += Number(foodBoardPriceDiscounted);
  //         } else {
  //           discountedFoodBoardPrice += Number(boardPrice);
  //         }
  //       });
  //     });
  //     priceToAdd = discountedFoodBoardPrice;
  //   }

  //   const totalFoodBoardPrice = Number(priceToAdd * dataCopy.adults.value);
  //   dataCopy.price = Number(dataCopy.price) + totalFoodBoardPrice;
  //   dataCopy.price = Number(dataCopy.price);

  //   dataCopy["foodBoard"] = board.value;

  //   setLastFoodBoardPrice(totalFoodBoardPrice);
  //   // setSelectedFoodBoard(board);
  //   setBookingdata({ ...dataCopy });
  // };

  return (
    <Layouts>
      <div className="payment-page">
        {/* <div className="food-board-container">
          <h2 className="center-text">{t("food-board")}</h2>
          <ReactSelect
            options={foodBoardOptions}
            value={selectedFoodBoard ? selectedFoodBoard : foodBoardOptions[0]}
            onChange={(val) => handleBoardChange(val)}
            components={{
              IndicatorSeparator: () => null,
            }}
            styles={{
              control: (base, state) => ({
                ...base,
                border: state.isSelected ? 0 : "1px solid var(--theme-color)",
                "&:hover": {
                  border: "1px solid var(--theme-color)",
                },
                boxShadow: "none",
              }),
              menu: (provided, state) => ({
                ...provided,
                border: "1px solid var(--theme-color)",
              }),
            }}
            isSearchable={false}
          />
        </div> */}
        <SummaryContainer {...{ bookingData, t }} />
        <LineSeparator />
        <CheckoutContainer
          {...{
            language: i18n.language,
            validateVoucher,
            voucher,
            setVoucher,
            width,
            voucherError,
            bookingData,
            validVoucherUsed,
            t,
          }}
        />
      </div>
    </Layouts>
  );
}

const SummaryContainer = ({ bookingData, t }) => {
  return (
    <div className="payment-container">
      <div className="booking-details">
        <h2 className="center-text">{t("booking-summary")}</h2>
        <div className="booking-details__items">
          <div className="booking-details__item">
            <h5>{t("check-in")}</h5>
            <p>{getDateView(getDateDB(bookingData.startDate))}</p>
          </div>
          <div className="booking-details__item">
            <h5>{t("check-out")}</h5>
            <p>{getDateView(getDateDB(bookingData.endDate))}</p>
          </div>
          <div className="booking-details__item">
            <h5>{t("guests")}</h5>
            <p>{bookingData.adults.value}</p>
          </div>
          <div className="booking-details__item">
            <h5>{t("children")}</h5>
            <p>{bookingData.children.value}</p>
          </div>
          <div className="booking-details__item">
            <h5>{t("food-board")}</h5>
            <p>{t("ultra-all-inclusive")}</p>
          </div>
          <div className="total-price booked-rooms-summary">
            <h5>{t("rooms")}</h5>
            {bookingData &&
              bookingData.rooms.map((room, index) => {
                return (
                  <Fragment key={index}>
                    <p>
                      {t(room.roomName)} - {room.count}{" "}
                      {room.count === 1
                        ? t("room-single")
                        : t("rooms").toLowerCase()}
                    </p>
                  </Fragment>
                );
              })}
          </div>
          <div className="total-price">
            <h5 className="price center-text">
              {t("price")}: {bookingData.price} {t("bgn")}
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

const CheckoutContainer = ({
  validateVoucher,
  voucher,
  setVoucher,
  width,
  voucherError,
  bookingData,
  validVoucherUsed,
  language,
  t,
}) => {
  return (
    <div className="payment-and-voucher-container">
      <form onSubmit={validateVoucher} className="voucher-form">
        <h2 className="payment-heading">{t("payment")}</h2>
        <div className="payment-and-voucher__submit-container">
          <input
            placeholder={t("voucher")}
            value={voucher}
            onChange={(e) => setVoucher(e.currentTarget.value)}
          />
          <Button
            type="submit"
            classes={["submit-voucher-button"]}
            text={t("apply")}
          />
        </div>
        {width >= 768 && <br className="flex-breaker" />}
        {voucherError && <p className="voucher-error">{voucherError}</p>}
      </form>
      <Elements stripe={stripePromise} options={{ locale: language }}>
        <PaymentForm
          bookingData={{ ...bookingData }}
          validVoucherUsed={validVoucherUsed}
        />
      </Elements>
    </div>
  );
};
