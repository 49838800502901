import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import logo from "../../assets/images/logo-no-background-big.png";

const MobileHeader = ({ closeMobileHeader, tabs, activeTab }) => {
  const { t, i18n } = useTranslation();
  const languages = ["bg", "en", "de"];
  const currentLang = i18n.language;

  function onMenuItemClick() {
    window.scrollTo(10, 0);
    closeMobileHeader();
  }
  return (
    <div className="mobile-menu">
      <div className="menu-backdrop" />
      <div className="close-btn" onClick={() => closeMobileHeader()}>
        <i className="icon far fa-times" />
      </div>
      <nav className="menu-box">
        <div className="nav-logo">
          <Link to="/home">
            <img
              src={logo}
              alt=""
              title=""
              style={{ border: "1px solid", borderRadius: "10px" }}
            />
          </Link>
        </div>
        <div className="menu-outer">
          {/*Here Menu Will Come Automatically Via Javascript / Same Menu as in Header*/}
          <div className="collapse navbar-collapse show clearfix">
            <ul className="navigation">
              {tabs.map((tab, i) => {
                return (
                  <li key={i}>
                    <Link
                      to={tab}
                      onClick={onMenuItemClick}
                      className={`${
                        activeTab === tab && "navigation-active-tab"
                      }`}
                    >
                      {t(tab === "rooms" ? "accommodation" : tab)}
                    </Link>
                  </li>
                );
              })}
            </ul>
            <div className="mobile-language-change">
              {languages.map((lang, i) => {
                return (
                  <div
                    className={`mobile-language-change__lang ${
                      currentLang === lang &&
                      "mobile-language-change__lang-selected"
                    }`}
                    key={i}
                    onClick={() => {
                      i18n.changeLanguage(lang);
                      localStorage.setItem("hotel-admiral-language", lang);
                    }}
                  >
                    {lang.toUpperCase()}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};
export default MobileHeader;
