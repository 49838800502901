import React, { useState } from "react";
import PageBanner from "../components/PageBanner";
import Layouts from "../layouts/Layouts";
import config from "../config/config.json";
import { useTranslation } from "react-i18next";
import Joi from "joi";
import { validate } from "../utils/validation";
import emailSVC from "../services/emailService";
import { toast } from "react-toastify";

import contactUs from "../assets/images/contact-us.jpg";

const toEmail = config.fromEmail;

const Contact = () => {
  const { t } = useTranslation();

  const schema = Joi.object({
    firstName: Joi.string()
      .min(3)
      .max(50)
      .required()
      .label(t("required-field")),
    lastName: Joi.string().min(3).max(50).required().label(t("required-field")),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label(t("required-field")),
    subject: Joi.string().min(3).required().label(t("required-field")),
    message: Joi.string().min(3).required().label(t("required-field")),
  });

  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    subject: "",
    message: "",
  });
  const [error, setError] = useState({});

  const changeHandler = (field, value) => {
    let dataCopy = { ...data };
    dataCopy[field] = value;
    setData({ ...dataCopy });
  };

  const subimtHandler = async (e) => {
    e.preventDefault();
    if ((await validate(data, schema, setError)) === null) {
      // send email here
      const emailData = {
        subject: data.subject,
        text: data.message,
        to: toEmail,
        replyTo: data.email,
        html: `<p>${data.message}</p>`,
      };
      try {
        emailSVC.sendEmail(emailData);
        setData({
          firstName: "",
          lastName: "",
          email: "",
          subject: "",
          message: "",
        });
        toast.success(t("send-email-success"));
      } catch (err) {
        console.log(err);
        toast.success(t("send-email-fail"));
      }
    }
  };

  return (
    <Layouts>
      <PageBanner pageName={t("contact")} />
      <section className="section-fifty-six">
        <div className="auto-container">
          <div className="row">
            <div className="col-lg-4">
              <div className="block-fifty-six">
                <div className="icon">
                  <i className="flaticon-open" />
                </div>
                <div className="icon-border" />
                <h3>{t("email-address")}</h3>
                <div className="text">
                  reservations@admiral.bg <br />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="block-fifty-six">
                <div className="icon">
                  <i className="flaticon-call-2" />
                </div>
                <div className="icon-border" />
                <h3>{t("telephone")}</h3>
                <div className="text">
                  <a href="tel:+35952390200">+359 52 390 200</a> <br />{" "}
                  {t("fax")}: <a href="tel:+35952355252">+359 52 355 252</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="block-fifty-six">
                <div className="icon">
                  <i className="flaticon-location-2" />
                </div>
                <div className="icon-border" />
                <h3>{t("address")}</h3>
                <div className="text">{t("hotel-address")}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* map section */}
      <section className="map-section">
        <h4 className="d-none">heading</h4>
        <div className="contact-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2904.345968174646!2d28.044042!3d43.286076!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40a4f8d07189ca17%3A0x520bb97590851dec!2sAdmiral!5e0!3m2!1sen!2sbg!4v1656242408622!5m2!1sen!2sbg"
            width="600"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="hotel-admiral-address"
          ></iframe>
        </div>
      </section>
      {/* contact section */}
      <section className="contact-section">
        <div className="auto-container">
          <div className="row">
            <div className="col-lg-6">
              <div className="image">
                <img src={contactUs} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="contact-block">
                <h2 className="sec-title mb-30">{t("send-message")}</h2>
                {/* form */}
                <div className="contact-form style-two">
                  <form method="post" onSubmit={subimtHandler}>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          defaultValue=""
                          placeholder={t("name")}
                          required=""
                          onChange={(e) =>
                            changeHandler("firstName", e.currentTarget.value)
                          }
                          value={data.firstName}
                        />
                        <p className="form-error">
                          {error.firstName ? error.firstName : ""}
                        </p>
                      </div>
                      <div className="form-group col-md-6">
                        <input
                          type="text"
                          className="form-control"
                          name="lastname"
                          defaultValue=""
                          placeholder={t("last-name")}
                          required=""
                          onChange={(e) =>
                            changeHandler("lastName", e.currentTarget.value)
                          }
                          value={data.lastName}
                        />
                        <p className="form-error">
                          {error.lastName ? error.lastName : ""}
                        </p>
                      </div>
                      <div className="form-group col-md-6">
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          defaultValue=""
                          placeholder={t("email")}
                          required=""
                          onChange={(e) =>
                            changeHandler("email", e.currentTarget.value)
                          }
                          value={data.email}
                        />
                        <p className="form-error">
                          {error.email ? error.email : ""}
                        </p>
                      </div>
                      <div className="form-group col-md-6">
                        <input
                          type="text"
                          className="form-control"
                          name="subject"
                          defaultValue=""
                          placeholder={t("subject")}
                          required=""
                          onChange={(e) =>
                            changeHandler("subject", e.currentTarget.value)
                          }
                          value={data.subject}
                        />
                        <p className="form-error">
                          {error.subject ? error.subject : ""}
                        </p>
                      </div>
                      <div className="form-group col-md-12">
                        <textarea
                          name="message"
                          className="form-control"
                          placeholder={t("message")}
                          defaultValue={""}
                          onChange={(e) =>
                            changeHandler("message", e.currentTarget.value)
                          }
                          value={data.message}
                        />
                        {error.message && (
                          <p className="form-error">{error.message}</p>
                        )}
                      </div>
                      <div className="form-group col-md-12">
                        <input
                          id="form_botcheck"
                          name="form_botcheck"
                          className="form-control"
                          type="hidden"
                          defaultValue=""
                        />
                        <button
                          className="theme-btn btn-style-one"
                          type="submit"
                          data-loading-text="Please wait..."
                        >
                          <span>{t("send")}</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                {/* form end */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layouts>
  );
};
export default Contact;
