import http from "./httpService";
import config from "../config/config.json";
const apiUrl = config.apiUrl;

const apiEndpoint = apiUrl + "/bookings";
const getBookings = async () => {
  return await http.get(apiEndpoint);
};

const cancelBooking = async (ref) => {
  return await http.put(apiEndpoint + "/" + ref);
};

const changeVisiblity = async (ref, visibility) => {
  return await http.put(apiEndpoint + "/change-visibility", {
    ref,
    visibility,
  });
};

const exportedFunctions = {
  getBookings,
  cancelBooking,
  changeVisiblity,
};

export default exportedFunctions;
