import React, { useState, useEffect, useRef, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import RoomInfo from "../utils/RoomInfo";
import { getDateDB, getDatesInRange } from "../utils/date";
import Select from "react-select";
import Button from "./Button";
// import useRoomPrices from "../utils/useRoomPrices";
import usePeriodRoomPrices from "../utils/usePeriodRoomPrices";
import useWindowsDimensions from "../utils/useWindowsDimensions";
import { Icon } from "./Icon/Icon";
import { toast } from "sonner";

export default function RoomBookingView({ roomsToDisplay: rooms, data }) {
  const history = useHistory();
  const { t } = useTranslation();
  const [totalPrice, setTotalPrice] = useState(0);
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [bookedDays, setBookedDays] = useState();
  const [daysLength, setDaysLength] = useState();
  // const [roomsPrices] = useRoomPrices();
  const [roomsPrices, daysInPeriods] = usePeriodRoomPrices(
    data && data.startDate,
    data && data.endDate
  );

  const canAccomodate = useMemo(() => {
    if (!selectedRooms.length) return false;
    const totalAdults = data.adults.value;
    const totalChildren = data.children.value;

    function findCombinations(remainingRooms, comboAdults, comboChildren) {
      if (comboAdults >= totalAdults && comboChildren >= totalChildren) {
        return true;
      }

      if (remainingRooms.length === 0) {
        return false;
      }

      const [currentRoom, ...restRooms] = remainingRooms;

      const roomInfo = RoomInfo[currentRoom.roomName];
      if (!roomInfo) return false;

      for (const variant of roomInfo.maxOccupancy) {
        for (let count = 0; count <= currentRoom.count; count++) {
          const newComboAdults = comboAdults + count * variant.adults;
          const newComboChildren = comboChildren + count * variant.children;

          if (
            newComboAdults >= totalAdults &&
            newComboChildren >= totalChildren
          ) {
            return true;
          }

          if (findCombinations(restRooms, newComboAdults, newComboChildren)) {
            return true;
          }
        }
      }

      return false;
    }

    return findCombinations(selectedRooms, 0, 0);
  }, [selectedRooms, data]);

  const totalPriceRef = useRef(null);
  const { width } = useWindowsDimensions();

  useEffect(() => {
    if (data) {
      const dates = getDatesInRange(
        getDateDB(data.startDate),
        getDateDB(data.endDate)
      );

      setDaysLength(dates.length - 1);
      setBookedDays(dates);
    }
  }, [data]);

  /**
   *
   * @param {Number} count - the ammount of rooms to select
   * @param {String} roomName - the name of the room to select
   */
  const handleRoomSelect = (count, roomName) => {
    let selectedRoomsCopy = [...selectedRooms];
    const roomExists = selectedRooms.find((x) => x.roomName === roomName);
    let totalPriceForAllPeriods = 0;
    Object.keys(daysInPeriods).forEach((period) => {
      totalPriceForAllPeriods +=
        roomsPrices[roomName][period] * daysInPeriods[period];
    });
    if (roomExists) {
      selectedRoomsCopy[selectedRoomsCopy.indexOf(roomExists)] = {
        roomName,
        count,
        totalPriceForAllPeriods,
        daysInPeriods,
      };
    } else {
      selectedRoomsCopy.push({
        roomName,
        count,
        totalPriceForAllPeriods,
        daysInPeriods,
      });
    }
    // console.log(selectedRoomsCopy, "rooms copy");
    setSelectedRooms(selectedRoomsCopy);
    let totalAmmount = 0;
    for (let i = 0; i < selectedRoomsCopy.length; i++) {
      const tempTotal =
        selectedRoomsCopy[i].totalPriceForAllPeriods *
        selectedRoomsCopy[i].count;
      totalAmmount += tempTotal;
    }
    // console.log("days length", daysLength);
    setTotalPrice(totalAmmount.toFixed(2));
  };

  const handleSubmit = () => {
    if (!totalPrice || totalPrice === "0.00") {
      return;
    }
    if (!canAccomodate) {
      const adults = data.adults.value;
      const children = data.children.value;
      const message = children
        ? "cant_accomodate_with_children"
        : "cant_accomodate";
      toast.error(
        t(message, {
          adults,
          children,
        })
      );
      return;
    }
    window.scrollTo(10, 0);
    const finalData = {
      ...data,
      rooms: selectedRooms.filter((x) => x.count !== 0),
      price: Number(totalPrice),
      daysLength,
    };
    history.push({
      pathname: "payment",
      state: {
        bookingData: finalData,
        roomsPrices,
        bookedDays,
      },
    });
  };

  return !rooms || !rooms.length ? (
    <div>
      <h3 style={{ textAlign: "center", margin: "50px" }}>
        {t("no-room-found")}
      </h3>
    </div>
  ) : (
    <div style={{ padding: "35px" }} ref={totalPriceRef}>
      <div className="room-booking-view__submit-container">
        {totalPrice ? (
          <>
            <p>{`${t("total-price")} ${totalPrice}${t("bgn")}`}</p>
            <Button text={t("continue")} onClick={handleSubmit} />
          </>
        ) : (
          ""
        )}
      </div>
      {rooms &&
        rooms.length &&
        roomsPrices &&
        rooms.map((room, index) => {
          return (
            <RoomBookingViewSingle
              key={index}
              room={RoomInfo[room.roomName]}
              price={roomsPrices ? roomsPrices[room.roomName] : ""}
              availableRooms={room.availableRooms}
              t={t}
              handleRoomSelect={handleRoomSelect}
              history={history}
              totalPriceRef={totalPriceRef}
            />
          );
        })}

      {totalPrice && width < 768 ? (
        <Button
          classes={["booking-submit-button"]}
          text={t("continue")}
          onClick={handleSubmit}
        />
      ) : (
        ""
      )}

      <div className="room-booking-view__submit-container">
        {totalPrice ? (
          <>
            <p>{`${t("total-price")} ${totalPrice}${t("bgn")}`}</p>
            <Button text={t("continue")} onClick={handleSubmit} />
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

/**
 *
 * @param {Object} room - the room object returned from the DB - {name:'Sea room', roomKey:'room-key'}
 * @param {Function} t - the translation function
 * @param {Object} price - the prices for different periods - {"periodOne": 150}
 * @param {Number} availableRooms - ammount of available rooms
 * @param {Function} handleRoomSelect
 * @returns
 */
const RoomBookingViewSingle = ({
  room,
  t,
  price,
  availableRooms,
  handleRoomSelect,
  history,
  totalPriceRef,
}) => {
  const defaultValue = { value: 0, label: "0" };
  const [roomsCount, setRoomsCount] = useState(defaultValue);

  let options = [];
  for (let i = 0; i <= availableRooms; i++) {
    options.push({ value: i, label: i.toString() });
  }

  /**
   *
   * @param {Object} value - {value: 1, label:'1}
   */
  const handleChange = (value) => {
    handleRoomSelect(value.value, room.roomKey);
    setRoomsCount(value);
    const offset = totalPriceRef.current.offsetTop;
    window.scrollTo({
      top: offset - 50,
      behavior: "smooth",
    });
    // totalPriceRef.current.scrollIntoView();
  };

  /**
   *
   * @param {Object} prices - the prices for different periods - {"periodOne": 150}
   * @returns
   */
  const getPricesString = (prices) => {
    if (!prices) return <div />;
    if (Object.keys(prices).length === 1) {
      const firstPriceKey = Object.keys(prices)[0];
      const firstPrice = prices[firstPriceKey];
      return `${firstPrice}${t("bgn")} / ${t("night")}`;
    } else {
      const pricesValues = Object.values(prices);
      const firstPrice = `${Math.min(...pricesValues)}${t("bgn")}`;
      const lastPrice = `${Math.max(...pricesValues)}${t("bgn")}`;
      return `${firstPrice} - ${lastPrice} / ${t("night")}`;
    }
  };

  const redirectToRoom = () => {
    history.push({
      pathname: "/room-single",
      state: {
        name: room.roomKey,
        price: getPricesString(price),
        isPriceFormatted: true,
      },
    });
  };

  return (
    <div className="row room-booking-view-single">
      <div className="col-lg-4">
        <div className="image">
          <img
            src={room.image}
            alt=""
            style={{ display: "block", margin: "0 auto" }}
          />
        </div>
      </div>
      <div className="col-lg-8" style={{ alignSelf: "center" }}>
        <div className="block-thirty-eight">
          <div className="icon-list">
            <ul>
              <li>
                <i className="flaticon-user" />
                <h4>{t("capacity")}</h4>
                <div style={{ marginBottom: 0 }} className="text">{`${
                  RoomInfo[room.roomKey].adults
                } ${t("adults")}`}</div>
                <div className="text">{`${RoomInfo[room.roomKey].children} ${t(
                  "children"
                )}`}</div>
              </li>
              <li>
                <i className="flaticon-preview" />
                <h4>{t("size")}</h4>
                <div className="text">{room.size}m2</div>
              </li>
              <li>
                <i className="flaticon-view" />
                <h4>{t("view")}</h4>
                <div className="text">{t(RoomInfo[room.roomKey].view)}</div>
              </li>
              <li>
                <Icon
                  classes="block-thirty-eight__fork-icon"
                  icon="fork-knife"
                />
                <h4>{t("food-board")}</h4>
                <div className="text">{t("ultra-all-inclusive")}</div>
              </li>
            </ul>
          </div>
          <h3 className="text-left">{`${t(room.roomKey)}`}</h3>
          <div className="text-two">{`${t(
            room.roomKey + "-description"
          )}`}</div>
          <div className="inner-box">
            <div className="pricing">{getPricesString(price)}</div>
            <div className="room-ammount-selector">
              <p>{t("room-number")}</p>
              <Select
                value={roomsCount}
                onChange={(value) => handleChange(value)}
                isSearchable={false}
                options={options}
                components={{
                  IndicatorSeparator: () => null,
                }}
                className="room-ammount-selector__select"
                menuPortalTarget={document.body}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    border: 0,
                    boxShadow: "none",
                    "&:hover": {
                      border: "1px solid black",
                    },
                  }),
                  menuPortal: (base) => ({ ...base, zIndex: 999 }),
                }}
              />
            </div>
          </div>
          <div
            onClick={redirectToRoom}
            className="room-booking-view-single-see-more"
          >
            <p>{t("see-more")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
