import http from "./httpService";
import config from "../config/config.json";
const apiUrl = config.apiUrl;
const apiEndpoint = apiUrl + "/availability";

const getAvailability = async () => {
  return await http.get(apiEndpoint);
};

const createAvailability = async (data) => {
  const res = await http.post(apiEndpoint, data);
  return res;
};

const deleteAvailability = async (id) => {
  const res = await http.delete(`${apiEndpoint}/${id}`);
  return res;
};

const getAvailabilityForPeriod = async (data) => {
  const res = await http.post(`${apiEndpoint}/getAvailabilityForPeriod`, data);
  return res;
};

const getAvailabilityById = async (id) => {
  return await http.get(`${apiEndpoint}/${id}`);
};

const editAvailability = async (data) => {
  const res = await http.put(`${apiEndpoint}/editAvailability`, data);
  return res;
};

const exportedFunctions = {
  getAvailability,
  createAvailability,
  deleteAvailability,
  getAvailabilityForPeriod,
  getAvailabilityById,
  editAvailability,
};

export default exportedFunctions;
