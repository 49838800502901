import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import PageBanner from "../components/PageBanner";
import Layouts from "../layouts/Layouts";
import RoomInfo from "../utils/RoomInfo";
import LoadingSpinner from "../components/LoadingSpinner";
import "../assets/css/room-details.css";
import Button from "../components/Button";
import { FullScreenGallery } from "../components/GalleryFilter";
// import { Icon } from "../components/Icon/Icon";

const RoomDetails = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [data, setData] = useState();
  const [fullScreenImage, setFullScreenImage] = useState();

  useEffect(() => {
    if (location.state) {
      const locationData = location.state;
      const dataCopy = { ...RoomInfo[locationData.name] };
      dataCopy.price = locationData.price;
      dataCopy.name = locationData.name;
      dataCopy.isPriceFormatted = locationData.isPriceFormatted;
      setData(dataCopy);
    }
  }, [location]);

  const redirectToBooking = () => {
    window.scrollTo(0, 500);
    history.push("/rooms");
  };

  const sendInquiry = () => {
    window.scrollTo(0, 1800);
    history.push("/contact");
  };

  const allImages = [
    data?.image1,
    data?.image2,
    data?.image3,
    data?.image4,
    data?.image5,
  ].filter((image) => image);

  return (
    <Layouts>
      <PageBanner pageName={t("room-details")} />
      {data ? (
        <section className="section-thirty-nine">
          <div className="auto-container">
            <div className="row">
              {data.image1 && (
                <div className="col-lg-3 col-md-12">
                  <div className="block-thirty-nine">
                    <div className="image image-1">
                      <img
                        onClick={() => setFullScreenImage(data.image1)}
                        src={data.image1}
                        alt=""
                      />
                    </div>
                    {data.image2 && (
                      <div className="image image-2">
                        <img
                          onClick={() => setFullScreenImage(data.image2)}
                          src={data.image2}
                          alt=""
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
              {data.image3 && (
                <div className="col-lg-6 col-md-12">
                  <div className="image image-3">
                    <img
                      onClick={() => setFullScreenImage(data.image3)}
                      src={data.image3}
                      alt=""
                    />
                  </div>
                </div>
              )}
              {data.image4 && (
                <div className="col-lg-3 col-md-12">
                  <div className="block-thirty-nine">
                    <div className="image image-4">
                      <img
                        onClick={() => setFullScreenImage(data.image4)}
                        src={data.image4}
                        alt=""
                      />
                    </div>
                    {data.image5 && (
                      <div className="image image-5">
                        <img
                          onClick={() => setFullScreenImage(data.image5)}
                          src={data.image5}
                          alt=""
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-lg-8">
                <div className="block-forty">
                  {data.price ? (
                    <div className="pricing">
                      <b>
                        {data.price}
                        {!data.isPriceFormatted
                          ? `${t("bgn")} / ${t("night")}`
                          : ""}
                      </b>
                    </div>
                  ) : null}
                  {data.name === "boutique-apartment" ? (
                    <Button
                      classes={["reserve-room-button"]}
                      text={t("send-inquiry")}
                      onClick={sendInquiry}
                    />
                  ) : (
                    <Button
                      classes={["reserve-room-button"]}
                      text={t("reserve")}
                      onClick={redirectToBooking}
                    />
                  )}
                  <h4 className="room-details__heading">{t(data.name)}</h4>
                  <ul>
                    <li>
                      <i className="flaticon-preview" />
                      <span>{data.size} m2</span>
                    </li>
                  </ul>
                  <div className="text">{t(data.name + "-description")}</div>
                  {/* <div className="text-two">
                    Collect 10 stamps, get 1 reward* night with Hotels.com
                    Rewards. Whether it’s a weekend break business trip or a
                    family holiday, collect 1 stamp for every night stayed –
                    even when you book for others. Collect stamps after your
                    stay at over 1,000,000 properties around the world and
                    redeem reward nights anytime.
                  </div>
                  <div className="bottom-border">
                    <h3>Property Highlights</h3>
                  </div>
                  <div className="text-three">
                    Instantly access Secret Prices when you download our app.
                    Look out for ’Your Secret Price’ on selected hotels and save
                    up to 40%. We don’t share Secret Prices with other sites:
                    these are lower prices that we’ve specially negotiated for
                    our customers who have subscribed to our emails, downloaded
                    our app or those who have joined Hotels.com Rewards.
                  </div> */}
                </div>
                {/* <div className="row">
                  <div className="col-md-4">
                    <div className="block-40">
                      <div className="icon">
                        <i className="flaticon-like" />
                      </div>
                      <div className="content-box">
                        <div className="author">Walkable</div>
                        <div className="designation">
                          6,850 positive mentions
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="block-40">
                      <div className="icon">
                        <i className="flaticon-like" />
                      </div>
                      <div className="content-box">
                        <div className="author">Launch Restaurant</div>
                        <div className="designation">
                          6,850 positive mentions
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="block-40">
                      <div className="icon">
                        <i className="flaticon-like" />
                      </div>
                      <div className="content-box">
                        <div className="author">Open 5 more hotel</div>
                        <div className="designation">
                          6,850 positive mentions
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="bottom-border">
                  <h3>{t("services")}</h3>
                </div>
                <div className="room-details-services">
                  {data.features.map((feature, index) => {
                    return (
                      <div key={index}>
                        <i className="flaticon-check" />
                        <span style={{ paddingLeft: "12px" }}>
                          {t(feature)}
                        </span>
                      </div>
                    );
                  })}
                </div>
                {/* <div className="block-42">
                  <div className="icon-two">
                    <i className="flaticon-moon" />{" "}
                  </div>
                  <div className="cta-box">
                    <h6>You’ll collect 1 stamp after your 1-night stay</h6>
                    <div className="cta-text">
                      Remember, collect 10 stamps, get 1 reward* night
                    </div>
                  </div>
                </div> */}
                <div className="bottom-border">
                  <h3>{t("additional-services")}</h3>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="block-six">
                      <div className="icon">
                        <i className="flaticon-laundry" />
                      </div>
                      <h4>{t("laundry")}</h4>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="block-six">
                      <div className="icon">
                        <i className="flaticon-cheers" />
                      </div>
                      <h4>{t("minibar")}</h4>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="block-six">
                      <div className="icon">
                        <i className="flaticon-breakfast" />
                      </div>
                      <h4>{t("ultra-all-inclusive")}</h4>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="block-six">
                      <div className="icon">
                        <i className="flaticon-wifi-signal" />
                      </div>
                      <h4>{t("wifi")}</h4>
                    </div>
                  </div>
                </div>
                {/* <Accordion
                  as={"ul"}
                  className="accordion-box"
                  defaultActiveKey="acc-1"
                >
                  <li className={`accordion block ${activeLi("acc-1")}`}>
                    <Accordion.Toggle
                      as={"div"}
                      eventKey="acc-1"
                      className={`acc-btn ${activebtn(`acc-1`)}`}
                      onClick={() => setAcc("acc-1")}
                    >
                      <div className="icon-outer">
                        <span className="fal fa-plus" />
                      </div>
                      Is parking offered on site at Excalibur Hotel &amp;
                      Casino?
                    </Accordion.Toggle>
                    <Accordion.Collapse
                      eventKey="acc-1"
                      // className={`acc-content `}
                      // style={activeContent("acc-1")}
                    >
                      <div className="content">
                        <div className="text">
                          Very clean facility rooms are large and comfortable
                          check in although very nice people left a lot to be{" "}
                          <br /> desired as it took 40 minutes waiting in a que
                          like Disney Land The buffet was flavorless and food
                          dry the shops do not have prices marked on beverages
                          and snacks so it’s a little bit of a surprise to pay 6
                          dollars for a 99 cent soda Plus the Starbucks in food
                          com.
                        </div>
                      </div>
                    </Accordion.Collapse>
                  </li>
                  <li className={`accordion block ${activeLi("acc-2")}`}>
                    <Accordion.Toggle
                      as={"div"}
                      eventKey="acc-2"
                      className={`acc-btn ${activebtn(`acc-2`)}`}
                      onClick={() => setAcc("acc-2")}
                    >
                      <div className="icon-outer">
                        <span className="fal fa-plus" />
                      </div>
                      Is there a pool at Excalibur Hotel &amp; Casino?{" "}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="acc-2">
                      <div className="content">
                        <div className="text">
                          Very clean facility rooms are large and comfortable
                          check in although very nice people left a lot to be{" "}
                          <br /> desired as it took 40 minutes waiting in a que
                          like Disney Land The buffet was flavorless and food
                          dry the shops do not have prices marked on beverages
                          and snacks so it’s a little bit of a surprise to pay 6
                          dollars for a 99 cent soda Plus the Starbucks in food
                          com.
                        </div>
                      </div>
                    </Accordion.Collapse>
                  </li>
                  <li className={`accordion block ${activeLi("acc-3")}`}>
                    <Accordion.Toggle
                      as={"div"}
                      eventKey="acc-3"
                      className={`acc-btn ${activebtn(`acc-3`)}`}
                      onClick={() => setAcc("acc-3")}
                    >
                      <div className="icon-outer">
                        <span className="fal fa-plus" />
                      </div>
                      Are there restaurants at or near Excalibur Hotel &amp;
                      Casino?{" "}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="acc-3">
                      <div className="content">
                        <div className="text">
                          Very clean facility rooms are large and comfortable
                          check in although very nice people left a lot to be{" "}
                          <br /> desired as it took 40 minutes waiting in a que
                          like Disney Land The buffet was flavorless and food
                          dry the shops do not have prices marked on beverages
                          and snacks so it’s a little bit of a surprise to pay 6
                          dollars for a 99 cent soda Plus the Starbucks in food
                          com.
                        </div>
                      </div>
                    </Accordion.Collapse>
                  </li>
                  <li className={`accordion block ${activeLi("acc-4")}`}>
                    <Accordion.Toggle
                      as={"div"}
                      eventKey="acc-4"
                      className={`acc-btn ${activebtn(`acc-4`)}`}
                      onClick={() => setAcc("acc-4")}
                    >
                      <div className="icon-outer">
                        <span className="fal fa-plus" />
                      </div>
                      Is there an onsite casino at Excalibur Hotel &amp; Casino?
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="acc-4">
                      <div className="content">
                        <div className="text">
                          Very clean facility rooms are large and comfortable
                          check in although very nice people left a lot to be{" "}
                          <br /> desired as it took 40 minutes waiting in a que
                          like Disney Land The buffet was flavorless and food
                          dry the shops do not have prices marked on beverages
                          and snacks so it’s a little bit of a surprise to pay 6
                          dollars for a 99 cent soda Plus the Starbucks in food
                          com.
                        </div>
                      </div>
                    </Accordion.Collapse>
                  </li>
                  <li className={`accordion block ${activeLi("acc-5")}`}>
                    <Accordion.Toggle
                      as={"div"}
                      eventKey="acc-5"
                      className={`acc-btn ${activebtn(`acc-5`)}`}
                      onClick={() => setAcc("acc-5")}
                    >
                      <div className="icon-outer">
                        <span className="fal fa-plus" />
                      </div>
                      What is there to do at Excalibur Hotel &amp; Casino and
                      nearby?
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="acc-5">
                      <div className="content">
                        <div className="text">
                          Very clean facility rooms are large and comfortable
                          check in although very nice people left a lot to be{" "}
                          <br /> desired as it took 40 minutes waiting in a que
                          like Disney Land The buffet was flavorless and food
                          dry the shops do not have prices marked on beverages
                          and snacks so it’s a little bit of a surprise to pay 6
                          dollars for a 99 cent soda Plus the Starbucks in food
                          com.
                        </div>
                      </div>
                    </Accordion.Collapse>
                  </li>
                </Accordion> */}
              </div>
            </div>
          </div>
        </section>
      ) : (
        <LoadingSpinner />
      )}
      {fullScreenImage && (
        <FullScreenGallery
          images={[
            fullScreenImage,
            ...allImages.filter((image) => image !== fullScreenImage),
          ]}
          onClose={() => setFullScreenImage()}
        />
      )}
    </Layouts>
  );
};
export default RoomDetails;
