import React, { useState } from "react";
import PageBanner from "../components/PageBanner";
import Layouts from "../layouts/Layouts";
import { Icon } from "../components/Icon/Icon";

import admiralNight from "../assets/images/admiralNight.jpg";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();

  const [grillWhite, setGrillWhite] = useState(false);
  const [pianoWhite, setPianoWhite] = useState(false);
  const [bicycleWhite, setBicycleWhite] = useState(false);
  const [vaultWhite, setVaultWhite] = useState(false);
  const [poolTableWhite, setPoolTableWhite] = useState(false);
  return (
    <Layouts>
      {/* Page Title */}
      <PageBanner pageName={t("about")} />
      {/* section one */}
      <section className="section-one">
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="image mb-30">
                <img src={admiralNight} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="block-two style-two">
                <div className="image mb-10">
                  {/* <img src={admiral2} alt="" /> */}
                </div>
                <h2 className="sec-title">
                  {/* Welcome To Our <br />{" "} */}
                  <span className="theme-color">{t("hotel-admiral")}</span>
                </h2>
                <div className="text-two">
                  <p>{t("about-text-one")}</p>
                  <p>{t("about-text-two")}</p>
                  <p>{t("about-text-three")}</p>
                </div>
                {/* <div className="bottom-content">
                  <div className="ratings">4.6</div>
                  <div className="text-three">
                    Users <br /> Ratings
                  </div>
                  <Link to="/gallery">
                    <a href="/home" className="theme-btn btn-style-one dark">
                      <span>Explore Now</span>
                    </a>
                  </Link>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* section thirty three */}
      {/* <section className="section-thirty-three">
        <div className="auto-container">
          <h2 className="sec-title text-center">{t("services")}</h2>
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="block-thirty-three">
                <div className="icon">
                  <i className="flaticon-breakfast" />
                </div>
                <h4>{t("ultra-all-inclusive")}</h4>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="block-thirty-three">
                <div className="icon">
                  <i className="flaticon-massage" />
                </div>
                <h4>{t("spa")}</h4>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="block-thirty-three">
                <div className="icon">
                  <i className="flaticon-cheers" />
                </div>
                <h4>{t("lobby-bar")}</h4>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="block-thirty-three">
                <div className="icon">
                  <i className="flaticon-pool" />
                </div>
                <h4>{t("outdoor-pool")}</h4>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="block-thirty-three">
                <div className="icon">
                  <i className="flaticon-car" />
                </div>
                <h4>{t("parking")}</h4>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="block-thirty-three">
                <div className="icon">
                  <i className="flaticon-resort" />
                </div>
                <h4>{t("own-beach")}</h4>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="block-thirty-three">
                <div className="icon">
                  <i className="flaticon-tray" />
                </div>
                <h4>{t("restaurants")}</h4>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="block-thirty-three">
                <div className="icon">
                  <i className="flaticon-weights" />
                </div>
                <h4>{t("fitness")}</h4>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="block-thirty-three">
                <div className="icon">
                  <i className="flaticon-dollar" />
                </div>
                <h4>{t("currency-exchange")}</h4>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="block-thirty-three">
                <div className="icon">
                  <i className="flaticon-conference" />
                </div>
                <h4>{t("conference")}</h4>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="block-thirty-three">
                <div className="icon">
                  <Icon icon="piano" classes="services-icon" />
                </div>
                <h4>{t("piano-bar-service")}</h4>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="block-thirty-three">
                <div className="icon">
                  <Icon icon="bicycle" classes="services-icon" />
                </div>
                <h4>{t("rent-bicycle-service")}</h4>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="block-thirty-three">
                <div className="icon">
                  <Icon icon="grill" classes="services-icon" />
                </div>
                <h4>{t("grill-service")}</h4>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="block-thirty-three">
                <div className="icon">
                  <i className="flaticon-laundry" />
                </div>
                <h4>{t("laundry")}</h4>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="block-thirty-three">
                <div className="icon">
                  <i className="flaticon-wifi-signal" />
                </div>
                <h4>{t("wifi")}</h4>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="block-thirty-three">
                <div className="icon">
                  <Icon icon="vault" />
                </div>
                <h4>{t("vault")}</h4>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="block-thirty-three">
                <div className="icon">
                  <Icon icon="8-ball" />
                </div>
                <h4>{t("pool-table")}</h4>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="section-six">
        <div className="auto-container">
          <h2 className="sec-title text-center">{t("services")}</h2>
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="block-six">
                <div className="icon">
                  <i className="flaticon-breakfast" />
                </div>
                <h4>{t("ultra-all-inclusive")}</h4>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="block-six">
                <div className="icon">
                  <i className="flaticon-massage" />
                </div>
                <h4>{t("spa")}</h4>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="block-six">
                <div className="icon">
                  <i className="flaticon-cheers" />
                </div>
                <h4>{t("lobby-bar")}</h4>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="block-six">
                <div className="icon">
                  <i className="flaticon-pool" />
                </div>
                <h4>{t("outdoor-pool")}</h4>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="block-six">
                <div className="icon">
                  <i className="flaticon-car" />
                </div>
                <h4>{t("parking")}</h4>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="block-six">
                <div className="icon">
                  <i className="flaticon-resort" />
                </div>
                <h4>{t("own-beach")}</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="block-six">
                <div className="icon">
                  <i className="flaticon-tray" />
                </div>
                <h4>{t("restaurants")}</h4>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="block-six">
                <div className="icon">
                  <i className="flaticon-weights" />
                </div>
                <h4>{t("fitness")}</h4>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="block-six">
                <div className="icon">
                  <i className="flaticon-dollar" />
                </div>
                <h4>{t("currency-exchange")}</h4>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="block-six">
                <div className="icon">
                  <i className="flaticon-conference" />
                </div>
                <h4>{t("conference")}</h4>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div
                className="block-six"
                onMouseEnter={() => setPianoWhite(true)}
                onMouseLeave={() => setPianoWhite(false)}
              >
                <div className="icon">
                  <Icon
                    icon={pianoWhite ? "piano-white" : "piano"}
                    classes="services-icon"
                  />
                </div>
                <h4>{t("piano-bar-service")}</h4>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div
                className="block-six"
                onMouseEnter={() => setBicycleWhite(true)}
                onMouseLeave={() => setBicycleWhite(false)}
              >
                <div className="icon">
                  <Icon
                    icon={bicycleWhite ? "bicycle-white" : "bicycle"}
                    classes="services-icon"
                  />
                </div>
                <h4>{t("rent-bicycle-service")}</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div
                className="block-six"
                onMouseEnter={() => setGrillWhite(true)}
                onMouseLeave={() => setGrillWhite(false)}
              >
                <div className="icon">
                  <Icon
                    icon={grillWhite ? "grill-white" : "grill"}
                    classes="services-icon"
                  />
                </div>
                <h4>{t("grill-service")}</h4>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="block-six">
                <div className="icon">
                  <i className="flaticon-laundry" />
                </div>
                <h4>{t("laundry")}</h4>
              </div>
            </div>
            {/* <div className="col-lg-2 col-md-4 col-sm-6">
              <div
                className="block-six"
                onMouseEnter={() => setAmoreWhite(true)}
                onMouseLeave={() => setAmoreWhite(false)}
              >
                <div className="icon">
                  <Icon
                    icon={amoreWhite ? "amore-white" : "amore"}
                    classes="services-icon"
                  />
                </div>
                <h4>{t("amore-service")}</h4>
              </div>
            </div> */}
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="block-six">
                <div className="icon">
                  <i className="flaticon-wifi-signal" />
                </div>
                <h4>{t("wifi")}</h4>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div
                className="block-six"
                onMouseEnter={() => setVaultWhite(true)}
                onMouseLeave={() => setVaultWhite(false)}
              >
                <div className="icon">
                  <Icon
                    icon={vaultWhite ? "vault-white" : "vault"}
                    classes="services-icon"
                  />
                </div>
                <h4>{t("vault")}</h4>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div
                className="block-six"
                onMouseEnter={() => setPoolTableWhite(true)}
                onMouseLeave={() => setPoolTableWhite(false)}
              >
                <div className="icon">
                  <Icon
                    icon={poolTableWhite ? "8-ball-white" : "8-ball"}
                    classes="services-icon"
                  />
                </div>
                <h4>{t("pool-table")}</h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* section thirty four */}
      {/* <section className="section-thirty-four">
        <h4 className="d-none">heading</h4>
        <div className="auto-container">
          <div className="row">
            <div className="col-md-6">
              <div className="block-thirty-four">
                <div className="image">
                  <img
                    src={image46}
                    alt=""
                  />
                </div>
                <div className="video-btn">
                  <a
                    href="https://www.youtube.com/watch?v=nfP5N9Yc72A&t=28s"
                    className="overlay-link play-now ripple"
                    data-fancybox="gallery"
                    data-caption=""
                  >
                    <i className="fas fa-play" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="block-thirty-four">
                <div className="image">
                  <img
                    src={image47}
                    alt=""
                  />
                </div>
                <div className="video-btn">
                  <a
                    href="https://www.youtube.com/watch?v=nfP5N9Yc72A&t=28s"
                    className="overlay-link play-now ripple"
                    data-fancybox="gallery"
                    data-caption=""
                  >
                    <i className="fas fa-play" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* section thirty five */}
      {/* <section className="section-thirty-five">
        <div className="auto-container">
          <Tab.Container defaultActiveKey={"tab-1"}>
            <div className="row">
              <div className="col-lg-7 order-2">
                <Tab.Content className="tab-content" id="myTabContent">
                  <Tab.Pane
                    className="tab-pane fade"
                    id="tab-1"
                    role="tabpanel"
                    eventKey="tab-1"
                  >
                    <div className="block-thirty-six">
                      <div className="image">
                        <img
                          src={image48}
                          alt=""
                        />
                      </div>
                      <div className="text">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit, sed do eiusmod tem por incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, qu quat.
                        Duis aute irure dolor in reprehenderit in voluptate
                        velit esse cillum dolore eu fugiat nulla pariatur.
                      </div>
                      <Link to="/room-details">
                        <a href="/home" className="theme-btn style-two">
                          <span>Our Services</span>
                        </a>
                      </Link>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    className="tab-pane fade"
                    id="tab-2"
                    role="tabpanel"
                    eventKey="tab-2"
                  >
                    <div className="block-thirty-six">
                      <div className="image">
                        <img
                          src={image48}
                          alt=""
                        />
                      </div>
                      <div className="text">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit, sed do eiusmod tem por incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, qu quat.
                        Duis aute irure dolor in reprehenderit in voluptate
                        velit esse cillum dolore eu fugiat nulla pariatur.
                      </div>
                      <Link to="/room-details">
                        <a href="/home" className="theme-btn style-two">
                          <span>Our Services</span>
                        </a>
                      </Link>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    className="tab-pane fade"
                    id="tab-3"
                    role="tabpanel"
                    eventKey="tab-3"
                  >
                    <div className="block-thirty-six">
                      <div className="image">
                        <img
                          src={image48}
                          alt=""
                        />
                      </div>
                      <div className="text">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit, sed do eiusmod tem por incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, qu quat.
                        Duis aute irure dolor in reprehenderit in voluptate
                        velit esse cillum dolore eu fugiat nulla pariatur.
                      </div>
                      <Link to="/room-details">
                        <a href="/home" className="theme-btn style-two">
                          <span>Our Services</span>
                        </a>
                      </Link>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    className="tab-pane fade"
                    id="tab-4"
                    role="tabpanel"
                    eventKey="tab-4"
                  >
                    <div className="block-thirty-six">
                      <div className="image">
                        <img
                          src={image48}
                          alt=""
                        />
                      </div>
                      <div className="text">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit, sed do eiusmod tem por incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, qu quat.
                        Duis aute irure dolor in reprehenderit in voluptate
                        velit esse cillum dolore eu fugiat nulla pariatur.
                      </div>
                      <Link to="/room-details">
                        <a href="/home" className="theme-btn style-two">
                          <span>Our Services</span>
                        </a>
                      </Link>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    className="tab-pane fade"
                    id="tab-5"
                    role="tabpanel"
                    eventKey="tab-5"
                  >
                    <div className="block-thirty-six">
                      <div className="image">
                        <img
                          src={image48}
                          alt=""
                        />
                      </div>
                      <div className="text">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit, sed do eiusmod tem por incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, qu quat.
                        Duis aute irure dolor in reprehenderit in voluptate
                        velit esse cillum dolore eu fugiat nulla pariatur.
                      </div>
                      <Link to="/room-details">
                        <a href="/home" className="theme-btn style-two">
                          <span>Our Services</span>
                        </a>
                      </Link>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </div>
              <div className="col-lg-5">
                <div className="sub-title">Why Choose</div>
                <h2 className="sec-title">Why Choose Us</h2>
                <div className="nav-tab-wrapper">
                  <Nav
                    as={"ul"}
                    className="nav nav-tabs"
                    id="myTab"
                    role="tablist"
                  >
                    <Nav.Item
                      as={"li"}
                      className="nav-item"
                      role="presentation"
                    >
                      <Nav.Link
                        as={"button"}
                        className="nav-link"
                        data-bs-toggle="tab"
                        eventKey="tab-1"
                        type="button"
                        role="tab"
                        aria-selected="true"
                      >
                        <span>
                          <i className="flaticon-room" /> Our Rooms
                        </span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item
                      as={"li"}
                      className="nav-item"
                      role="presentation"
                    >
                      <Nav.Link
                        as={"button"}
                        className="nav-link"
                        data-bs-toggle="tab"
                        eventKey="tab-2"
                        type="button"
                        role="tab"
                        aria-selected="false"
                      >
                        <span>
                          <i className="flaticon-kitchen-set" /> Room Setup
                        </span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item
                      as={"li"}
                      className="nav-item"
                      role="presentation"
                    >
                      <Nav.Link
                        as={"button"}
                        className="nav-link"
                        data-bs-toggle="tab"
                        eventKey="tab-3"
                        type="button"
                        role="tab"
                        aria-selected="false"
                      >
                        <span>
                          <i className="flaticon-plane" /> Transport
                        </span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item
                      as={"li"}
                      className="nav-item"
                      role="presentation"
                    >
                      <Nav.Link
                        as={"button"}
                        className="nav-link"
                        data-bs-toggle="tab"
                        eventKey="tab-4"
                        type="button"
                        role="tab"
                        aria-selected="false"
                      >
                        <span>
                          <i className="flaticon-cheers" />
                          Bar &amp; Drinks
                        </span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item
                      as={"li"}
                      className="nav-item"
                      role="presentation"
                    >
                      <Nav.Link
                        as={"button"}
                        className="nav-link"
                        data-bs-toggle="tab"
                        eventKey="tab-5"
                        type="button"
                        role="tab"
                        aria-selected="false"
                      >
                        <span>
                          <i className="flaticon-game-controller" />
                          Gaming Zone
                        </span>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </div>
            </div>
          </Tab.Container>
        </div>
      </section> */}
    </Layouts>
  );
};
export default About;
