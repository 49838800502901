import React from "react";
import Button from "../Button";

import "./style.css";

export default function Review({ item, isAdmin, approveReview, deleteReview }) {
  return (
    <div className="review-wrapper">
      <div className="rating">
        <ul>
          {[...Array(5)].map((star, i) => {
            if (i + 1 <= item.stars) {
              return (
                <li key={"star" + i}>
                  <i className="fas fa-star"></i>
                </li>
              );
            } else {
              return (
                <li key={"star" + i}>
                  <i className="far fa-star"></i>
                </li>
              );
            }
          })}
        </ul>
      </div>
      <div className="quote">
        <p>{item.text}</p>
      </div>
      <div className="client-info">
        <div className="client-text">
          <h5>{item.senderName}</h5>
          {isAdmin && <p>{item.email}</p>}
        </div>
      </div>
      {isAdmin && (
        <div className="review-admin-buttons">
          {item.status === "pending" && (
            <Button text="Одобри" onClick={() => approveReview(item)} />
          )}
          <Button
            text="Изтрий"
            classes={["delete-button"]}
            onClick={deleteReview}
          />
        </div>
      )}
    </div>
  );
}
