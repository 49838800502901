import DoubleStandardDeluxeMain from "../assets/images/rooms/deluxe-double.jpg";
import DoubleStandardDeluxe1 from "../assets/images/rooms/deluxe-double.jpg";
import DoubleStandardDeluxe2 from "../assets/images/rooms/deluxe-double-2.jpg";
import DoubleStandardDeluxe3 from "../assets/images/rooms/deluxe-double-3.jpg";
import DoubleStandardDeluxe4 from "../assets/images/rooms/deluxe-double-4.jpg";
import DoubleStandardDeluxe5 from "../assets/images/rooms/deluxe-double-5.jpg";

import DoubleSeaDeluxeMain from "../assets/images/rooms/deluxe-double-sea.jpg";
import DoubleSeaDeluxe1 from "../assets/images/rooms/deluxe-double-sea.jpg";
import DoubleSeaDeluxe2 from "../assets/images/rooms/deluxe-double-sea-2.jpg";
import DoubleSeaDeluxe3 from "../assets/images/rooms/deluxe-double-sea-3.jpg";
import DoubleSeaDeluxe4 from "../assets/images/rooms/deluxe-double-sea-4.jpg";
import DoubleSeaDeluxe5 from "../assets/images/rooms/deluxe-double-sea-5.jpg";

import DoubleSuperiorDeluxeMain from "../assets/images/rooms/deluxe-superior.jpg";
import DoubleSuperiorDeluxe1 from "../assets/images/rooms/deluxe-superior.jpg";
import DoubleSuperiorDeluxe2 from "../assets/images/rooms/deluxe-superior-2.jpg";
import DoubleSuperiorDeluxe3 from "../assets/images/rooms/deluxe-superior-3.jpg";
import DoubleSuperiorDeluxe4 from "../assets/images/rooms/deluxe-superior-4.jpg";

import JuniorSuiteDeluxeMain from "../assets/images/rooms/deluxe-junior-suite.jpg";
import JuniorSuiteDeluxe1 from "../assets/images/rooms/deluxe-junior-suite.jpg";
import JuniorSuiteDeluxe2 from "../assets/images/rooms/deluxe-junior-suite-2.jpg";
import JuniorSuiteDeluxe3 from "../assets/images/rooms/deluxe-junior-suite-3.jpg";
import JuniorSuiteDeluxe4 from "../assets/images/rooms/deluxe-junior-suite-4.jpg";

import ApartmentDeluxeMain from "../assets/images/rooms/deluxe-apartment.jpg";
import ApartmentDeluxe1 from "../assets/images/rooms/deluxe-apartment.jpg";
import ApartmentDeluxe2 from "../assets/images/rooms/deluxe-apartment-2.jpg";
import ApartmentDeluxe3 from "../assets/images/rooms/deluxe-apartment-3.jpg";

import ApartmentBoutique from "../assets/images/rooms/apartment-boutique.jpeg";
import ApartmentBoutique2 from "../assets/images/rooms/apartment-boutique-2.jpeg";
import ApartmentBoutique3 from "../assets/images/rooms/apartment-boutique-3.jpeg";

const mainFeatures = [
  "showerhead",
  "balcon",
  "phone",
  "ergonomic",
  "TV-55",
  "vault",
  "nespresso",
  "cosmetics",
  "touch-light",
  "AC",
  "dryer",
];

const RoomInfo = {
  "double-standard-deluxe": {
    name: "Двойна стая изглед море DeLuxe",
    view: "park",
    image: DoubleStandardDeluxeMain,
    size: 23,
    bed: "",
    adults: 2,
    children: 1,
    roomKey: "double-standard-deluxe",
    features: mainFeatures,
    image1: DoubleStandardDeluxe1,
    image2: DoubleStandardDeluxe2,
    image3: DoubleStandardDeluxe3,
    image4: DoubleStandardDeluxe4,
    image5: DoubleStandardDeluxe5,
    maxOccupancy: [
      { adults: 2, children: 1 },
      { adults: 3, children: 0 },
    ],
  },
  "double-sea-deluxe": {
    name: "",
    view: "sea",
    image: DoubleSeaDeluxeMain,
    size: 23,
    bed: "",
    adults: 2,
    children: 1,
    roomKey: "double-sea-deluxe",
    features: mainFeatures,
    image1: DoubleSeaDeluxe1,
    image2: DoubleSeaDeluxe2,
    image3: DoubleSeaDeluxe3,
    image4: DoubleSeaDeluxe4,
    image5: DoubleSeaDeluxe5,
    maxOccupancy: [
      { adults: 2, children: 1 },
      { adults: 3, children: 0 },
    ],
  },
  "double-sea-superior-deluxe": {
    name: "",
    view: "sea",
    image: DoubleSuperiorDeluxeMain,
    size: 41,
    bed: "",
    adults: 2,
    children: 2,
    roomKey: "double-sea-superior-deluxe",
    features: mainFeatures,
    image1: DoubleSuperiorDeluxe1,
    image2: DoubleSuperiorDeluxe2,
    image3: DoubleSuperiorDeluxe3,
    image4: DoubleSuperiorDeluxe4,
    maxOccupancy: [
      { adults: 2, children: 2 },
      { adults: 3, children: 0 },
    ],
  },
  "junior-suit-deluxe": {
    name: "",
    view: "sea",
    image: JuniorSuiteDeluxeMain,
    size: 57,
    bed: "",
    adults: 2,
    children: 2,
    roomKey: "junior-suit-deluxe",
    features: mainFeatures,
    image1: JuniorSuiteDeluxe1,
    image2: JuniorSuiteDeluxe2,
    image3: JuniorSuiteDeluxe3,
    image4: JuniorSuiteDeluxe4,
    maxOccupancy: [
      { adults: 2, children: 2 },
      { adults: 3, children: 0 },
    ],
  },
  "apartment-deluxe": {
    name: "",
    view: "sea",
    image: ApartmentDeluxeMain,
    size: 50,
    bed: "",
    adults: 2,
    children: 2,
    roomKey: "apartment-deluxe",
    features: mainFeatures,
    image1: ApartmentDeluxe1,
    image2: "",
    image3: ApartmentDeluxe3,
    image4: ApartmentDeluxe2,
    image5: "",
    maxOccupancy: [
      { adults: 2, children: 2 },
      { adults: 3, children: 0 },
    ],
  },
  "boutique-apartment": {
    name: "",
    view: "sea",
    image: ApartmentBoutique,
    size: 200,
    bed: "",
    adults: 2,
    children: 2,
    roomKey: "boutique-apartment",
    features: [
      "showerhead",
      "balcon",
      "phone",
      "coffee-machine",
      "couch",
      "TV-75",
      "vault",
      "AC",
      "dryer",
    ],
    image1: "",
    image2: "",
    image3: ApartmentBoutique,
    image4: ApartmentBoutique2,
    image5: ApartmentBoutique3,
    maxOccupancy: [
      { adults: 2, children: 2 },
      { adults: 3, children: 0 },
    ],
  },
};

const boardPrices = {
  "bed-and-breakfast": 0,
  "half-board": 30,
  "full-board": 55,
  "all-light": 75,
};

export default RoomInfo;
export { boardPrices };
