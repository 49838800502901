import { Link } from "react-router-dom";
import React, { useState } from "react";
import PageBanner from "../components/PageBanner";
import Layouts from "../layouts/Layouts";
import DatePicker from "../components/DatePicker";

const RoomList = () => {
  const [date, setDate] = useState(null);
  const [date2, setDate2] = useState(null);
  return (
    <Layouts>
      <PageBanner pageName={"Room List"} />

      <div className="check-availability">
        <div className="auto-container">
          <form className="form" onSubmit={(e) => e.preventDefault()}>
            <div className="left-side">
              <ul>
                <li>
                  {/* <input
                    type="date"
                    placeholder="Arrival Date"
                    className="datepicker"
                  />{" "} */}
                  {/* <i className="far fa-calendar-alt" /> */}

                  <DatePicker
                    date={date}
                    onChange={setDate}
                    placeholder={`Arrival Date`}
                  />
                </li>
                <li>
                  <DatePicker
                    date={date2}
                    onChange={setDate2}
                    placeholder={`Departure Date`}
                  />
                </li>
                <li>
                  <select>
                    <option data-display="Aduls">Aduls</option>
                    <option value={1}>0 Adul</option>
                    <option value={2}>1 Adul</option>
                    <option value={4}>2 Aduls</option>
                    <option value={4}>3 Aduls</option>
                    <option value={4}>4 Aduls</option>
                    <option value={4}>5 Aduls</option>
                  </select>
                </li>
                <li>
                  <select>
                    <option data-display="Childrens">Childrens</option>
                    <option value={1}>0 Children</option>
                    <option value={2}>1 Children</option>
                    <option value={4}>2 Childrens</option>
                    <option value={4}>3 Childrens</option>
                    <option value={4}>4 Childrens</option>
                    <option value={4}>5 Childrens</option>
                  </select>
                </li>
              </ul>
            </div>
            <div className="right-side">
              <button type="submit">check availability</button>
            </div>
          </form>
        </div>
      </div>

      <section className="section-thirty-eight">
        <div className="auto-container">
          <div className="row">
            <div className="col-lg-4">
              <div className="image">
                <img
                  src="../public/assets/images/resource/image-49.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-8">
              <div className="block-thirty-eight">
                <div className="icon-list">
                  <ul>
                    <li>
                      <i className="flaticon-user" />
                      <h4>Capacity</h4>
                      <div className="text">4 Person</div>
                    </li>
                    <li>
                      <i className="flaticon-preview" />
                      <h4>Size</h4>
                      <div className="text">600sqm</div>
                    </li>
                    <li>
                      <i className="flaticon-view" />
                      <h4>View</h4>
                      <div className="text">City, Ocean</div>
                    </li>
                  </ul>
                </div>
                <h3>Delux Queen Luxury Room</h3>
                <div className="text-two">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod
                </div>
                <div className="inner-box">
                  <div className="pricing">150/Night</div>
                  <Link to="/room-details">
                    <a href="/home" className="theme-btn btn-style-four">
                      <span>View Room</span>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="image">
                <img
                  src="../public/assets/images/resource/image-50.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-8">
              <div className="block-thirty-eight">
                <div className="icon-list">
                  <ul>
                    <li>
                      <i className="flaticon-user" />
                      <h4>Capacity</h4>
                      <div className="text">4 Person</div>
                    </li>
                    <li>
                      <i className="flaticon-preview" />
                      <h4>Size</h4>
                      <div className="text">600sqm</div>
                    </li>
                    <li>
                      <i className="flaticon-view" />
                      <h4>View</h4>
                      <div className="text">City, Ocean</div>
                    </li>
                  </ul>
                </div>
                <h3>Delux Queen Luxury Room</h3>
                <div className="text-two">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod
                </div>
                <div className="inner-box">
                  <div className="pricing">150/Night</div>
                  <Link to="/room-details">
                    <a href="/home" className="theme-btn btn-style-four">
                      <span>View Room</span>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="image">
                <img
                  src="../public/assets/images/resource/image-51.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-8">
              <div className="block-thirty-eight">
                <div className="icon-list">
                  <ul>
                    <li>
                      <i className="flaticon-user" />
                      <h4>Capacity</h4>
                      <div className="text">4 Person</div>
                    </li>
                    <li>
                      <i className="flaticon-preview" />
                      <h4>Size</h4>
                      <div className="text">600sqm</div>
                    </li>
                    <li>
                      <i className="flaticon-view" />
                      <h4>View</h4>
                      <div className="text">City, Ocean</div>
                    </li>
                  </ul>
                </div>
                <h3>Delux Queen Luxury Room</h3>
                <div className="text-two">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod
                </div>
                <div className="inner-box">
                  <div className="pricing">150/Night</div>
                  <Link to="/room-details">
                    <a href="/home" className="theme-btn btn-style-four">
                      <span>View Room</span>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="image">
                <img
                  src="../public/assets/images/resource/image-52.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-8">
              <div className="block-thirty-eight">
                <div className="icon-list">
                  <ul>
                    <li>
                      <i className="flaticon-user" />
                      <h4>Capacity</h4>
                      <div className="text">4 Person</div>
                    </li>
                    <li>
                      <i className="flaticon-preview" />
                      <h4>Size</h4>
                      <div className="text">600sqm</div>
                    </li>
                    <li>
                      <i className="flaticon-view" />
                      <h4>View</h4>
                      <div className="text">City, Ocean</div>
                    </li>
                  </ul>
                </div>
                <h3>Delux Queen Luxury Room</h3>
                <div className="text-two">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod
                </div>
                <div className="inner-box">
                  <div className="pricing">150/Night</div>
                  <Link to="/room-details">
                    <a href="/home" className="theme-btn btn-style-four">
                      <span>View Room</span>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="image">
                <img
                  src="../public/assets/images/resource/image-53.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-8">
              <div className="block-thirty-eight">
                <div className="icon-list">
                  <ul>
                    <li>
                      <i className="flaticon-user" />
                      <h4>Capacity</h4>
                      <div className="text">4 Person</div>
                    </li>
                    <li>
                      <i className="flaticon-preview" />
                      <h4>Size</h4>
                      <div className="text">600sqm</div>
                    </li>
                    <li>
                      <i className="flaticon-view" />
                      <h4>View</h4>
                      <div className="text">City, Ocean</div>
                    </li>
                  </ul>
                </div>
                <h3>Delux Queen Luxury Room</h3>
                <div className="text-two">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod
                </div>
                <div className="inner-box">
                  <div className="pricing">150/Night</div>
                  <Link to="/room-details">
                    <a href="/home" className="theme-btn btn-style-four">
                      <span>View Room</span>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="image">
                <img
                  src="../public/assets/images/resource/image-54.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-8">
              <div className="block-thirty-eight">
                <div className="icon-list">
                  <ul>
                    <li>
                      <i className="flaticon-user" />
                      <h4>Capacity</h4>
                      <div className="text">4 Person</div>
                    </li>
                    <li>
                      <i className="flaticon-preview" />
                      <h4>Size</h4>
                      <div className="text">600sqm</div>
                    </li>
                    <li>
                      <i className="flaticon-view" />
                      <h4>View</h4>
                      <div className="text">City, Ocean</div>
                    </li>
                  </ul>
                </div>
                <h3>Delux Queen Luxury Room</h3>
                <div className="text-two">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod
                </div>
                <div className="inner-box">
                  <div className="pricing">150/Night</div>
                  <Link to="/room-details">
                    <a href="/home" className="theme-btn btn-style-four">
                      <span>View Room</span>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="image">
                <img
                  src="../public/assets/images/resource/image-55.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-8">
              <div className="block-thirty-eight">
                <div className="icon-list">
                  <ul>
                    <li>
                      <i className="flaticon-user" />
                      <h4>Capacity</h4>
                      <div className="text">4 Person</div>
                    </li>
                    <li>
                      <i className="flaticon-preview" />
                      <h4>Size</h4>
                      <div className="text">600sqm</div>
                    </li>
                    <li>
                      <i className="flaticon-view" />
                      <h4>View</h4>
                      <div className="text">City, Ocean</div>
                    </li>
                  </ul>
                </div>
                <h3>Delux Queen Luxury Room</h3>
                <div className="text-two">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod
                </div>
                <div className="inner-box">
                  <div className="pricing">150/Night</div>
                  <Link to="/room-details">
                    <a href="/home" className="theme-btn btn-style-four">
                      <span>View Room</span>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layouts>
  );
};
export default RoomList;
