import React from "react";
import PageBanner from "../components/PageBanner";
import Layouts from "../layouts/Layouts";
import { useTranslation } from "react-i18next";
import { Icon } from "../components/Icon/Icon";

import restaurant from "../assets/images/restaurant.jpg";
import restaurant2 from "../assets/images/restaurant2.jpg";
import restaurant3 from "../assets/images/restaurant3.jpg";
import restaurant4 from "../assets/images/restaurant6.jpg";
import restaurant5 from "../assets/images/restaurant5.jpg";

import lobbyBar2 from "../assets/images/lobby-bar2.jpg";

// import confectionery from "../assets/images/confectionery-1.jpg";
import confectionery2 from "../assets/images/confectionery-2.jpg";
import confectionery3 from "../assets/images/confectionery-3.jpg";
import confectionery4 from "../assets/images/confectionery-4.jpg";
import confectionery5 from "../assets/images/confectionery-5.jpg";

import loungeBar from "../assets/images/lounge-bar-1.jpg";
import loungeBar2 from "../assets/images/lounge-bar-2.jpg";
import loungeBar3 from "../assets/images/lounge-bar-3.jpg";
import loungeBar4 from "../assets/images/lounge-bar-4.jpg";
import loungeBar5 from "../assets/images/lounge-bar-5.jpg";

import swimUp from "../assets/images/swim-up-bar-1.jpg";
// import swimUp2 from "../assets/images/swim-up-bar-2.jpg";
import swimUp3 from "../assets/images/swim-up-bar-3.jpg";
import swimUp4 from "../assets/images/swim-up-bar-4.jpg";
import swimUp5 from "../assets/images/swim-up-bar-5.jpg";

import azzurro from "../assets/images/azzurro-1.jpg";
import azzurro2 from "../assets/images/azzurro-2.jpg";
// import azzurro3 from "../assets/images/azzurro-3.jpg";
import azzurro4 from "../assets/images/azzurro-4.jpg";
import azzurro5 from "../assets/images/azzurro-5.jpg";

import asianSamba from "../assets/images/asian-samba-1.jpg";
import asianSamba2 from "../assets/images/asian-samba-2.jpg";
import asianSamba3 from "../assets/images/asian-samba-3.jpg";
import asianSamba4 from "../assets/images/asian-samba-4.jpg";
import asianSamba5 from "../assets/images/asian-samba-5.jpg";

// import amore from "../assets/images/amore.jpg";
// import amore2 from "../assets/images/amore2.jpg";
// import amore3 from "../assets/images/amore3.jpg";
// import amore4 from "../assets/images/amore4.jpg";
// import amore5 from "../assets/images/amore5.jpg";

import tortuga from "../assets/images/tortuga.jpg";
import tortuga2 from "../assets/images/tortuga2.jpg";
import tortuga3 from "../assets/images/tortuga3.jpg";
import tortuga4 from "../assets/images/tortuga4.jpg";
import tortuga5 from "../assets/images/tortuga5.jpg";
import tortuga6 from "../assets/images/tortuga6.jpg";

// import bbq from "../assets/images/bbq.webp";
// import useWindowDimensions from "../utils/useWindowsDimensions";

const Dining = () => {
  const { t } = useTranslation();

  return (
    <Layouts>
      <PageBanner pageName={t("dining")} />

      <MainRestaurant t={t} />
      <Azzurro t={t} />
      <AsianSamba t={t} />
      {/* <Amore t={t} /> */}
      <LobbyBar t={t} />
      <ViennishBakery t={t} />
      {/* <Affair t={t} /> */}
      <BarMezanine t={t} />
      <PoolBar t={t} />
      <Tortuga t={t} />
    </Layouts>
  );
};
export default Dining;

const Azzurro = ({ t }) => {
  return (
    <section className="section-fourty-five">
      <div className="auto-container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 leading-image">
            <div className="image">
              <iframe
                src="https://www.youtube.com/embed/3ezU-SAN8_E?mute=1&autoplay=1&loop=1&playlist=3ezU-SAN8_E"
                style={{ width: "100%", minHeight: "400px" }}
                // src="https://player.vimeo.com/video/733635604?h=baa2033c9b"
                title={t("azzurro")}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="block-fourty-five">
              <div className="icon mb-10 dining__restaurant">
                <Icon icon="fork-knife" />
                <h2 className="sec-title">{t("azzurro")}</h2>
              </div>
              <div className="text">{t("azzurro-text")}</div>
              <div className="text-two">
                <p>{t("azzurro-working-hours-1")}</p>
                <p>{t("azzurro-working-hours")}</p>
                <p>{t("azzurro-working-hours-2")}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="image">
              <img src={azzurro} alt="" />
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="image">
              <img src={azzurro2} alt="" />
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="image">
              <img src={azzurro4} alt="" />
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="image">
              <img src={azzurro5} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const AsianSamba = ({ t }) => {
  return (
    <section className="section-fourty-five">
      <div className="auto-container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 leading-image">
            <div className="image">
              <img src={asianSamba3} alt="" className="asian-samba-main" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="block-fourty-five">
              <div className="icon mb-10 dining__restaurant">
                <Icon icon="fork-knife" />
                <h2 className="sec-title">{t("asian-samba")}</h2>
              </div>
              <div className="text">{t("asian-samba-text")}</div>
              <div className="text">{t("asian-samba-text-2")}</div>
              <div className="text-two">
                <p>{t("asian-samba-working-hours")}</p>
                {/* <p>{t("asian-samba-working-hours-2")}</p> */}
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 mobile-image">
            <div className="image">
              <img src={asianSamba3} alt="" />
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="image">
              <img src={asianSamba2} alt="" />
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="image">
              <img src={asianSamba} alt="" />
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="image">
              <img src={asianSamba4} alt="" />
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="image">
              <img src={asianSamba5} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

// const Amore = ({ t }) => {
//   return (
//     <section className="section-fourty-five">
//       <div className="auto-container">
//         <div className="row align-items-center">
//           <div className="col-lg-6 col-md-12 leading-image">
//             <div className="image">
//               <img src={amore} alt="" />
//             </div>
//           </div>
//           <div className="col-lg-6">
//             <div className="block-fourty-five">
//               <div className="icon mb-10 dining__restaurant">
//                 <Icon icon="fork-knife" />
//                 <h2 className="sec-title">{t("amore")}</h2>
//               </div>
//               <div className="text">{t("amore-text")}</div>
//               <div className="text">{t("amore-text-3")}</div>
//               <div className="text">{t("amore-text-2")}</div>
//               <div className="text-two">
//                 <p>{t("amore-working-hours")}</p>
//                 <p className="dining__amore__note">{t("amore-note")}</p>
//               </div>
//             </div>
//           </div>
//           <div className="col-lg-6 col-md-6 mobile-image">
//             <div className="image">
//               <img src={amore} alt="" />
//             </div>
//           </div>
//           {/* <div className="col-lg-3 col-md-6">
//             <div className="image">
//               <img src={amore2} alt="" />
//             </div>
//           </div>
//           <div className="col-lg-3 col-md-6">
//             <div className="image">
//               <img src={amore3} alt="" />
//             </div>
//           </div>
//           <div className="col-lg-3 col-md-6">
//             <div className="image">
//               <img src={amore4} alt="" />
//             </div>
//           </div>
//           <div className="col-lg-3 col-md-6">
//             <div className="image">
//               <img src={amore5} alt="" />
//             </div>
//           </div> */}
//         </div>
//       </div>
//     </section>
//   );
// };

const MainRestaurant = ({ t }) => {
  return (
    <section className="section-fourty-five">
      <div className="auto-container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 leading-image">
            <div className="image">
              <img src={restaurant} alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="block-fourty-five">
              <div className="icon mb-10 dining__restaurant">
                <Icon icon="fork-knife" />
                <h2 className="sec-title">{t("restaurant")}</h2>
              </div>
              <div className="text">
                {t("restaurant-text")}
                <p>{t("restaurant-text-2")}</p>
              </div>
              <div className="text-two">
                {t("restaurant-working-hours")}
                <p></p>
                {t("restaurant-working-hours-1")}
                <p></p>
                {t("restaurant-working-hours-2")}
                <p></p>
                {t("restaurant-working-hours-3")}
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="image">
              <img src={restaurant2} alt="" />
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="image">
              <img src={restaurant3} alt="" />
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="image">
              <img src={restaurant4} alt="" />
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="image">
              <img src={restaurant5} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const LobbyBar = ({ t }) => {
  return (
    <section className="section-fourty-five">
      <div className="auto-container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 leading-image">
            <div className="image">
              <img src={lobbyBar2} alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="block-fourty-five">
              <div className="icon mb-10 dining__restaurant">
                <i className="flaticon-cheers" />
                <h2 className="sec-title">{t("lobby-bar")}</h2>
              </div>
              <div className="text">{t("lobby-bar-text")}</div>
              <div className="text-two">{t("lobby-bar-working-hours")}</div>
              {/* <div className="text-two">
              {t("lobby-bar-working-hours")}
            </div> */}
            </div>
          </div>
          <div className="col-lg-6 col-md-12 mobile-image">
            <div className="image">
              <img src={lobbyBar2} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const ViennishBakery = ({ t }) => {
  return (
    <section className="section-fourty-five">
      <div className="auto-container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 leading-image">
            <div className="image">
              <iframe
                src="https://www.youtube.com/embed/uGDjhz5VPWo?start=3&autoplay=1&mute=1"
                style={{ width: "100%", minHeight: "400px" }}
                title={t("confectionery")}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              />
              {/* <img src={confectionery3} alt="" /> */}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="block-fourty-five">
              <div className="icon mb-10 dining__restaurant">
                <i className="flaticon-croissant" />
                <h2 className="sec-title">{t("confectionery")}</h2>
              </div>
              <div className="text">{t("confectionery-text")}</div>
              <div className="text-two">{t("confectionery-working-hours")}</div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 mobile-image">
            <div className="image">
              <iframe
                src="https://www.youtube.com/embed/uGDjhz5VPWo?start=3&autoplay=1&mute=1"
                style={{ width: "100%", minHeight: "350px" }}
                title={`${t("confectionery")}1234`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="image">
              <img src={confectionery3} alt="" />
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="image">
              <img src={confectionery2} alt="" />
            </div>
          </div>
          {/* <div className="col-lg-3 col-md-6">
            <div className="image">
              <img src={confectionery3} alt="" />
            </div>
          </div> */}
          <div className="col-lg-3 col-md-6">
            <div className="image">
              <img src={confectionery4} alt="" />
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="image">
              <img src={confectionery5} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const PoolBar = ({ t }) => {
  return (
    <section className="section-fourty-five">
      <div className="auto-container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 leading-image">
            <div className="image">
              <iframe
                src="https://www.youtube.com/embed/lTJ47MrWF28?mute=1&autoplay=1&loop=1&playlist=lTJ47MrWF28"
                style={{ width: "100%", minHeight: "349px" }}
                // src="https://player.vimeo.com/video/733635604?h=baa2033c9b"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
                title={t("swim-up")}
              ></iframe>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="block-fourty-five">
              <div className="icon mb-10 dining__restaurant">
                <i className="flaticon-martini" />
                <h2 className="sec-title">{t("swim-up")}</h2>
              </div>
              <div className="text">{t("swim-up-text")}</div>
              <div className="text-two">{t("swim-up-working-hours")}</div>
              {/* <div className="text-two">
              {t("bar-mezzanine-working-hours")}
            </div> */}
            </div>
          </div>
          <div className="col-lg-6 col-md-12 mobile-image">
            <div className="image">
              <iframe
                src="https://www.youtube.com/embed/lTJ47MrWF28?mute=1&autoplay=1&loop=1&playlist=lTJ47MrWF28"
                style={{ width: "100%", minHeight: "400px" }}
                // src="https://player.vimeo.com/video/733635604?h=baa2033c9b"
                title={t("azzurro") + "1234"}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="image">
              <img src={swimUp} alt="" />
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="image">
              <img src={swimUp3} alt="" />
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="image">
              <img src={swimUp4} alt="" />
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="image">
              <img src={swimUp5} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const BarMezanine = ({ t }) => {
  return (
    <section className="section-fourty-five">
      <div className="auto-container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 leading-image">
            <div className="image">
              <img src={loungeBar} alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="block-fourty-five">
              <div className="icon mb-10 dining__restaurant">
                <i className="flaticon-martini" />
                <h2 className="sec-title">{t("bar-mezzanine")}</h2>
              </div>
              <div className="text">{t("bar-mezzanine-text")}</div>
              <div className="text">{t("bar-mezzanine-text-2")}</div>
              <div className="text">{t("bar-mezzanine-text-3")}</div>
              <div className="text-two">{t("bar-mezzanine-working-hours")}</div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 mobile-image">
            <div className="image">
              <img src={loungeBar} alt="" />
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="image">
              <img src={loungeBar2} alt="" />
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="image">
              <img src={loungeBar3} alt="" />
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="image">
              <img src={loungeBar4} alt="" />
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="image">
              <img src={loungeBar5} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const Tortuga = ({ t }) => {
  return (
    <section className="section-fourty-five">
      <div className="auto-container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 leading-image">
            <div className="image">
              <img src={tortuga3} alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="block-fourty-five">
              <div className="icon mb-10 dining__restaurant">
                <Icon icon="fork-knife" />
                <h2 className="sec-title">{t("tortuga")}</h2>
              </div>
              <div className="text">{t("tortuga-text")}</div>
              <div className="text">{t("tortuga-text-2")}</div>
              <div className="text">{t("tortuga-text-3")}</div>
              <div className="text-two">
                <p>{t("tortuga-working-hours")}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 mobile-image">
            <div className="image">
              <img src={tortuga} alt="" />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 mobile-image">
            <div className="image">
              <img src={tortuga3} alt="" />
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="image">
              <img src={tortuga2} alt="" className="tortuga-image" />
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="image">
              <img src={tortuga5} alt="" className="tortuga-image" />
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="image">
              <img src={tortuga4} alt="" className="tortuga-image" />
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="image">
              <img src={tortuga6} alt="" className="tortuga-image" />
            </div>
          </div>
          {/*<div className="col-lg-3 col-md-6">
            <div className="image">
              <img src={tortuga3} alt="" />
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="image">
              <img src={tortuga4} alt="" />
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="image">
              <img src={tortuga5} alt="" />
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

// const Affair = ({ t }) => {
//   return (
//     <section className="section-fourty-five">
//       <div className="auto-container">
//         <div className="row align-items-center">
//           <div className="col-lg-6 col-md-12 leading-image">
//             <div className="image">
//               <img src={bbq} alt="" />
//             </div>
//           </div>
//           <div className="col-lg-6">
//             <div className="block-fourty-five">
//               <div className="icon mb-10 dining__restaurant">
//                 <Icon icon="fork-knife" />
//                 <h2 className="sec-title">{t("affair-bbq")}</h2>
//               </div>
//               <div className="text">{t("affair-bbq-text")}</div>
//               <div className="text-two">
//                 <p>{t("affair-working-hours")}</p>
//               </div>
//             </div>
//           </div>
//           <div className="col-lg-6 col-md-12 mobile-image">
//             <div className="image">
//               <img src={bbq} alt="" />
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };
