import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import RoomInfo from "../utils/RoomInfo";
import { useTranslation } from "react-i18next";
import periods from "../utils/periods";
import roomService from "../services/roomService";
import getRoomPrice from "../utils/getRoomPriceWithTax";
import { Icon } from "./Icon/Icon";

export default function RoomSection({
  rClass,
  data,
  roomsToDisplay,
  isRoomsLoading,
}) {
  let rooms = roomsToDisplay ? roomsToDisplay : Object.keys(RoomInfo);
  const history = useHistory();
  const { t } = useTranslation();

  const [roomsPrices, setRoomsPrices] = useState({});

  useEffect(() => {
    roomService.getRooms().then((res) => {
      const roomData = res.data;
      let prices = {};
      let currentPeriod;
      const today = new Date();
      Object.keys(periods).forEach((period) => {
        periods[period].forEach((interval) => {
          const dates = interval.split(" - ");
          const startDate = new Date(dates[0]);
          const endDate = new Date(dates[1]);
          if (today >= startDate && today <= endDate) {
            currentPeriod = period;
          }
        });
      });
      Object.keys(RoomInfo).forEach((room) => {
        const currentRoomInfo = roomData.find((x) => x.name === room);
        const currentRoomPrice = currentRoomInfo[currentPeriod + "Price"];
        prices[room] = getRoomPrice(currentRoomPrice);
      });
      setRoomsPrices(prices);
    });
  }, []);

  const handleRoomClick = (room) => {
    window.scrollTo(10, 0);
    history.push({
      pathname: "/room-single",
      state: {
        name: room.roomKey,
        price: roomsPrices[room.roomKey],
        isPriceFormatted: false,
      },
    });
  };

  return (
    <section className="section-twenty-five">
      <h3 className="our-rooms-heading">{t("our-rooms")}</h3>
      <div className="auto-container">
        <div className="row">
          {rooms.map((room, index) => {
            return (
              <RoomGridSingle
                room={RoomInfo[room]}
                price={roomsPrices[room]}
                key={index}
                handleRoomClick={handleRoomClick}
                title={room}
                t={t}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
}

const RoomGridSingle = ({ room, handleRoomClick, title, t, price }) => {
  return (
    <div className="col-lg-4">
      <div className="block-twenty-five">
        <div className="image">
          <img src={room.image} alt="" />
        </div>
        <div className="lower-content">
          {price ? (
            <div className="pricing">
              {price}
              {t("bgn")} / {t("night")}
            </div>
          ) : null}
          <div className="icon-list">
            <ul>
              <li>
                <i className="flaticon-user" />
                <h4>{t("capacity-single")}</h4>
                <div style={{ marginBottom: 0 }} className="text">
                  {room.adults} {t("adults")}
                </div>
                <div className="text">
                  {room.children}{" "}
                  {t(room.children === 1 ? "child" : "children")}
                </div>
              </li>
              <li>
                <Icon
                  classes="block-twenty-five__fork-icon"
                  icon="fork-knife"
                />
                <h4>{t("food-board")}</h4>
                <div className="text">{t("ultra-all-inclusive")}</div>
              </li>

              <li>
                <i className="flaticon-view" />
                <h4>{t("view")}</h4>
                <div className="text">{t(room.view)}</div>
              </li>
              <li>
                <i className="flaticon-preview" />
                <h4>{t("size")}</h4>
                <div className="text">
                  {room.size}
                  {t("sqm")}
                </div>
              </li>
            </ul>
          </div>
          <h3>{t(room.roomKey)}</h3>
          <div onClick={() => handleRoomClick(room)}>
            <p className="theme-btn btn-style-four">{t("view-room")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
