import React, { useEffect, useState } from "react";
import Layouts from "../../layouts/Layouts";

export default function MobileAppPage() {
  const [hasOpenStore, setHasOpenStore] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.sevendigit.hoteladmiral&hl=en_US";
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href =
        "https://apps.apple.com/bg/app/hotel-admiral/id6448111991";
    } else {
      // Default case if the user is not on a mobile device
      window.location.href = "https://www.admiral.bg/";
    }
    setHasOpenStore(true);
  }, []);

  useEffect(() => {
    if (hasOpenStore) {
      window.location.href = "https://www.admiral.bg/";
    }
  }, [hasOpenStore]);

  return <Layouts></Layouts>;
}
