import React, { useState } from "react";
import { useTranslation } from "react-i18next";

// Template Images
import logoBig from "../../assets/images/logo-no-background-big.png";
import footerBackground from "../../assets/images/background.jpg";
import { Link } from "react-router-dom";

import Button from "../../components/Button";
import Joi from "joi";
import subscriberService from "../../services/subscribersService";
import { toast } from "react-toastify";
import { validateProperty } from "../../utils/validation";

const schema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
});

const Footer = ({ noBg, extraCls }) => {
  const { t } = useTranslation();
  const clickHandler = () => {
    window.scrollTo(10, 0);
  };
  const leftTabs = ["about", "rooms", "dining", "sport", "events"];
  const rightTabs = ["offers", "reviews", "gallery", "contact"];

  const [subscriber, setSubscriber] = useState("");

  const SubmitHandler = async (e) => {
    console.log(subscriber, "subscriber");
    e.preventDefault();
    if (await validateProperty("email", subscriber, schema, () => {})) {
      try {
        const res = await subscriberService.createSubscriber(subscriber);
        if (res.status === 200) {
          toast.success(t("subscribe-success"));
          setSubscriber("");
        }
      } catch (ex) {
        toast.error(t("wrong"));
      }
    } else {
      toast.error(t("invalid-email"));
    }
  };

  return (
    <footer
      className={`main-footer ${extraCls ? extraCls : "style-two"}`}
      style={{
        background: noBg ? null : `url(${footerBackground})`,
      }}
    >
      <div className="auto-container">
        <div className="widget-wrapper">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="footer-logo">
                <img
                  src={logoBig}
                  alt=""
                  style={{ border: "1px solid", borderRadius: "20px" }}
                />
              </div>
            </div>
            <div className="column col-lg-4 col-md-6">
              <div className="widget links-widget">
                <h4>{t("useful-links")}</h4>
                <div className="row">
                  <div className="col-sm-6">
                    <ul className="navigation">
                      {leftTabs.map((tab, index) => {
                        return (
                          <li key={index}>
                            <Link onClick={clickHandler} to={`/${tab}`}>
                              {t(tab === "rooms" ? "accommodation" : tab)}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="col-sm-6">
                    <ul>
                      {rightTabs.map((tab, index) => {
                        return (
                          <li key={index}>
                            <Link onClick={clickHandler} to={`/${tab}`}>
                              {t(tab)}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <form
                  method="post"
                  className="newsletter-form"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <h5 className="newsletter-header">{t("newsletter")}</h5>
                  <div className="form-group newsletter-input-wrapper">
                    <input
                      type="email"
                      name="email"
                      placeholder={t("email-address")}
                      className="footer-newsletter-input"
                      value={subscriber}
                      onChange={(e) => setSubscriber(e.currentTarget.value)}
                    />
                    <Button
                      classes={["footer-newsletter-button"]}
                      text={t("subscribe")}
                      style={{ borderRadius: 0 }}
                      onClick={SubmitHandler}
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="widget contact-widget">
                <h4>{t("contact")}</h4>
                <ul>
                  <li>
                    <i className="fal fa-phone" />
                    <a href="tel:+35952390200">+359 52 390 200</a>
                  </li>
                  <li>
                    <i className="fal fa-fax" />
                    <a href="tel:+35952355252">+359 52 355 252</a>
                  </li>
                  <li>
                    <i className="fal fa-envelope" />
                    <a href="mailto:reservations@admiral.bg">
                      reservations@admiral.bg
                    </a>
                  </li>
                  <li>
                    <i className="fal fa-map-marker-alt" />
                    {t("hotel-address")}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer Bottom */}
      <div className="footer-bottom">
        <div className="auto-container">
          <div className="wrapper-box">
            <div className="copyright">
              <div className="text">{`${new Date().getFullYear()} ${t(
                "all-rights-reserved"
              )}`}</div>
            </div>
            <div className="brand-logo">
              <div className="row conditions">
                <Link onClick={clickHandler} to="/general-terms" alt="">
                  <div className="text">{t("general-terms")}</div>
                </Link>
                <Link onClick={clickHandler} to="/privacy" alt="">
                  <div className="text">{t("privacy")}</div>
                </Link>
                <Link onClick={clickHandler} to="/terms" alt="">
                  <div className="text">{t("terms")}</div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
