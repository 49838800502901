import React from "react";
import PageBanner from "../components/PageBanner";
import Layouts from "../layouts/Layouts";

import beach from "../assets/images/beach.jpg";
// import outdoorSwimmingPool from "../assets/images/outdoor-swimming-pool.jpg";
import gym from "../assets/images/gym-new.jpg";
// import spa from "../assets/images/spa.jpg";
import rentBicycle from "../assets/images/rent-bicycle.jpg";
import indoorSwimmingPool from "../assets/images/indoor-swimming-pool-new.jpg";
import { useTranslation } from "react-i18next";

const Sport = () => {
  const { t } = useTranslation();
  return (
    <Layouts>
      <PageBanner pageName={t("sport")} />
      <section className="section-sixteen">
        <div className="auto-container">
          <div className="row no-gutters">
            <div className="col-lg-6">
              <div className="block-sixteen">
                {/* <div className="offer-title">Parlour &amp; Salon</div> */}
                <h4>{t("admiral-beach")}</h4>
                <div className="text">{t("admiral-beach-text")}</div>
                <div className="icon">
                  <i className="flaticon-clock" />
                  <span>{t("admiral-beach-working-hours")}</span>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="image">
                <img src={beach} alt="" />
              </div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-lg-6 order-lg-2">
              <div className="block-sixteen">
                {/* <div className="offer-title">Eat &amp; Drinks</div> */}
                <h4>{t("outdoor-swimming-pool")}</h4>
                <div className="text">{t("outdoor-swimming-pool-text")}</div>
                <div className="icon">
                  <i className="flaticon-clock" />
                  <span>{t("outdoor-swimming-pool-working-hours")}</span>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="image sport__spa-video">
                <iframe
                  src="https://www.youtube.com/embed/mv2kCkntHjo?autoplay=1&mute=1&loop=1&playlist=mv2kCkntHjo"
                  title={t("hotel-admiral")}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-lg-6">
              <div className="block-sixteen">
                {/* <div className="offer-title">Rooms</div> */}
                <h4>{t("spa")}</h4>
                <div className="text">{t("spa-text")}</div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="image sport__spa-video">
                {/* <img src={spa} alt="" /> */}
                <iframe
                  src="https://www.youtube.com/embed/Y1v8r1zp3iQ?autoplay=1&mute=1&loop=1&playlist=Y1v8r1zp3iQ"
                  title={t("hotel-admiral")}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-lg-6">
              <div className="image">
                <img src={gym} alt="" />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="block-sixteen">
                {/* <div className="offer-title">Rooms</div> */}
                <h4>{t("gym")}</h4>
                <div className="text">{t("gym-text")}</div>
                <div className="icon">
                  <i className="flaticon-clock" />
                  <span>{t("gym-working-hours")}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-lg-6">
              <div className="block-sixteen">
                {/* <div className="offer-title">Travelling</div> */}
                <h4>{t("indoor-swimming-pool")}</h4>
                <div className="text">{t("indoor-swimming-pool-text")}</div>
                <div className="icon">
                  <i className="flaticon-clock" />
                  <span>{t("indoor-swimming-pool-working-hours")}</span>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="image">
                <img src={indoorSwimmingPool} alt="" />
              </div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-lg-6">
              <div className="image">
                <img src={rentBicycle} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="block-sixteen">
                {/* <div className="offer-title">Travelling</div> */}
                <h4>{t("bicycle-rent")}</h4>
                <div className="text">{t("bicycle-rent-text")}</div>
                <div className="icon">
                  <i className="flaticon-clock" />
                  <span>{t("bicycle-rent-working-hours")}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layouts>
  );
};
export default Sport;
