import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Toaster } from "sonner";

import Root from "./components/Root";
import Context from "./utils/context";
import { getTomorrowDate } from "./utils/date";

function App() {
  const { i18n } = useTranslation();
  const [availabilityData, setAvailabilityData] = useState({
    startDate: new Date(),
    endDate: getTomorrowDate(),
    adults: { value: 1, label: "1" },
    children: { value: 0, label: "0" },
  });
  useEffect(() => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const hasChosenLanguage = localStorage.getItem("hotel-admiral-language");
    if (hasChosenLanguage) {
      i18n.changeLanguage(hasChosenLanguage);
    } else if (tz !== "Europe/Sofia") {
      let newLanguage;
      if (tz === "Europe/Berlin") {
        newLanguage = "de";
      } else {
        newLanguage = "en";
      }
      localStorage.setItem("hotel-admiral-language", newLanguage);
      i18n.changeLanguage(newLanguage);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Toaster
        toastOptions={{
          style: {
            whiteSpace: "pre-line",
            padding: "1rem",
          },
        }}
        duration={6000}
        richColors
        position="top-right"
      />
      <div className="App">
        <Context.Provider
          value={{
            availabilityData,
            setAvailabilityData,
          }}
        >
          <Root />
        </Context.Provider>
        <ToastContainer />
      </div>
    </>
  );
}

export default App;
