import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import Select from "react-select";
import Navbar from "../../components/Navbar";
import Button from "../../components/Button";
import RoomInfo from "../../utils/RoomInfo";
import DatePicker from "../../components/DatePicker";

import Joi from "joi";
import { validate } from "../../utils/validation";
import {
  getDateDB,
  getDatesInRange,
  getDateView,
  getDateViewFromPeriod,
} from "../../utils/date";
import { useTranslation } from "react-i18next";

import periods from "../../utils/periods";

import roomService from "../../services/roomService";
import availabilityService from "../../services/availabilityService";

import "./style.css";
import getTaxedPrice from "../../utils/getRoomPriceWithTax";

const availabilitySchema = Joi.object({
  room: Joi.object().required().label("Моля изберете тип стая !"),
  startDate: Joi.string().required(),
  endDate: Joi.string().required(),
  roomAmmount: Joi.number().required().label("Полето е задължително !"),
});

const initialData = {
  room: "",
  startDate: new Date(),
  endDate: new Date(),
  roomAmmount: 1,
};

const settings = {
  dragSpeed: 1.25,
  itemWidth: 220,
  itemHeight: 150,
  itemSideOffsets: 15,
};

const itemStyle = {
  width: `${settings.itemWidth}px`,
  height: `${settings.itemHeight}px`,
  margin: `0px ${settings.itemSideOffsets}px`,
};

export default function AdminRoomPage() {
  const { t } = useTranslation();

  const [availability, setAvailability] = useState();

  useEffect(() => {
    availabilityService.getAvailability().then((res) => {
      setAvailability(res.data);
    });
  }, []);

  return (
    <div className="admin-room-page">
      <Navbar />
      <RoomDisplay t={t} />
      {/* <LineSeparator /> */}
      <CreateAvailability {...{ t, availability, setAvailability }} />
      <DisplayAvailability {...{ t, availability, setAvailability }} />
    </div>
  );
}

const RoomDisplay = ({ t }) => {
  const [roomsPeriodPrices, setRoomsPeriodPrices] = useState();

  useEffect(() => {
    roomService.getRooms().then((res) => {
      setRoomsPeriodPrices(res.data);
    });
  }, []);

  const handleRoomPeriodPriceChange = (period, roomName, price) => {
    const dataCopy = [...roomsPeriodPrices];
    const room = dataCopy.find((x) => x.name === roomName);
    room[period] = price;
    setRoomsPeriodPrices(dataCopy);
  };

  const editRoom = async (room) => {
    const roomData = roomsPeriodPrices.find((x) => x.name === room);
    await roomService.editRoom(room, roomData);
    toast.success("Промяната беше успешна");
  };

  const periodPrices = [
    "periodOne",
    "periodTwo",
    "periodThree",
    "periodFour",
    "periodFive",
    "periodSix",
    "periodSeven",
    "periodEight",
  ];
  return (
    <div className="rooms-container">
      {roomsPeriodPrices &&
        roomsPeriodPrices.map((room, index) => {
          return (
            <div key={index} className="room-display-container">
              <h5>{t(room.name)}</h5>
              <img
                src={RoomInfo[room.name].image}
                alt=""
                className="room-display-image"
              />
              {periodPrices.map((period, index) => {
                return (
                  <div className="price-period" key={index}>
                    <label>
                      {periods[period].map((periodString, pIndex) => {
                        return (
                          <React.Fragment key={pIndex}>
                            {getDateViewFromPeriod(periodString)}
                            {pIndex !== periods[period].length - 1 && " и "}
                          </React.Fragment>
                        );
                      })}
                    </label>
                    <div className="flex-row-justify-between">
                      <input
                        id="period-1-price"
                        placeholder={`Цена период ${index + 1}`}
                        value={room[period + "Price"]}
                        className="change-room-price-input"
                        onChange={(e) =>
                          handleRoomPeriodPriceChange(
                            period + "Price",
                            room.name,
                            e.currentTarget.value
                          )
                        }
                      />
                      <p style={{ margin: 0 }}>
                        След такса - {getTaxedPrice(room[period + "Price"])}лв.
                      </p>
                    </div>
                  </div>
                );
              })}

              <Button
                text="Запази"
                classes={["change-room-price-button"]}
                onClick={() => editRoom(room.name)}
              />
            </div>
          );
        })}
    </div>
  );
};

const CreateAvailability = ({ t, availability, setAvailability }) => {
  const [data, setData] = useState({ ...initialData });

  const [errors, setErrors] = useState({});

  const rooms = Object.keys(RoomInfo).map((x) => ({ value: x, label: t(x) }));

  const handleChange = (field, value) => {
    let dataCopy = { ...data };
    dataCopy[field] = value;
    setData(dataCopy);
  };

  const handleSubmit = async () => {
    let dataCopy = { ...data };
    let availabilityCopy = [...availability];
    let days = getDatesInRange(dataCopy.startDate, dataCopy.endDate);
    days = days.map((day) => {
      return {
        date: day,
        availableRooms: Number(dataCopy.roomAmmount),
        bookedRooms: 0,
      };
    });

    dataCopy["startDate"] = getDateDB(data.startDate);
    dataCopy["endDate"] = getDateDB(data.endDate);
    if ((await validate(dataCopy, availabilitySchema, setErrors)) === null) {
      try {
        dataCopy["room"] = data["room"].value;
        dataCopy["days"] = days;

        const res = await availabilityService.createAvailability(dataCopy);
        if (res && res.status === 200) {
          toast.success("Успешно добавена наличност");
          setData({ ...initialData });
          setAvailability([...availabilityCopy, res.data]);
        }
      } catch (ex) {
        console.log(ex, "ex");
        toast.error("Нещо се обърка.");
      }
    }
  };

  return (
    <React.Fragment>
      <h5 className="add-availability-header">Добави наличност</h5>
      <div className="admin-room-page-content">
        <div className="admin-room-page__select-wrapper">
          <h5>Тип стая</h5>
          <Select
            options={rooms}
            onChange={(opt) => handleChange("room", opt)}
          />
          {errors.room && <p style={{ color: "red" }}>{errors.room}</p>}
        </div>

        <div className="admin-room-page__datepicker">
          <p style={{ margin: 0 }}>Начална дата</p>
          <div>
            <DatePicker
              onChange={(date) => handleChange("startDate", date)}
              date={data.startDate}
            />
          </div>
          <span className="ti-angle-down"></span>
        </div>

        <div className="admin-room-page__datepicker">
          <p style={{ margin: 0 }}>Крайна дата</p>
          <div>
            <DatePicker
              onChange={(date) => handleChange("endDate", date)}
              date={data.endDate}
            />
          </div>
          <span className="ti-angle-down"></span>
        </div>
        <div className="admin-room-page__price-container">
          <p className="admin-room-page__price-label">Брой стаи</p>
          <input
            value={data.roomAmmount}
            onChange={(e) => handleChange("roomAmmount", e.currentTarget.value)}
            className="admin-room-page__price-input"
          />
          {errors.roomAmmount && (
            <p style={{ color: "red" }}>{errors.roomAmmount}</p>
          )}
        </div>
      </div>

      <Button
        onClick={handleSubmit}
        text="Добави"
        classes={["admin-room-page__submit-button"]}
      />
    </React.Fragment>
  );
};

const DisplayAvailability = ({ t, availability, setAvailability }) => {
  const [details, setDetails] = useState();
  const carouselRef = useRef();

  const deleteAvailability = async (id) => {
    await availabilityService.deleteAvailability(id);
    setAvailability([...availability].filter((x) => x._id !== id));
  };

  const handleDetailsClick = async (availability) => {
    const res = await availabilityService.getAvailabilityById(availability._id);
    if (res.status === 200) {
      setDetails(res.data);
      setTimeout(() => {
        carouselRef.current.scrollIntoView({ behavior: "smooth" });
      }, 250);
    }
  };

  const handleChange = (text, index) => {
    let dataCopy = { ...details };
    dataCopy.days[index].availableRooms = Number(text);
    setDetails({ ...dataCopy });
  };

  const onSave = async () => {
    let dtls = { ...details };
    dtls.availableRooms = Number(dtls.availableRooms);
    const res = await availabilityService.editAvailability(details);
    if (res.status === 200) {
      toast.success("Успешно редактирана наличност");
    } else {
      toast.error("Нещо се обърка");
    }
  };
  return (
    <section className="display-availability-section">
      <table className="availability-table">
        <thead>
          <tr>
            <th>Тип стаи</th>
            <th>Начална дата</th>
            <th>Крайна дата</th>
            <th>Брой стаи</th>
            <th> - </th>
            <th> Изтрий </th>
          </tr>
        </thead>
        <tbody>
          {availability &&
            availability.map((x, index) => {
              return (
                <tr key={index}>
                  <td>{t(x.room)}</td>
                  <td>{getDateView(x.startDate)}</td>
                  <td>{getDateView(x.endDate)}</td>
                  <td>{x.roomAmmount}</td>
                  <td
                    onClick={() => handleDetailsClick(x)}
                    className="availability-see-details"
                  >
                    Виж детайли
                  </td>
                  <td>
                    <Button
                      classes={["delete-button"]}
                      text="X"
                      onClick={() => deleteAvailability(x._id)}
                    />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>

      {details && (
        <>
          <h4 style={{ textAlign: "center", marginBottom: "10px" }}>
            {t(details.room)}
          </h4>
          <div className="availability-details-container" ref={carouselRef}>
            {details.days.map((day, index) => {
              return (
                <div
                  ref={index === 0 ? carouselRef : null}
                  key={index}
                  className={`draggable-carousel-item ${
                    details.days[index].availableRooms === 0
                      ? "rooms-availability-red-text"
                      : ""
                  }`}
                  style={{ ...itemStyle }}
                >
                  <h6>{getDateView(day.date)}</h6>
                  <div className="carousel-input-group">
                    <p>Налични стаи</p>
                    <input
                      value={details.days[index].availableRooms}
                      onChange={(e) =>
                        handleChange(e.currentTarget.value, index)
                      }
                    />
                  </div>
                  <p>Резервирани стаи: {details.days[index].bookedRooms}</p>
                </div>
              );
            })}
            <Button
              text="Запази промените"
              classes={["save-button"]}
              onClick={onSave}
            />
          </div>
        </>
      )}
    </section>
  );
};
