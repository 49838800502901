import { useEffect, useState } from "react";
import roomService from "../services/roomService";
import { getDateDB, getDatesInRange, getDateZeroHours } from "./date";
import getTaxedPrice from "./getRoomPriceWithTax";
import periods from "./periods";

/**
 *
 * @param {Date} startDate - in browser format - Wed Jun 29 2022 ...
 * @param {Date} endDate - in browser format - Wed Jun 29 2022 ...
 * @returns {Array} [roomsPrices, daysInPeriod]
 * roomsPrices are after tax
 *  The roomsPrices structure is:
 *     { 
 *          "roomName": { 
 *              "periodName": price<Number>, 
 *              "periodTwo": price<Number>
            },
            "roomNameTwo":{
                "periodName": price<Number>,
                "periodTwo": price<Number>
            }
 *     }
 *
 *  The daysInPeriods structure is :
 *      {
 *          "periodName": days<Number>
 *       }
 */
export default function usePeriodRoomPrices(startDate, endDate) {
  const [roomsPrices, setRoomsPrices] = useState();
  const [daysInPeriod, setDaysInPeriod] = useState();

  useEffect(() => {
    if (!startDate || !endDate) return;
    const startDateDB = getDateDB(startDate);
    const endDateDB = getDateDB(endDate);
    const bookingDays = getDatesInRange(startDateDB, endDateDB);
    const daysLength = bookingDays.length - 1;

    const daysCountForPeriods = {};

    for (let i = 0; i < daysLength; i++) {
      const day = bookingDays[i];
      const currentBookingDayDate = getDateZeroHours(day).toISOString();
      Object.keys(periods).forEach((period) => {
        periods[period].forEach((interval) => {
          const dates = interval.split(" - ");
          const periodStartDate = getDateZeroHours(
            new Date(dates[0])
          ).toISOString();
          const periodEndDate = getDateZeroHours(
            new Date(dates[1])
          ).toISOString();
          if (
            currentBookingDayDate >= periodStartDate &&
            currentBookingDayDate <= periodEndDate
          ) {
            if (!daysCountForPeriods[period]) {
              daysCountForPeriods[period] = 1;
            } else {
              daysCountForPeriods[period]++;
            }
          }
        });
      });
    }

    let roomPrices = {};
    roomService.getRooms().then((res) => {
      const rooms = res.data;
      for (let i = 0; i < rooms.length; i++) {
        const room = rooms[i];
        roomPrices[room.name] = {};
        Object.keys(daysCountForPeriods).forEach((period) => {
          const priceForPeriod = room[period + "Price"];
          const priceAfterTax = getTaxedPrice(priceForPeriod);
          roomPrices[room.name][period] = priceAfterTax;
        });
      }
      setRoomsPrices(roomPrices);
      setDaysInPeriod(daysCountForPeriods);
    });
    console.log(roomPrices, "roomPRices");
    console.log(daysCountForPeriods, "daysCountForPeriods");
  }, [startDate, endDate]);
  if (!roomsPrices) return [];
  return [roomsPrices, daysInPeriod];
}
